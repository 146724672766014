import Image from "next/image"
import Typography from "compass/data/Typography"
import LinkButtonDONOTUSE from "compass-local/legacy/LinkButtonDONOTUSE"
import { t, Trans } from "content"
import EmailVerificationGraphic from "assets/EmailVerificationGraphic.svg"
import { Cb, Q } from "cb"
import Modal from "compass-local/Modal"
import { ENVIRONMENT } from "env"
import { useMutation } from "@tanstack/react-query"
import { MSError2, MSTimestamp, useOnMount } from "msutils"
import { useCurrentPath, useQueryParams } from "utils/router"
import { Action } from "utils/actions"
import SupportLink from "components/misc/SupportLink"
import { LinkEXPERIMENTAL } from "components/misc/LinkEXPERIMENTAL"
import { MSLayout } from "utils/layout"
import ErrorState from "components/misc/ErrorState"
import { SentryClient } from "lib/sentry"
import { useBusinessContext } from "./user"
import { auth } from "./auth"

export function Footer() {
  return (
    <div className="flex justify-center py-4">
      <Typography variant="label" className="text-th-text-secondary text-center">
        <Trans
          tKey="© Beam Finance Inc. · <X1>Privacy</X1> · <X2>Terms</X2>"
          X1={<LinkEXPERIMENTAL.External href="https://www.trybeam.com/privacy-policy" />}
          X2={<LinkEXPERIMENTAL.External href="https://www.trybeam.com/terms-of-use" />}
        />
      </Typography>
    </div>
  )
}

export function Utm() {
  const { utm_content: utmContent } = useQueryParams()
  const trackEmailLinkClicked = Cb.useTrackEmailLinkClickedHook()

  useOnMount(() => {
    if (utmContent !== undefined) {
      trackEmailLinkClicked({
        tracking_token: utmContent as string,
        clicked_at: MSTimestamp.now().iso(),
      })
    }
  })

  return <></>
}

const EmailVerificationModal = Modal.asModal(
  () => {
    const { resetAuth } = auth.usePostAuthContext()
    const { user } = useBusinessContext()
    const createEmailVerificationAttempt = Cb.useCreateEmailVerificationAttemptHook()
    const mutation = useMutation({
      mutationFn: () => createEmailVerificationAttempt({ email: user.email ?? "" }),
    })

    return (
      <Modal.Body title="" setInactive={() => undefined} noHeader>
        <div className="vflex grow justify-center">
          <div className="vflex items-center justify-center gap-10">
            <Image src={EmailVerificationGraphic} alt="Email" />
            <div className="vflex gap-5 items-center">
              <Typography variant="drawerheader">{t("Please confirm your email")}</Typography>
              <Typography className="text-center">
                {t(
                  "Thanks for signing up. Please take a second to confirm your email address to finish account setup.",
                )}
              </Typography>
            </div>
            <div className="vflex gap-5 items-center">
              <Action.Mount
                {...Action.mutation(t("Resend email"), { mutate: mutation.mutateAsync })}
              />
              <Typography>{t("To: {{ X }}", { X: user.email ?? "--" })}</Typography>
              <Typography className="text-th-text-secondary text-center">
                <Trans
                  tKey="Wrong email address? <X>Sign out</X> and create another account."
                  X={<LinkButtonDONOTUSE onClick={resetAuth} />}
                />
              </Typography>
            </div>
            <Typography className="text-th-text-secondary text-center">
              <SupportLink tKey="Didn't receive an email? Please check your spam folder or <X>contact support</X>." />
            </Typography>
          </div>
        </div>
      </Modal.Body>
    )
  },
  { blur: true },
)

export function EmailVerificationBlocker() {
  const { user, emailVerifications } = useBusinessContext()
  const path = useCurrentPath().split("?")[0]

  if (ENVIRONMENT !== "test" && path !== "/verify-email") {
    return (
      <EmailVerificationModal
        isActive={emailVerifications.length === 0 && user.email !== null}
        setInactive={() => undefined}
      />
    )
  }
  return null
}

export function BusinessAlert() {
  const frontendBanner = Cb.useListFrontEndBanners({ select: Q.opt })

  if (frontendBanner.data?.content) {
    return (
      <MSLayout.Banner
        icon="exclamation-in-triangle"
        title={frontendBanner.data.content}
        theme="orange"
      />
    )
  } else {
    return null
  }
}

function Fallback({ error }: { error?: MSError2 }) {
  return <ErrorState error={error?.message ?? t("An unexpected error has occurred")} />
}
export function useErrorHandling() {
  useOnMount(() => {
    const authCtx = auth.getAuthDONOTUSE()
    const isImpersonator = authCtx && authCtx.impersonator

    MSError2.report = (errorOrString) => {
      let error: Error
      if (typeof errorOrString === "string") {
        error = new Error(errorOrString)
      } else {
        error = errorOrString
      }
      const originalMessage = error.message
      if (error instanceof MSError2) error.message = error.reportMessage
      SentryClient.report2(error, {
        tags: {
          isImpersonator,
          componentStack: error instanceof MSError2 ? error.componentStack : "None",
        },
      })
      error.message = originalMessage
    }

    MSError2.Fallback = Fallback
  })
  MSError2.useGlobalErrorHandling()
}
