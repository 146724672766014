/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type ExpandedExpenseApprovalState =
  (typeof ExpandedExpenseApprovalState)[keyof typeof ExpandedExpenseApprovalState]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ExpandedExpenseApprovalState = {
  pending: "pending",
  rejected: "rejected",
  approved: "approved",
  void: "void",
} as const
