import IconBase from "compass-local/legacy/IconBase"
import { IconProps } from "../types"

export default function Warning({
  width,
  height = 20,
  onClick,
  href,
  tabIndex,
  className,
}: IconProps) {
  return (
    <IconBase href={href} className={className} onClick={onClick} tabIndex={tabIndex}>
      <svg
        width={width?.toFixed(1)}
        height={height.toFixed(1)}
        viewBox="0 0 17 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.57831 1.5C7.96321 0.833333 8.92546 0.833333 9.31036 1.5L14.5065 10.5C14.8914 11.1667 14.4103 12 13.6405 12H3.24818C2.47838 12 1.99726 11.1667 2.38216 10.5L7.57831 1.5Z"
          fill="currentColor"
        />
        <path
          d="M8.44434 4.85718V7.42861M8.44434 10H8.45005"
          stroke="#FEF9C8"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </IconBase>
  )
}
