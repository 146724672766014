/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type ListBankAccountsOrdering =
  (typeof ListBankAccountsOrdering)[keyof typeof ListBankAccountsOrdering]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ListBankAccountsOrdering = {
  account_number: "account_number",
  "-account_number": "-account_number",
  account_number_last_4: "account_number_last_4",
  "-account_number_last_4": "-account_number_last_4",
  routing_number: "routing_number",
  "-routing_number": "-routing_number",
  bank_name: "bank_name",
  "-bank_name": "-bank_name",
  inbound_transfers_enabled: "inbound_transfers_enabled",
  "-inbound_transfers_enabled": "-inbound_transfers_enabled",
  stripe_payment_method_id: "stripe_payment_method_id",
  "-stripe_payment_method_id": "-stripe_payment_method_id",
  nickname: "nickname",
  "-nickname": "-nickname",
  created_at: "created_at",
  "-created_at": "-created_at",
  updated_at: "updated_at",
  "-updated_at": "-updated_at",
  archived: "archived",
  "-archived": "-archived",
} as const
