/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type ListDocumentsOrdering =
  (typeof ListDocumentsOrdering)[keyof typeof ListDocumentsOrdering]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ListDocumentsOrdering = {
  name: "name",
  "-name": "-name",
  creator_name: "creator_name",
  "-creator_name": "-creator_name",
  externally_created_at: "externally_created_at",
  "-externally_created_at": "-externally_created_at",
  url: "url",
  "-url": "-url",
  created_at: "created_at",
  "-created_at": "-created_at",
  updated_at: "updated_at",
  "-updated_at": "-updated_at",
} as const
