/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type QBProjectImportRequestState =
  (typeof QBProjectImportRequestState)[keyof typeof QBProjectImportRequestState]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const QBProjectImportRequestState = {
  pending: "pending",
  complete: "complete",
  failed: "failed",
  null: null,
} as const
