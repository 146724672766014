import { Cell, CellField } from "./base"
import { Branded, EmptyString, FieldError } from "./utils"

type ZipCodeString = Branded<string, "zip">
type ZipCodeField<V> = CellField<string, V>

type ZipCodeProps<TReq> = {
  required?: TReq
  initValue?: string
  treatEmptyAs?: string
}

export function ZipCode<TReq extends boolean = false>(
  props?: ZipCodeProps<TReq>,
): ZipCodeField<TReq extends true ? ZipCodeString : ZipCodeString | EmptyString> {
  const validate: any = (x: string) => {
    if (props?.required) {
      if (!x) {
        throw new FieldError("Required")
      } else if (Number.isNaN(x) || x.length !== 5) {
        throw new FieldError("Please enter a valid zip code")
      } else {
        return x
      }
    } else if (Number.isNaN(x)) {
      throw new FieldError("Please enter a valid zip code")
    } else {
      return x
    }
  }
  return Cell({
    initValue: props?.initValue ?? "",
    validate,
  })
}
