import IconBase from "compass-local/legacy/IconBase"
import { StrokedIconProps } from "../types"

export default function Receipt({
  width,
  height = 18,
  onClick,
  href,
  tabIndex,
  className,
  thickness = 0.33,
}: StrokedIconProps) {
  return (
    <IconBase href={href} className={className} onClick={onClick} tabIndex={tabIndex}>
      <svg
        width={width?.toFixed(1)}
        height={height.toFixed(1)}
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.3101 17.9744C16.1053 17.9744 15.926 17.8976 15.7724 17.744L13.8777 15.8236L11.9829 17.7183C11.6757 18.0256 11.2148 18.0256 10.9075 17.7183L8.98719 15.8492L7.09245 17.744C6.78519 18.0512 6.32431 18.0512 6.01706 17.744L4.12232 15.8492L2.22759 17.7696C1.92033 18.0768 1.45945 18.0768 1.15219 17.7696C0.998566 17.6159 0.921753 17.4367 0.921753 17.2319V3.20057C0.921753 1.43385 2.35561 0 4.12232 0H13.8777C15.6444 0 17.0782 1.43385 17.0782 3.20057V17.2063C17.0782 17.6415 16.7198 17.9744 16.3101 17.9744ZM8.98719 14.0057C9.19202 14.0057 9.37125 14.0825 9.52488 14.2361L11.4196 16.1565L13.3144 14.2617C13.6216 13.9545 14.0825 13.9545 14.3897 14.2617L15.5163 15.3883V3.20057C15.5163 2.27881 14.7482 1.53627 13.8521 1.53627H4.12232C3.20056 1.53627 2.45803 2.27881 2.45803 3.20057V15.3883L3.58463 14.2617C3.89188 13.9545 4.35276 13.9545 4.66002 14.2617L6.55475 16.1565L8.44949 14.2617C8.60312 14.1081 8.78235 14.0313 8.98719 14.0057Z"
          stroke="currentColor"
          strokeWidth={thickness.toFixed(1)}
          fill="currentColor"
        />
        <path
          d="M10.5234 10.7794H5.65858C5.24891 10.7794 4.89044 10.4466 4.89044 10.0113C4.89044 9.60163 5.2233 9.24316 5.65858 9.24316H10.5234C10.9331 9.24316 11.2916 9.57602 11.2916 10.0113C11.2916 10.421 10.9331 10.7794 10.5234 10.7794Z"
          stroke="currentColor"
          strokeWidth={thickness.toFixed(1)}
          fill="currentColor"
        />
        <path
          d="M12.6486 6.91323H5.35133C4.94165 6.91323 4.58319 6.58037 4.58319 6.14509C4.58319 5.73542 4.91605 5.37695 5.35133 5.37695H12.6486C13.0583 5.37695 13.4168 5.70981 13.4168 6.14509C13.4168 6.58037 13.0583 6.91323 12.6486 6.91323Z"
          stroke="currentColor"
          strokeWidth={thickness.toFixed(1)}
          fill="currentColor"
        />
      </svg>
    </IconBase>
  )
}
