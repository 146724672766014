/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type UpdateMoneyMovementViewInputNewState =
  (typeof UpdateMoneyMovementViewInputNewState)[keyof typeof UpdateMoneyMovementViewInputNewState]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UpdateMoneyMovementViewInputNewState = {
  pending: "pending",
  complete: "complete",
  failed: "failed",
} as const
