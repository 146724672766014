/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type ListUpcomingHolidaysOrdering =
  (typeof ListUpcomingHolidaysOrdering)[keyof typeof ListUpcomingHolidaysOrdering]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ListUpcomingHolidaysOrdering = {
  date: "date",
  "-date": "-date",
  name: "name",
  "-name": "-name",
  created_at: "created_at",
  "-created_at": "-created_at",
  updated_at: "updated_at",
  "-updated_at": "-updated_at",
} as const
