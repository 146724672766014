/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type ListInvoicePrefillsOrdering =
  (typeof ListInvoicePrefillsOrdering)[keyof typeof ListInvoicePrefillsOrdering]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ListInvoicePrefillsOrdering = {
  payee_name: "payee_name",
  "-payee_name": "-payee_name",
  number: "number",
  "-number": "-number",
  date: "date",
  "-date": "-date",
  due_date: "due_date",
  "-due_date": "-due_date",
  line_items_match_total: "line_items_match_total",
  "-line_items_match_total": "-line_items_match_total",
  has_conditional: "has_conditional",
  "-has_conditional": "-has_conditional",
  created_at: "created_at",
  "-created_at": "-created_at",
  updated_at: "updated_at",
  "-updated_at": "-updated_at",
} as const
