import { Cell, CellField } from "./base"
import { Branded, EmptyString, FieldError } from "./utils"

type PasswordString = Branded<string, "password">
type PasswordField<V> = CellField<string, V>

type PasswordProps<TReq> = {
  required?: TReq
  initValue?: string
  errorKey?: string
}

function validatePassword(s: string) {
  if (s.length < 8) {
    throw new Error("Password must be at least 8 characters")
  }
  return s
}

export function Password<TReq extends boolean = false>(
  props?: PasswordProps<TReq>,
): PasswordField<TReq extends true ? PasswordString : PasswordString | EmptyString> {
  const validate: any = (x: string) => {
    if (props?.required) {
      if (!x) {
        throw new FieldError("Required")
      } else {
        return validatePassword(x)
      }
    } else if (!x) {
      return ""
    } else {
      return validatePassword(x)
    }
  }
  return Cell({
    initValue: props?.initValue ?? "",
    validate,
    errorKey: props?.errorKey,
  })
}
