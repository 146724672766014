/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type InvoicePaymentTypeBankAccountBankAccountDetailsState =
  (typeof InvoicePaymentTypeBankAccountBankAccountDetailsState)[keyof typeof InvoicePaymentTypeBankAccountBankAccountDetailsState]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InvoicePaymentTypeBankAccountBankAccountDetailsState = {
  new: "new",
  pending: "pending",
  complete: "complete",
  failed: "failed",
} as const
