import IconBase from "compass-local/legacy/IconBase"
import { StrokedIconProps } from "../types"

export default function EditWithPlus({
  width,
  height = 18,
  onClick,
  href,
  tabIndex,
  className,
  thickness = 2,
}: StrokedIconProps) {
  return (
    <IconBase href={href} className={className} onClick={onClick} tabIndex={tabIndex}>
      <svg
        width={width?.toFixed(1)}
        height={height.toFixed(1)}
        viewBox="0 0 18 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 4.5003H7M4 1.50031V7.50031"
          stroke="currentColor"
          strokeWidth={thickness.toFixed(1)}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.1545 2.98825L15.5118 5.34554M14.1545 1.98827C14.4671 1.67568 14.8911 1.50006 15.3331 1.50006C15.7752 1.50006 16.1992 1.67568 16.5118 1.98827C16.8244 2.30087 17 2.72484 17 3.16692C17 3.60899 16.8244 4.03297 16.5118 4.34556L7.33329 13.5241H5V11.1428L14.1545 1.98827Z"
          stroke="currentColor"
          strokeWidth={thickness.toFixed(1)}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </IconBase>
  )
}
