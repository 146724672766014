import { Cell, CellField } from "./base"
import { Branded, EmptyString, FieldError } from "./utils"

type EmailString = Branded<string, "email">

const re =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
function validateEmail(message: string = "Must be a valid email address") {
  return (val: string) => {
    if (!val.toLowerCase().match(re)) {
      throw new FieldError(message)
    }
    return val
  }
}

type EmailField<V> = CellField<string, V>

type EmailProps<TReq> = {
  required?: TReq
  initValue?: string
}

export function Email<TReq extends boolean = false>(
  props?: EmailProps<TReq>,
): EmailField<TReq extends true ? EmailString : EmailString | EmptyString> {
  const validate: any = (x: string) => {
    if (props?.required) {
      if (!x) {
        throw new FieldError("Required")
      } else {
        return validateEmail()(x)
      }
    } else if (!x) {
      return ""
    } else {
      return validateEmail()(x)
    }
  }
  return Cell({
    initValue: props?.initValue ?? "",
    validate,
  })
}
