/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type ListProjectsPageSummarysOrdering =
  (typeof ListProjectsPageSummarysOrdering)[keyof typeof ListProjectsPageSummarysOrdering]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ListProjectsPageSummarysOrdering = {
  all_count: "all_count",
  "-all_count": "-all_count",
  active_count: "active_count",
  "-active_count": "-active_count",
  completed_count: "completed_count",
  "-completed_count": "-completed_count",
  created_at: "created_at",
  "-created_at": "-created_at",
  updated_at: "updated_at",
  "-updated_at": "-updated_at",
} as const
