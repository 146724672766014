/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type ComplianceDocumentTypeCoiType =
  (typeof ComplianceDocumentTypeCoiType)[keyof typeof ComplianceDocumentTypeCoiType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ComplianceDocumentTypeCoiType = {
  coi: "coi",
} as const
