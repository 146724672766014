/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type ListDealsOrdering = (typeof ListDealsOrdering)[keyof typeof ListDealsOrdering]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ListDealsOrdering = {
  payer_name: "payer_name",
  "-payer_name": "-payer_name",
  raw_amount: "raw_amount",
  "-raw_amount": "-raw_amount",
  amount: "amount",
  "-amount": "-amount",
  description: "description",
  "-description": "-description",
  name: "name",
  "-name": "-name",
  stage: "stage",
  "-stage": "-stage",
  created_at: "created_at",
  "-created_at": "-created_at",
  updated_at: "updated_at",
  "-updated_at": "-updated_at",
  archived: "archived",
  "-archived": "-archived",
} as const
