import { ReactNode, useState } from "react"
import { BaseLayout } from "../baseLayout"
import { PortalLayout } from "../portalLayout"

type Theme = {
  border: string
  borderRadius: number
  background: string
  inset: number
  shadow: string
}

type Props = {
  isActive: boolean
  setInactive: () => void
  content: ReactNode
  theme: Theme
  children: ReactNode
}

export default function Dropdown2({ isActive, setInactive, content, theme, children }: Props) {
  const [anchorRef, setAnchorRef] = useState<HTMLDivElement | null>(null)

  return (
    <>
      {anchorRef && (
        <PortalLayout.Dropdown
          isActive={isActive}
          setInactive={setInactive}
          anchor={anchorRef}
          animationDuration={120}
          content={
            <BaseLayout.View
              inset={theme.inset}
              background={theme.background}
              border={theme.border}
              borderRadius={theme.borderRadius}
              overridesDONOTUSE={{ boxShadow: theme.shadow, width: "max-content" }}
            >
              {content}
            </BaseLayout.View>
          }
        />
      )}
      <div ref={setAnchorRef}>{children}</div>
    </>
  )
}
