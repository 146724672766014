/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type ListAdminQBRequestsOrdering =
  (typeof ListAdminQBRequestsOrdering)[keyof typeof ListAdminQBRequestsOrdering]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ListAdminQBRequestsOrdering = {
  business_name: "business_name",
  "-business_name": "-business_name",
  failure_reason: "failure_reason",
  "-failure_reason": "-failure_reason",
  complete: "complete",
  "-complete": "-complete",
  error_content: "error_content",
  "-error_content": "-error_content",
  is_retry: "is_retry",
  "-is_retry": "-is_retry",
  addressed: "addressed",
  "-addressed": "-addressed",
  link_type: "link_type",
  "-link_type": "-link_type",
  type: "type",
  "-type": "-type",
  source_id: "source_id",
  "-source_id": "-source_id",
  is_ignorable_failure: "is_ignorable_failure",
  "-is_ignorable_failure": "-is_ignorable_failure",
  created_at: "created_at",
  "-created_at": "-created_at",
  updated_at: "updated_at",
  "-updated_at": "-updated_at",
} as const
