/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type ListProjectListRowsOrdering =
  (typeof ListProjectListRowsOrdering)[keyof typeof ListProjectListRowsOrdering]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ListProjectListRowsOrdering = {
  name: "name",
  "-name": "-name",
  start_date: "start_date",
  "-start_date": "-start_date",
} as const
