import { Cell, CellField } from "./base"
import { Branded, EmptyString, FieldError } from "./utils"

type DecimalString = Branded<string, "decimal">
type DecimalField<V> = CellField<string, V>

type DecimalProps<TReq> = {
  required?: TReq
  initValue?: string
  treatEmptyAs?: string
}

export function Decimal<TReq extends boolean = false>(
  props?: DecimalProps<TReq>,
): DecimalField<TReq extends true ? DecimalString : DecimalString | EmptyString> {
  const validate: any = (x: string) => {
    if (props?.required) {
      if (!x) {
        throw new FieldError("Required")
      } else if (Number.isNaN(x)) {
        throw new FieldError("Please enter a valid amount")
      } else {
        return x
      }
    } else if (!x) {
      return props?.treatEmptyAs ?? ""
    } else if (Number.isNaN(x)) {
      throw new FieldError("Please enter a valid amount")
    } else {
      return x
    }
  }
  return Cell({
    initValue: props?.initValue ?? "",
    validate,
    init: (val) => {
      if (val.endsWith(".00")) {
        return val.slice(0, -3)
      } else {
        return val
      }
    },
  })
}
