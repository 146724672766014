/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type EstimateAsPayeeListRowType =
  (typeof EstimateAsPayeeListRowType)[keyof typeof EstimateAsPayeeListRowType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EstimateAsPayeeListRowType = {
  project: "project",
  change_order: "change_order",
} as const
