/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type EstimatePatchStatus = (typeof EstimatePatchStatus)[keyof typeof EstimatePatchStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EstimatePatchStatus = {
  draft: "draft",
  sent: "sent",
  approved: "approved",
  rejected: "rejected",
  void: "void",
} as const
