/* eslint-disable no-bitwise */
import { UseQueryOptions, UseQueryResult } from "@tanstack/react-query"
import { MSError2 } from "msutils"
import { MSObject, ToSnake } from "msutils/object"
import { C, ExtraResult } from "msutils/abstract-c"
import * as Js from "./query-utils-js.jsx"

// Settings

export function settings<T, S>(
  query: UseQueryOptions<T, unknown, S>,
): { query: UseQueryOptions<T, unknown, S> } {
  return { query }
}

// for some reason, this doesn't work unless tsconfig has
// "moduleResolution": "node10", but setting that breaks
// JSON files
export function snake<T extends object>(obj: T): ToSnake<T> {
  return MSObject.toSnake(obj)
}

// Extractors

export type Paginated<T> = {
  results: T[]
}

export function identity<T>(data: T): T {
  return data
}

export function paginated<T extends Paginated<any>>(data: T): T {
  return data
}

export function all<T>(data: Paginated<T>): T[] {
  return data.results
}

export function opt<T>(data: Paginated<T>): T | null {
  return data.results.at(0) ?? null
}

export function get<T>(data: Paginated<T>): T {
  if (data.results.length > 1) {
    throw new MSError2("Expected exactly one element, got multiple")
  }
  const d = data.results.at(0)
  if (d === undefined) {
    throw new MSError2("Expected exactly one element, got none")
  }
  return d
}

export function first<T>(data: Paginated<T>): T {
  const f = data.results.at(0)
  if (f === undefined) {
    throw new MSError2("Expected an element in list")
  } else {
    return f
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function none<T>(_data: Paginated<T>): null {
  return null
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function empty<T>(_data: Paginated<T>): [] {
  return []
}

export function notRequired<T>(data: T | undefined): T | null {
  return data ?? null
}

export function required<T>(data: T | undefined): T {
  if (!data) throw new MSError2("q-required")
  return data
}

export function filter<T>(fn: (_: T) => boolean): (data: Paginated<T>) => T[] {
  return (data: Paginated<T>) => data.results.filter(fn)
}

export function flatMap<T, S>(fn: (_: T) => [S] | []): (data: Paginated<T>) => S[] {
  return (data: Paginated<T>) => data.results.flatMap(fn)
}

export function buildInFilter<T extends Record<string, string>>(
  options: T,
  {
    keep,
    exclude,
  }:
    | { keep: Array<T[keyof T]>; exclude?: undefined }
    | { keep?: undefined; exclude: Array<T[keyof T]> }
    | { keep?: undefined; exclude?: undefined },
) {
  const values = Object.values(options) as Array<T[keyof T]>

  return (
    keep
      ? values.filter((x) => keep.includes(x))
      : exclude
      ? values.filter((x) => !exclude.includes(x))
      : values
  ).join(",")
}

type QueryGroup<R extends object> = {
  [K in keyof R]: UseQueryResult<R[K], any>
}

type Metadata<R extends object> = {
  isRefetching: boolean
  _queries: QueryGroup<R>
}

export type Queryset<R extends object> = Metadata<R> &
  (
    | {
        status: "error"
        queries: null
      }
    | {
        status: "loading"
        queries: null
      }
    | {
        status: "success"
        queries: R
      }
  )

export function group<R extends object>(g: QueryGroup<R>): Queryset<R> {
  const values = Object.values<QueryGroup<R>[keyof R]>(g)

  const metadata = { isRefetching: values.some((v) => v.isRefetching), _queries: g }

  if (values.some((q) => !!q.error)) {
    return { ...metadata, status: "error", queries: null }
  } else if (values.some((q) => q.isLoading)) {
    return { ...metadata, status: "loading", queries: null }
  } else if (values.every((q) => q.status === "success")) {
    return { ...metadata, status: "success", queries: MSObject.map(g, (q) => q.data as any) }
  } else {
    return { ...metadata, status: "error", queries: null }
  }
}

type QIn<T extends object> = { [K in keyof T]: UseQueryResult<T[K]> }

type QOut<T extends object> =
  | { status: "error"; qs: QIn<T> }
  | { status: "loading"; qs: QIn<T> }
  | { status: "success"; data: T }

export function injector<T extends object>(qs: QIn<T>): ExtraResult<T> {
  const q: QOut<T> = Js.injector(qs)
  if (q.status === "error") {
    return C.error(Error("Error in queries"))
  } else if (q.status === "loading") {
    return C.waiting()
  } else {
    return C.ready({ ...q.data })
  }
}

export const RefetchIntervalShort = 60 * 1000

export const NullUuid = "00000000-0000-0000-0000-000000000000"
export function RandomUuid() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0
    const v = c === "x" ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}
