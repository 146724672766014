import IconBase from "compass-local/legacy/IconBase"
import { StrokedIconProps } from "../types"

export default function Card({
  width = 17,
  height,
  onClick,
  href,
  tabIndex,
  className,
}: StrokedIconProps) {
  return (
    <IconBase href={href} className={className} onClick={onClick} tabIndex={tabIndex}>
      <svg
        width={width?.toFixed(1)}
        height={height?.toFixed(1)}
        viewBox="0 0 17 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.5 0C1.39543 0 0.5 0.895431 0.5 2V3H16.5V2C16.5 0.895431 15.6046 0 14.5 0H2.5Z"
          fill="#6F6F78"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.5 5H0.5V10C0.5 11.1046 1.39543 12 2.5 12H14.5C15.6046 12 16.5 11.1046 16.5 10V5ZM2.5 9C2.5 8.44771 2.94772 8 3.5 8H4.5C5.05228 8 5.5 8.44771 5.5 9C5.5 9.55229 5.05228 10 4.5 10H3.5C2.94772 10 2.5 9.55229 2.5 9ZM7.5 8C6.94772 8 6.5 8.44771 6.5 9C6.5 9.55229 6.94772 10 7.5 10H8.5C9.05228 10 9.5 9.55229 9.5 9C9.5 8.44771 9.05228 8 8.5 8H7.5Z"
          fill="#6F6F78"
        />
      </svg>
    </IconBase>
  )
}
