/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type BeamBalanceTransactionTypeChargeDirection =
  (typeof BeamBalanceTransactionTypeChargeDirection)[keyof typeof BeamBalanceTransactionTypeChargeDirection]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BeamBalanceTransactionTypeChargeDirection = {
  debit: "debit",
  credit: "credit",
} as const
