/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type ListProjectBudgetItemsOrdering =
  (typeof ListProjectBudgetItemsOrdering)[keyof typeof ListProjectBudgetItemsOrdering]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ListProjectBudgetItemsOrdering = {
  cost_code_name: "cost_code_name",
  "-cost_code_name": "-cost_code_name",
  amount: "amount",
  "-amount": "-amount",
  markup_multiplier: "markup_multiplier",
  "-markup_multiplier": "-markup_multiplier",
  client_amount: "client_amount",
  "-client_amount": "-client_amount",
  is_initial: "is_initial",
  "-is_initial": "-is_initial",
  created_at: "created_at",
  "-created_at": "-created_at",
  updated_at: "updated_at",
  "-updated_at": "-updated_at",
} as const
