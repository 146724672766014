/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type StripeConnectedAccountLinkUsBankAccountAchPaymentsCapabilityStatus =
  (typeof StripeConnectedAccountLinkUsBankAccountAchPaymentsCapabilityStatus)[keyof typeof StripeConnectedAccountLinkUsBankAccountAchPaymentsCapabilityStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StripeConnectedAccountLinkUsBankAccountAchPaymentsCapabilityStatus = {
  active: "active",
  inactive: "inactive",
  pending: "pending",
} as const
