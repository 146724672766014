/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type ListQboLinksOrdering = (typeof ListQboLinksOrdering)[keyof typeof ListQboLinksOrdering]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ListQboLinksOrdering = {
  qbo_id: "qbo_id",
  "-qbo_id": "-qbo_id",
  type: "type",
  "-type": "-type",
  created_at: "created_at",
  "-created_at": "-created_at",
  updated_at: "updated_at",
  "-updated_at": "-updated_at",
} as const
