import { t } from "content"
import { nullCoalescer } from "./common"
import { MSDate } from "./date"

function toClockHours(hours: number) {
  if (hours === 0 || hours === 12) {
    return 12
  } else {
    return hours % 12
  }
}

const SecondsInDay = 60 * 60 * 24

const Units = {
  second: 1000,
  hour: 1000 * 60 * 60,
  day: 1000 * 60 * 60 * 24,
}

export class MSTimestamp {
  private epochValueMs: number

  constructor(value: string) {
    this.epochValueMs = new Date(value).getTime()
  }

  static init = nullCoalescer((value: string) => {
    return new MSTimestamp(value)
  })

  private static fromNativeDate(value: Date) {
    return MSTimestamp.init(value.toISOString())
  }

  private toNativeDate() {
    return new Date(this.epochValueMs)
  }

  static now() {
    return MSTimestamp.fromNativeDate(new Date())
  }

  valueOf() {
    return this.epochValueMs
  }

  gt(other: MSTimestamp) {
    return this.valueOf() > other.valueOf()
  }

  lt(other: MSTimestamp) {
    return this.valueOf() < other.valueOf()
  }

  gte(other: MSTimestamp) {
    return this.valueOf() >= other.valueOf()
  }

  lte(other: MSTimestamp) {
    return this.valueOf() <= other.valueOf()
  }

  isPast() {
    return this.lt(MSTimestamp.now())
  }

  isFuture() {
    return this.gt(MSTimestamp.now())
  }

  add(n: number, unit: keyof typeof Units) {
    return MSTimestamp.fromNativeDate(new Date(this.valueOf() + Units[unit] * n))
  }

  /**
    Returns the date of the timestamp in the specified timezone or the user's local timezone if unspecified
  */
  toDate(tz?: "pst" | "est") {
    const date = this.toNativeDate()
    const m = date.toLocaleString("en-US", { month: "2-digit", timeZone: tz })
    const y = date.toLocaleString("en-US", { year: "numeric", timeZone: tz })
    const d = date.toLocaleString("en-US", { day: "2-digit", timeZone: tz })
    return MSDate.init([y, m, d].join("-"))
  }

  iso() {
    const d = this.toNativeDate()
    return d.toISOString()
  }

  relative(other: MSTimestamp = MSTimestamp.now()) {
    const deltaSeconds = Math.floor(Math.abs(this.valueOf() - other.valueOf()) / 1000)
    const deltaDays = Math.floor(deltaSeconds / SecondsInDay)

    if (deltaDays > 365) {
      const deltaYears = Math.floor(deltaDays / 365)
      if (deltaYears === 1) {
        return t("1 year")
      } else {
        return t("{{ X }} years", { X: deltaYears })
      }
    } else if (deltaDays > 31) {
      const deltaMonths = Math.floor(deltaDays / 31)
      if (deltaMonths === 1) {
        return t("1 month")
      } else {
        return t("{{ X }} months", { X: deltaMonths })
      }
    } else if (deltaDays > 0) {
      if (deltaDays === 1) {
        return t("1 day")
      } else {
        return t("{{ X }} days", { X: deltaDays })
      }
    } else if (deltaSeconds > 60 * 60) {
      const deltaHours = Math.floor(deltaSeconds / (60 * 60))
      if (deltaHours === 1) {
        return t("1 hour")
      } else {
        return t("{{ X }} hours", { X: deltaHours })
      }
    } else if (deltaSeconds > 60) {
      const deltaMinutes = Math.floor(deltaSeconds / 60)
      if (deltaMinutes === 1) {
        return t("1 minute")
      } else {
        return t("{{ X }} minutes", { X: deltaMinutes })
      }
    } else {
      return t("a few seconds")
    }
  }

  format() {
    const d = this.toNativeDate()
    const monthName = d.toLocaleString("en-US", { month: "short" })
    const date = `${monthName} ${d.getDate()}${
      d.getFullYear() === new Date().getFullYear() ? "" : `, ${d.getFullYear()}`
    }`
    const minutes = d.getMinutes().toString().padStart(2, "0")
    const hours = toClockHours(d.getHours())
    const time = `${hours}:${minutes}${d.getHours() > 11 ? "pm" : "am"}`
    return `${date} ${time}`
  }
}
