/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type ProcoreAccountStatus = (typeof ProcoreAccountStatus)[keyof typeof ProcoreAccountStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProcoreAccountStatus = {
  new: "new",
  linked: "linked",
} as const
