/* eslint-disable mosaic-js/unnamed-args */
import { MSArray } from "../array"
import { MSObject } from "../object"
import { MSString } from "../string"
import * as Interaction from "./interaction"

function removeAt(i, l) {
  return l.flatMap((item, j) => (i === j ? [] : [item]))
}

function insertAt(i, newItem, l) {
  return l.flatMap((x, j) => (i === j ? [x, newItem] : [x]))
}

function insertBefore(i, newItem, l) {
  return l.flatMap((x, j) => (i === j ? [newItem, x] : [x]))
}

function move(i, j, l) {
  const el = l[i]
  if (i === j) {
    return l.slice()
  } else if (i > j) {
    return insertBefore(j, el, removeAt(i, l))
  } else {
    return insertAt(j - 1, el, removeAt(i, l))
  }
}

export function getDefaults(f, concreteConfig) {
  if (f._field_type === "cell") {
    return concreteConfig?.initValue ? f.init(concreteConfig.initValue) : f.initValue
  } else if (f._field_type === "group") {
    return Object.fromEntries(
      Object.entries(f.spec).map(([k, v]) => [k, getDefaults(v, concreteConfig?.values?.[k])]),
    )
  } else if (f._field_type === "list") {
    return concreteConfig?.values && Array.isArray(concreteConfig.values)
      ? concreteConfig.values.map((x) => getDefaults(f.spec, x))
      : [getDefaults(f.spec, null)]
  } else {
    throw new Error(`Unknown field type ${f._field_type}`)
  }
}

export function initStorable(f, defaultValues) {
  if (f._field_type === "cell") {
    const initValue = defaultValues ? f.init(defaultValues) : f.initValue
    return { initValue, value: initValue, status: "clean" }
  } else if (f._field_type === "group") {
    return Object.fromEntries(
      Object.entries(f.spec).map(([k, v]) => [k, initStorable(v, defaultValues?.[k])]),
    )
  } else if (f._field_type === "list") {
    return (
      (defaultValues ?? f.initValue)?.map((x) => initStorable(f.spec, x)) ?? [
        initStorable(f.spec, null),
      ]
    )
  } else {
    throw new Error(`Unknown field type ${f._field_type}`)
  }
}

export function initReadable(f, state, setState) {
  if (f._field_type === "cell") {
    const merge = (s) => setState((x) => ({ ...x, ...s }))

    return {
      ...state,
      hasChanged: state.initValue !== state.value,
      hasTouched: Interaction.shouldShowError(state.status),
      hasFocusWithin: Interaction.isFocused(state.status),
      tap: () => merge({ status: Interaction.transition(state.status, "tap") }),
      reset: () => merge({ value: state.initValue, status: "clean" }),
      checkpoint: () => merge({ initValue: state.value, status: "clean" }),
      update: (newValue) =>
        merge({ value: newValue, status: Interaction.transition(state.status, "update") }),
      focus: () => merge({ status: Interaction.transition(state.status, "focus") }),
      blur: () => merge({ status: Interaction.transition(state.status, "blur") }),
    }
  } else if (f._field_type === "group") {
    const subfields = Object.fromEntries(
      Object.entries(f.spec).map(([k, v]) => {
        const setSubstate = (getValue) => setState((x) => ({ ...x, [k]: getValue(x[k]) }))
        const subreadable = initReadable(v, state?.[k], setSubstate)
        return [k, subreadable]
      }),
    )
    const subfieldsList = Object.values(subfields)
    return {
      hasChanged: subfieldsList.some((x) => x.hasChanged),
      hasFocusWithin: subfieldsList.some((x) => x.hasFocusWithin),
      tap: () => subfieldsList.forEach((x) => x.tap()),
      reset: () => subfieldsList.forEach((x) => x.reset()),
      checkpoint: () => subfieldsList.forEach((x) => x.checkpoint()),
      update: (newValue) => Object.entries(newValue).forEach(([k, v]) => subfields?.[k]?.update(v)),
      fields: subfields,
    }
  } else if (f._field_type === "list") {
    const subfields = state.map((substate, i) => {
      // prev value doesn't always exist for a list (since a new item may have been added)
      const setSubstate = (getValue) =>
        setState((x) => MSArray.replace(x, i, getValue(x[i] ?? initStorable(f.spec, null))))
      return initReadable(f.spec, substate, setSubstate)
    })
    return {
      hasChanged: subfields.some((x) => x.hasChanged),
      hasFocusWithin: subfields.some((x) => x.hasFocusWithin),
      hasErrorWithin: subfields.some((x) => x.hasErrorWithin),
      tap: () => subfields.forEach((x) => x.tap()),
      reset: () => subfields.forEach((x) => x.reset()),
      checkpoint: () => subfields.forEach((x) => x.checkpoint()),
      remove: (i) => setState((x) => x.filter((_, j) => j !== i)),
      move: (i, j) => setState((x) => move(i, j, x)),
      append: (newValue) => setState((x) => [...x, initStorable(f.spec, newValue)]),
      insertAt: (i, newValue) => setState((x) => insertAt(i, initStorable(f.spec, newValue), x)),
      update: (newValue) => setState(() => newValue.map((x) => initStorable(f.spec, x))),
      fields: subfields,
    }
  } else {
    throw new Error(`Unknown field type ${f._field_type}`)
  }
}

export function getConfig(f, concreteConfig) {
  if (f._field_type === "cell") {
    return { disabled: concreteConfig?.disabled ?? false, hidden: concreteConfig?.hidden ?? false }
  } else if (f._field_type === "group") {
    return Object.fromEntries(
      Object.entries(f.spec).map(([k, v]) => [k, getConfig(v, concreteConfig?.[k])]),
    )
  } else if (f._field_type === "list") {
    return concreteConfig && Array.isArray(concreteConfig)
      ? concreteConfig.map((x) => getConfig(f.spec, x))
      : [getConfig(f.spec, null)]
  } else {
    throw new Error(`Unknown field type ${f._field_type}`)
  }
}

function deepAccess(object, key) {
  const segments = key.split(".")
  if (segments.length === 1) {
    return object?.[segments[0]]
  } else {
    const [first, ...rest] = segments
    return object ? deepAccess(object?.[first], rest.join(".")) : undefined
  }
}

export function hasErrorKey(f, key) {
  if (f._field_type === "cell") {
    return key === ""
  } else if (f._field_type === "list") {
    const [segment1, ...rest] = key.split(".")
    if (Number.isNaN(segment1)) {
      return false
    } else {
      return hasErrorKey(f.spec, rest.join("."))
    }
  } else if (f._field_type === "group") {
    if (key === "non_field_errors") {
      return true
    }

    let result
    Object.entries(f.spec).forEach(([k, v]) => {
      const fieldKey = v.errorKey ?? k
      if (key.startsWith(fieldKey)) {
        const latestResult = hasErrorKey(
          v,
          key
            .substring(fieldKey.length)
            .split(".")
            .filter((x) => !!x)
            .join("."),
        )
        if (latestResult || !result) result = latestResult
      }
    })
    return result ?? false
  } else {
    throw new Error(`Unknown field type ${f._field_type}`)
  }
}

function writable(f, state) {
  if (f._field_type === "cell") {
    return state.value
  } else if (f._field_type === "group") {
    return Object.fromEntries(
      Object.entries(f.spec).map(([k, v]) => {
        return [k, writable(v, state.fields[k])]
      }),
    )
  } else if (f._field_type === "list") {
    return state.fields.map((x) => writable(f.spec, x))
  } else {
    throw new Error(`Unknown field type ${f._field_type}`)
  }
}

export function callOnInits(f, state, config) {
  if (f._field_type === "cell") {
    // pass
  } else if (f._field_type === "group") {
    const hooks = config?.hooks?.(state.fields)
    const abstractHooks = f?.hooks?.(state.fields)
    Object.entries(state.fields).forEach(([k, v]) => {
      hooks?.[`onChange${k[0].toUpperCase() + k.slice(1)}`]?.(writable(f.spec[k], v))
      abstractHooks?.[`onChange${k[0].toUpperCase() + k.slice(1)}`]?.(writable(f.spec[k], v))
      callOnInits(f.spec[k], v, config?.config?.[k])
    })
  } else if (f._field_type === "list") {
    // pass
  } else {
    throw new Error(`Unknown field type ${f._field_type}`)
  }
}

export function attachConcreteConfig(f, state, config) {
  if (f._field_type === "cell") {
    return {
      ...state,
      disabled: config?.disabled ?? false,
      hidden: config?.hidden ?? false,
    }
  } else if (f._field_type === "group") {
    const fieldCores = Object.fromEntries(
      Object.entries(f.spec).map(([k, v]) => [
        k,
        {
          ...attachConcreteConfig(v, state.fields[k], config?.config?.[k]),
          ...(config?.disabled && { disabled: true }),
          ...(config?.hidden && { hidden: true }),
        },
      ]),
    )
    const hooks = config?.hooks?.(fieldCores)
    const abstractHooks = f.hooks?.(fieldCores)
    return {
      ...state,
      fields: Object.fromEntries(
        Object.entries(fieldCores).map(([k, v]) => [
          k,
          {
            ...v,
            updateWithoutHooks: v.update,
            ...((hooks?.[`onChange${k[0].toUpperCase() + k.slice(1)}`] ||
              abstractHooks?.[`onChange${k[0].toUpperCase() + k.slice(1)}`]) && {
              update: (newValue) => {
                v.update(newValue)
                hooks?.[`onChange${k[0].toUpperCase() + k.slice(1)}`]?.(newValue)
                abstractHooks?.[`onChange${k[0].toUpperCase() + k.slice(1)}`]?.(newValue)
              },
            }),
          },
        ]),
      ),
    }
  } else if (f._field_type === "list") {
    return {
      ...state,
      disableAppend: config?.disableAppend ?? false,
      disableReorder: config?.disableReorder ?? false,
      disableRemove:
        state.fields.length <= 1
          ? () => !config?.allowRemoveLast
          : (i) => config?.config?.(state.fields?.[i])?.disableRemove ?? false,
      fields: state.fields.map((x) => attachConcreteConfig(f.spec, x, config?.config?.(x)?.values)),
    }
  } else {
    throw new Error(`Unknown field type ${f._field_type}`)
  }
}

export function applyValidation(f, readable, config) {
  if (f._field_type === "cell") {
    const validate = () => {
      try {
        const validValue = f.validate(readable.value)
        config?.validate?.(validValue)
        return { isValid: true, validValue, error: null }
      } catch (e) {
        return { isValid: false, validValue: null, error: e?.message }
      }
    }
    const validation = validate()
    return {
      ...readable,
      validation,
      validValue: validation.validValue,
      error: Interaction.shouldShowError(readable.status) ? validation.error : null,
      statelessError: validation.error,
      hasErrorWithin: !!validation.error,
    }
  } else if (f._field_type === "group") {
    const subfields = Object.fromEntries(
      Object.entries(f.spec).map(([k, v]) => [
        k,
        applyValidation(v, readable.fields[k], config?.config?.[k]),
      ]),
    )

    const validate = () => {
      try {
        let resolvedSubvals
        try {
          const subvals = Object.fromEntries(
            Object.entries(subfields).map(([k, v]) => {
              if (v.validation.isValid === false) throw new Error(v.validation.error)
              return [k, v.validation.validValue]
            }),
          )
          resolvedSubvals = { isValid: true, validValue: subvals, error: null }
        } catch (e) {
          resolvedSubvals = { isValid: false, validValue: null, error: e.message }
        }
        try {
          const validValue = f.validate(resolvedSubvals.validValue)
          return { isValid: true, validValue, error: null }
        } catch (e) {
          if (resolvedSubvals.error === "File is loading. Please try again.") {
            e.message = resolvedSubvals.error
          }
          throw e
        }
      } catch (e) {
        return { isValid: false, validValue: null, error: e?.message }
      }
    }
    const validation = validate()
    return {
      ...readable,
      validation,
      fields: subfields,
      hasErrorWithin: Object.values(subfields).some((x) => x.hasErrorWithin),
    }
  } else if (f._field_type === "list") {
    const subfields = readable.fields.map((x) =>
      applyValidation(f.spec, x, config?.config?.(x)?.values),
    )
    const validate = () => {
      try {
        let resolvedSubvals
        try {
          const subvals = subfields.map((v) => {
            if (v.validation.isValid === false) throw new Error("")
            return v.validation.validValue
          })
          resolvedSubvals = { isValid: true, validValue: subvals, error: null }
        } catch (e) {
          resolvedSubvals = { isValid: false, validValue: null, error: e.message }
        }
        const validValue = f.validate(resolvedSubvals.validValue)
        return { isValid: true, validValue, error: null }
      } catch (e) {
        return { isValid: false, validValue: null, error: e?.message }
      }
    }
    const validation = validate()
    return {
      ...readable,
      validation,
      fields: subfields,
      hasErrorWithin: subfields.some((x) => x.hasErrorWithin),
    }
  } else {
    throw new Error(`Unknown field type ${f._field_type}`)
  }
}

export function attachErrors(f, state, errors) {
  if (f._field_type === "cell") {
    return {
      ...state,
      ...(Interaction.shouldShowError(state.status) && errors && { error: errors }),
    }
  } else if (f._field_type === "group") {
    return Object.assign(
      Object.fromEntries(
        Object.entries(f.spec).map(([k, v]) => [
          k,
          attachErrors(v, state[k], deepAccess(errors, v.errorKey ?? MSString.toSnake(k))),
        ]),
      ),
      {
        _controller: state._controller,
      },
    )
  } else if (f._field_type === "list") {
    return Object.assign(
      state.map((x, i) => attachErrors(f.spec, x, errors?.[i])),
      {
        _controller: state._controller,
      },
    )
  } else {
    throw new Error(`Unknown field type ${f._field_type}`)
  }
}

export function flattenAccessors(f, readable) {
  if (f._field_type === "cell") {
    return readable
  } else if (f._field_type === "group") {
    return Object.assign(
      Object.fromEntries(
        Object.entries(f.spec).map(([k, v]) => [k, flattenAccessors(v, readable.fields[k])]),
      ),
      {
        _controller: MSObject.omit(readable, ["fields"]),
      },
    )
  } else if (f._field_type === "list") {
    return Object.assign(
      readable.fields.map((x) => flattenAccessors(f.spec, x)),
      {
        _controller: MSObject.omit(readable, ["fields"]),
      },
    )
  } else {
    throw new Error(`Unknown field type ${f._field_type}`)
  }
}

export function collectErrors(f, readable) {
  if (f._field_type === "cell") {
    return readable.validation.isValid ? null : readable.validation.error
  } else if (f._field_type === "group") {
    return Object.fromEntries(
      Object.entries(f.spec).map(([k, v]) => [k, collectErrors(v, readable[k])]),
    )
  } else if (f._field_type === "list") {
    return readable.map((x) => collectErrors(f.spec, x))
  } else {
    throw new Error(`Unknown field type ${f._field_type}`)
  }
}

export function attachLazyHooks(f, state, config) {
  if (f._field_type === "cell") {
    return state
  } else if (f._field_type === "group") {
    const fieldCores = Object.fromEntries(
      Object.entries(f.spec).map(([k, v]) => [
        k,
        attachLazyHooks(v, state[k], config?.config?.[k]),
      ]),
    )
    const hooks = config?.hooks?.(fieldCores)
    return Object.assign(
      Object.fromEntries(
        Object.entries(fieldCores).map(([k, v]) => {
          if ("update" in v) {
            // scalar
            return [
              k,
              {
                ...v,
                ...(hooks?.[`onChange${k[0].toUpperCase() + k.slice(1)}`] && {
                  update: (newValue) => {
                    v.update(newValue)
                    hooks?.[`onChange${k[0].toUpperCase() + k.slice(1)}`]?.(newValue)
                  },
                }),
              },
            ]
          } else {
            return [
              k,
              Object.assign(Array.isArray(v) ? [...v] : { ...v }, {
                _controller: {
                  ...v._controller,
                  update: (newValue) => {
                    v._controller.update(newValue)
                    hooks?.[`onChange${k[0].toUpperCase() + k.slice(1)}`]?.(newValue)
                  },
                },
              }),
            ]
          }
        }),
      ),
      {
        _controller: state._controller,
      },
    )
  } else if (f._field_type === "list") {
    return Object.assign(
      state.map((x) => attachLazyHooks(f.spec, x, config?.config?.(x)?.values)),
      {
        _controller: state._controller,
      },
    )
  } else {
    throw new Error(`Unknown field type ${f._field_type}`)
  }
}

export function callLazyOnInits(f, state, config) {
  if (f._field_type === "cell") {
    // pass
  } else if (f._field_type === "group") {
    const hooks = config?.hooks?.(state)
    Object.entries(state).forEach(([k, v]) => {
      if (k !== "_controller") {
        if (!v.disabled) {
          hooks?.[`onChange${k[0].toUpperCase() + k.slice(1)}`]?.(writable(f.spec[k], v))
        }
        callLazyOnInits(f.spec[k], v, config?.config?.[k])
      }
    })
  } else if (f._field_type === "list") {
    // pass
  } else {
    throw new Error(`Unknown field type ${f._field_type}`)
  }
}
