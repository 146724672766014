/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type BeamBalanceTransactionTypeChargeState =
  (typeof BeamBalanceTransactionTypeChargeState)[keyof typeof BeamBalanceTransactionTypeChargeState]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BeamBalanceTransactionTypeChargeState = {
  new: "new",
  pending: "pending",
  complete: "complete",
  failed: "failed",
} as const
