import { toBase64 } from "msutils/misc"
import { MosaicHref } from "./types"

declare global {
  export interface RouteDefinitionsV2 {
    home: {
      template: "/"
      params: {}
    }
  }
}

type RouteKey = keyof RouteDefinitionsV2

type ParseTemplate<T extends string> = T extends `${infer Start}/${infer End}`
  ? `${ParseTemplate<Start>}/${ParseTemplate<End>}`
  : T extends `:${string}`
  ? string
  : T

type PushSpec<T extends RouteKey> = {
  name: T
  template: RouteDefinitionsV2[T]["template"]
  parsedTemplate: ParseTemplate<RouteDefinitionsV2[T]["template"]>
  params: RouteDefinitionsV2[T]["params"]
}

type RecursivePartial<T extends object> = {
  [K in keyof T]?: T[K] extends object ? RecursivePartial<T[K]> : T[K]
}

type ModuleLocator = {
  [K in RouteKey]: PushSpec<K>["params"] extends Record<string, never>
    ? [PushSpec<K>["parsedTemplate"]]
    : [path: PushSpec<K>["parsedTemplate"], params?: RecursivePartial<PushSpec<K>["params"]>]
}[RouteKey]

function getUrlParams(q: Record<string, any> = {}) {
  const query = Object.keys(q)
    .map((k) => `${k}=${toBase64(`${JSON.stringify(q[k])}`)}`)
    .join("&")
  return query ? `?${query}` : ""
}

export function Module(...pushProps: ModuleLocator) {
  const href = `${pushProps[0]}${getUrlParams(pushProps[1])}` as MosaicHref

  return { href }
}

export function RawRoute(route: string) {
  return route as MosaicHref
}
