import { ReactNode } from "react"
import Typography from "compass/data/Typography"
import { t } from "content"
import LoadingPage from "components/misc/LoadingPage"
import { useLayoutFlagContext } from "compass/layout/LayoutFlagContext"
import { cn } from "msutils/classnames"

type PaginationProps = {
  unfilteredCount: number
  filteredCount: number | undefined
  empty: ReactNode
  isLoading?: boolean
  sort?: ReactNode
  search?: ReactNode
  filter?: ReactNode
  navigator?: ReactNode
  children: ReactNode
}

export function Pagination({
  filteredCount = 0,
  unfilteredCount,
  sort,
  search,
  filter,
  navigator,
  isLoading,
  empty,
  children,
}: PaginationProps) {
  const noResults = <Typography className="text-th-coolgrey-1 p-5">{t("No results")}</Typography>
  const { disablePaginatorBarPadding } = useLayoutFlagContext()

  return (
    <div className={cn("vflex", !disablePaginatorBarPadding && "gap-6")}>
      {unfilteredCount > 0 && (
        <div
          className={cn(
            "flex flex-wrap md:flex-nowrap justify-between items-top gap-4",
            disablePaginatorBarPadding && "p-5",
          )}
        >
          {search}
          {filter}
          {sort}
        </div>
      )}
      <div className="vflex">
        {unfilteredCount === 0 ? (
          typeof empty === "string" ? (
            <Typography className="text-th-coolgrey-1 p-5 text-center">{empty}</Typography>
          ) : (
            empty
          )
        ) : filteredCount === 0 ? (
          isLoading ? (
            <LoadingPage />
          ) : (
            noResults
          )
        ) : (
          children
        )}
        {filteredCount > 0 && navigator}
      </div>
    </div>
  )
}
