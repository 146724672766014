/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type VendorListRowComplianceStatusSummaryProjectLevelStatusesItemStatus =
  (typeof VendorListRowComplianceStatusSummaryProjectLevelStatusesItemStatus)[keyof typeof VendorListRowComplianceStatusSummaryProjectLevelStatusesItemStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const VendorListRowComplianceStatusSummaryProjectLevelStatusesItemStatus = {
  compliant: "compliant",
  not_compliant: "not_compliant",
} as const
