/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type ListProjectImportRequestsOrdering =
  (typeof ListProjectImportRequestsOrdering)[keyof typeof ListProjectImportRequestsOrdering]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ListProjectImportRequestsOrdering = {
  source_id: "source_id",
  "-source_id": "-source_id",
  state: "state",
  "-state": "-state",
  created_at: "created_at",
  "-created_at": "-created_at",
  updated_at: "updated_at",
  "-updated_at": "-updated_at",
} as const
