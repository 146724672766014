import IconBase from "compass-local/legacy/IconBase"
import { StrokedIconProps } from "../types"

export default function Directory({
  width,
  height = 20,
  onClick,
  href,
  tabIndex,
  className,
  thickness = 1.8,
}: StrokedIconProps) {
  return (
    <IconBase className={className} onClick={onClick} href={href} tabIndex={tabIndex}>
      <svg
        width={width?.toFixed(1)}
        height={height.toFixed(1)}
        viewBox="0 0.5 18 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.33331 4.16667H2.88886C2.41737 4.16667 1.96518 4.35397 1.63178 4.68737C1.29838 5.02076 1.11108 5.47295 1.11108 5.94444V13.9444C1.11108 14.4159 1.29838 14.8681 1.63178 15.2015C1.96518 15.5349 2.41737 15.7222 2.88886 15.7222H15.3333C15.8048 15.7222 16.257 15.5349 16.5904 15.2015C16.9238 14.8681 17.1111 14.4159 17.1111 13.9444V5.94444C17.1111 5.47295 16.9238 5.02076 16.5904 4.68737C16.257 4.35397 15.8048 4.16667 15.3333 4.16667H10.8889M7.33331 4.16667V3.27778C7.33331 2.80628 7.52061 2.3541 7.85401 2.0207C8.1874 1.6873 8.63959 1.5 9.11108 1.5C9.58258 1.5 10.0348 1.6873 10.3682 2.0207C10.7016 2.3541 10.8889 2.80628 10.8889 3.27778V4.16667M7.33331 4.16667C7.33331 4.63816 7.52061 5.09035 7.85401 5.42375C8.1874 5.75714 8.63959 5.94444 9.11108 5.94444C9.58258 5.94444 10.0348 5.75714 10.3682 5.42375C10.7016 5.09035 10.8889 4.63816 10.8889 4.16667M6.44442 11.2778C6.91591 11.2778 7.3681 11.0905 7.7015 10.7571C8.03489 10.4237 8.2222 9.9715 8.2222 9.5C8.2222 9.0285 8.03489 8.57632 7.7015 8.24292C7.3681 7.90952 6.91591 7.72222 6.44442 7.72222C5.97292 7.72222 5.52074 7.90952 5.18734 8.24292C4.85394 8.57632 4.66664 9.0285 4.66664 9.5C4.66664 9.9715 4.85394 10.4237 5.18734 10.7571C5.52074 11.0905 5.97292 11.2778 6.44442 11.2778ZM6.44442 11.2778C7.60531 11.2778 8.59286 12.02 8.95997 13.0556M6.44442 11.2778C5.89276 11.2776 5.35462 11.4485 4.9041 11.7669C4.45359 12.0853 4.11287 12.5355 3.92886 13.0556M11.7778 8.61111H14.4444M11.7778 12.1667H13.5555"
          stroke="currentColor"
          strokeWidth={thickness.toFixed(1)}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </IconBase>
  )
}
