/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type BeamBalanceTransactionTypeTransferType =
  (typeof BeamBalanceTransactionTypeTransferType)[keyof typeof BeamBalanceTransactionTypeTransferType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BeamBalanceTransactionTypeTransferType = {
  transfer: "transfer",
} as const
