/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type ListJoinedProjectsOrdering =
  (typeof ListJoinedProjectsOrdering)[keyof typeof ListJoinedProjectsOrdering]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ListJoinedProjectsOrdering = {
  created_at: "created_at",
  "-created_at": "-created_at",
  updated_at: "updated_at",
  "-updated_at": "-updated_at",
  source_id: "source_id",
  "-source_id": "-source_id",
} as const
