/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type PaymentSourceType = (typeof PaymentSourceType)[keyof typeof PaymentSourceType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PaymentSourceType = {
  credit_card: "credit_card",
  bank_account: "bank_account",
} as const
