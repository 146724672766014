/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type ListPayeeBankAccountsOrdering =
  (typeof ListPayeeBankAccountsOrdering)[keyof typeof ListPayeeBankAccountsOrdering]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ListPayeeBankAccountsOrdering = {
  account_number_last_4: "account_number_last_4",
  "-account_number_last_4": "-account_number_last_4",
  bank_name: "bank_name",
  "-bank_name": "-bank_name",
  created_at: "created_at",
  "-created_at": "-created_at",
  updated_at: "updated_at",
  "-updated_at": "-updated_at",
  archived: "archived",
  "-archived": "-archived",
} as const
