/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type StripeIdentityVerificationSessionStatus =
  (typeof StripeIdentityVerificationSessionStatus)[keyof typeof StripeIdentityVerificationSessionStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StripeIdentityVerificationSessionStatus = {
  canceled: "canceled",
  processing: "processing",
  requires_input: "requires_input",
  verified: "verified",
} as const
