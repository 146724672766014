import { ReactNode } from "react"
import NextLink from "next/link"
import { CompassTypes } from "compass-local"
import { Link2Utils } from "../utils"

type Props = {
  href: CompassTypes["href"]
  children?: ReactNode
  stopPropagation?: boolean
}

export default function LinkDONOTUSE(props: Props) {
  const { href, stopPropagation, children } = props
  const push = Link2Utils.usePush()

  return (
    <NextLink
      href={href}
      onClick={(e) => {
        e.preventDefault()
        push(href)
        if (stopPropagation) e.stopPropagation()
      }}
    >
      {children}
    </NextLink>
  )
}
