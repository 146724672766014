/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type BeamBalanceTransactionTypeInvoicePaymentDirection =
  (typeof BeamBalanceTransactionTypeInvoicePaymentDirection)[keyof typeof BeamBalanceTransactionTypeInvoicePaymentDirection]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BeamBalanceTransactionTypeInvoicePaymentDirection = {
  debit: "debit",
  credit: "credit",
} as const
