/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type ListLienWaiverTemplatesOrdering =
  (typeof ListLienWaiverTemplatesOrdering)[keyof typeof ListLienWaiverTemplatesOrdering]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ListLienWaiverTemplatesOrdering = {
  name: "name",
  "-name": "-name",
  created_at: "created_at",
  "-created_at": "-created_at",
  updated_at: "updated_at",
  "-updated_at": "-updated_at",
} as const
