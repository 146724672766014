import axios from "axios"
import * as H from "./generated/hooks2"

export function useUploadFileHook() {
  const createFileUpload = H.useCreateFileUploadHook()
  const createFile = H.useCreateFileHook()

  return async (f: File) => {
    const uploadIntent = await createFileUpload({
      original_file_name: f.name,
      file_type: f.type,
    })

    const uploadToS3 = axios.post(
      uploadIntent.upload_url,
      // Note: S3 cares about what order the fields in the body are in...
      { ...uploadIntent.fields, file: f },
      { headers: { "Content-Type": "multipart/form-data" } },
    )

    const createdFileP = createFile({ file_upload_id: uploadIntent.id })
    const [, createdFile] = await Promise.all([uploadToS3, createdFileP])
    return createdFile.id
  }
}
