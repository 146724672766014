/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type PayerContactPatchContactType =
  (typeof PayerContactPatchContactType)[keyof typeof PayerContactPatchContactType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PayerContactPatchContactType = {
  homeowner: "homeowner",
  contractor: "contractor",
  developer: "developer",
} as const
