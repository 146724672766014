/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type InvoicePaymentTypeBankAccountMethod =
  (typeof InvoicePaymentTypeBankAccountMethod)[keyof typeof InvoicePaymentTypeBankAccountMethod]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InvoicePaymentTypeBankAccountMethod = {
  bank_account: "bank_account",
} as const
