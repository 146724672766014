/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type ComplianceSettingsListPatchCoverageTypesRequiredItem =
  (typeof ComplianceSettingsListPatchCoverageTypesRequiredItem)[keyof typeof ComplianceSettingsListPatchCoverageTypesRequiredItem]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ComplianceSettingsListPatchCoverageTypesRequiredItem = {
  general: "general",
  automotive: "automotive",
  umbrella: "umbrella",
  excess: "excess",
  workers_comp: "workers_comp",
} as const
