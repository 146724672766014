import { Cell, CellField } from "./base"
import { Branded, EmptyString, FieldError } from "./utils"

type EinString = Branded<string, "ein">
type EinField<V> = CellField<string, V>

type EinProps<TReq> = {
  required?: TReq
  initValue?: string
  errorKey?: string
}

function validateEin(s: string) {
  if (Number.isNaN(s) || s.length !== 9) {
    throw new Error("EIN must be 9 numeric characters")
  }
  return s
}

export function Ein<TReq extends boolean = false>(
  props?: EinProps<TReq>,
): EinField<TReq extends true ? EinString : EinString | EmptyString> {
  const validate: any = (x: string) => {
    if (props?.required) {
      if (!x) {
        throw new FieldError("Required")
      } else {
        return validateEin(x)
      }
    } else if (!x) {
      return ""
    } else {
      return validateEin(x)
    }
  }
  return Cell({
    initValue: props?.initValue ?? "",
    validate,
    errorKey: props?.errorKey,
  })
}
