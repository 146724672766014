/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type ListBillsPageSummarysOrdering =
  (typeof ListBillsPageSummarysOrdering)[keyof typeof ListBillsPageSummarysOrdering]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ListBillsPageSummarysOrdering = {
  all_count: "all_count",
  "-all_count": "-all_count",
  all_amount: "all_amount",
  "-all_amount": "-all_amount",
  unpaid_count: "unpaid_count",
  "-unpaid_count": "-unpaid_count",
  unpaid_amount: "unpaid_amount",
  "-unpaid_amount": "-unpaid_amount",
  scheduled_count: "scheduled_count",
  "-scheduled_count": "-scheduled_count",
  scheduled_amount: "scheduled_amount",
  "-scheduled_amount": "-scheduled_amount",
  paid_count: "paid_count",
  "-paid_count": "-paid_count",
  paid_amount: "paid_amount",
  "-paid_amount": "-paid_amount",
  lien_waiver_requirement_exists: "lien_waiver_requirement_exists",
  "-lien_waiver_requirement_exists": "-lien_waiver_requirement_exists",
  created_at: "created_at",
  "-created_at": "-created_at",
  updated_at: "updated_at",
  "-updated_at": "-updated_at",
} as const
