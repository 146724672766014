/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type ListEstimateCoverPagesOrdering =
  (typeof ListEstimateCoverPagesOrdering)[keyof typeof ListEstimateCoverPagesOrdering]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ListEstimateCoverPagesOrdering = {
  created_at: "created_at",
  "-created_at": "-created_at",
  updated_at: "updated_at",
  "-updated_at": "-updated_at",
  title: "title",
  "-title": "-title",
  body: "body",
  "-body": "-body",
} as const
