/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type BillListRowOutboundSyncDetailsLatestFailureReason =
  (typeof BillListRowOutboundSyncDetailsLatestFailureReason)[keyof typeof BillListRowOutboundSyncDetailsLatestFailureReason]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BillListRowOutboundSyncDetailsLatestFailureReason = {
  line_items_missing_cost_code: "line_items_missing_cost_code",
  null: null,
} as const
