/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type EmployeeRole = (typeof EmployeeRole)[keyof typeof EmployeeRole]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EmployeeRole = {
  admin: "admin",
  member: "member",
  project_manager: "project_manager",
  estimator: "estimator",
  cardholder: "cardholder",
  field_crew_member: "field_crew_member",
  salesperson: "salesperson",
} as const
