/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type ListBillOcrRawResultsOrdering =
  (typeof ListBillOcrRawResultsOrdering)[keyof typeof ListBillOcrRawResultsOrdering]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ListBillOcrRawResultsOrdering = {
  status: "status",
  "-status": "-status",
  predicted_number: "predicted_number",
  "-predicted_number": "-predicted_number",
  predicted_date: "predicted_date",
  "-predicted_date": "-predicted_date",
  predicted_due_date: "predicted_due_date",
  "-predicted_due_date": "-predicted_due_date",
  created_at: "created_at",
  "-created_at": "-created_at",
  updated_at: "updated_at",
  "-updated_at": "-updated_at",
} as const
