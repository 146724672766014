/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type AdminTransactionState =
  (typeof AdminTransactionState)[keyof typeof AdminTransactionState]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AdminTransactionState = {
  new: "new",
  pending: "pending",
  complete: "complete",
  failed: "failed",
} as const
