import { createContext, ReactNode, useContext, useMemo } from "react"
import { loadStripe, Stripe } from "@stripe/stripe-js"
import { Elements } from "@stripe/react-stripe-js"
import { STRIPE_API_KEY } from "env"
import { consoleLog } from "utils/console"

const stripePromise = loadStripe(STRIPE_API_KEY).then((s) => {
  consoleLog("[Stripe] init")
  return s
})

type TStripeContext = {
  stripePromise: Promise<Stripe | null>
}
const StripeContext = createContext<TStripeContext | undefined>(undefined)

export const useStripeContext = () => {
  const context = useContext(StripeContext)
  if (context === undefined) throw new Error("StripeContext must be used within a StripeProvider")
  return context
}

type Props = {
  children: ReactNode
}

export default function StripeProvider({ children }: Props) {
  const contextValue = useMemo(() => ({ stripePromise }), [])

  return (
    <StripeContext.Provider value={contextValue}>
      <Elements
        stripe={stripePromise}
        options={{
          fonts: [
            { cssSrc: "https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap" },
          ],
          mode: "setup",
          currency: "usd",
        }}
      >
        {children}
      </Elements>
    </StripeContext.Provider>
  )
}
