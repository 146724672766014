import IconBase from "compass-local/legacy/IconBase"
import { IconProps } from "../types"

export default function HouseWithMoney({
  width,
  height = 29,
  onClick,
  href,
  tabIndex,
  className,
}: IconProps) {
  return (
    <IconBase href={href} className={className} onClick={onClick} tabIndex={tabIndex}>
      <svg
        width={width?.toFixed(1)}
        height={height.toFixed(1)}
        viewBox="0 0 29 29"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M27.605 8.65238L15.3266 0.726972C14.7562 0.366726 14.0357 0.366726 13.4653 0.726972L1.18696 8.65238C0.376406 9.16273 0.166262 10.2135 0.676611 11.024C1.18696 11.8045 2.23768 12.0447 3.04823 11.5344L5.08962 10.2135V14.5964C6.05028 14.2062 7.101 13.996 8.21176 13.996C12.6548 13.996 16.3173 17.4484 16.6475 21.8314H22.0212C22.9818 21.8314 23.7324 21.0509 23.7324 20.1202V10.2135L25.7738 11.5344C26.074 11.7145 26.3742 11.8045 26.7044 11.8045C27.2748 11.8045 27.8151 11.5344 28.1454 11.024C28.6257 10.2135 28.4156 9.16273 27.605 8.65238Z"
          fill="currentColor"
        />
        <path
          d="M8.18168 15.9174C4.57922 15.9174 1.63721 18.8293 1.63721 22.4618C1.63721 26.0943 4.5492 29.0063 8.18168 29.0063C11.7841 29.0063 14.7261 26.0943 14.7261 22.4618C14.7261 18.8293 11.7841 15.9174 8.18168 15.9174ZM8.84213 25.704V26.3945C8.84213 26.5446 8.72205 26.6647 8.57194 26.6647H7.79141C7.64131 26.6647 7.52123 26.5446 7.52123 26.3945V25.7341C6.71067 25.644 6.08024 24.9836 6.0202 24.173C6.0202 24.113 6.05022 24.0229 6.08024 23.9629C6.14028 23.9028 6.20032 23.8728 6.29038 23.8728H7.07092C7.191 23.8728 7.31108 23.9629 7.3411 24.0829C7.37112 24.2631 7.55125 24.4132 7.76139 24.4132H8.45186C8.81211 24.4132 9.11231 24.143 9.14233 23.8128C9.17235 23.6326 9.11231 23.4525 8.96221 23.3024C8.84213 23.1523 8.662 23.0923 8.48188 23.0923H7.97153C6.92082 23.0923 6.0202 22.3117 5.93014 21.321C5.84008 20.3004 6.53055 19.3697 7.52123 19.1896V18.4991C7.52123 18.349 7.64131 18.2289 7.79141 18.2289H8.57194C8.72205 18.2289 8.84213 18.349 8.84213 18.4991V19.1596C9.65268 19.2496 10.2831 19.9101 10.3432 20.7206C10.3432 20.7807 10.3131 20.8707 10.2831 20.9308C10.2231 20.9908 10.163 21.0208 10.073 21.0208H9.29244C9.17235 21.0208 9.05227 20.9308 9.02225 20.8107C8.99223 20.6306 8.81211 20.4805 8.60196 20.4805H7.91149C7.55125 20.4805 7.25104 20.7507 7.22102 21.0809C7.191 21.261 7.25104 21.4411 7.40114 21.5912C7.52123 21.7413 7.70135 21.8014 7.88147 21.8014H8.48188C9.05227 21.8014 9.56262 22.0415 9.95289 22.4618C10.3432 22.8821 10.5233 23.4225 10.4632 23.9929C10.3732 24.8034 9.71272 25.5239 8.84213 25.704Z"
          fill="currentColor"
        />
      </svg>
    </IconBase>
  )
}
