/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type ListEstimatesType = (typeof ListEstimatesType)[keyof typeof ListEstimatesType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ListEstimatesType = {
  project: "project",
  change_order: "change_order",
} as const
