/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type InvoicePaymentFeeListRowState =
  (typeof InvoicePaymentFeeListRowState)[keyof typeof InvoicePaymentFeeListRowState]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InvoicePaymentFeeListRowState = {
  pending: "pending",
  complete: "complete",
} as const
