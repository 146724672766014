/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type InvoiceApprovalActionCreateType =
  (typeof InvoiceApprovalActionCreateType)[keyof typeof InvoiceApprovalActionCreateType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InvoiceApprovalActionCreateType = {
  approve: "approve",
  reject: "reject",
  resubmit: "resubmit",
  unapprove: "unapprove",
} as const
