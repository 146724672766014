import { Fragment, ReactNode } from "react"
import { BreadcrumbSegment } from "compass-local/Breadcrumb"
import TabGroup, { TabGroupUtils } from "compass-local/TabGroup"
import Icon from "compass/data/Icon"
import Typography from "compass/data/Typography"
import useScreenSize from "compass/theme/useScreenSize"
import { Collapsable } from "msutils/array"
import { Action } from "utils/actions"
import ComponentPropType from "utils/types/ComponentPropTypes"
import { Module } from "./Module"

type TabDef<T extends string> = {
  id: T
  name: string
  qualifiedContent: ReactNode
  inline?: boolean
  metric?: string
  icon?: ComponentPropType<typeof Icon>["name"] | JSX.Element
}

// eslint-disable-next-line
export function tab<T extends string>(
  id: T,
  name: string,
  props: Omit<TabDef<T>, "name" | "id">,
): TabDef<T> {
  return { id, name, ...props }
}

type Props<T extends string> = {
  title: string
  tabState: [T, (newValue: T) => void]
  tabs: TabDef<T>[]
  badge?: ReactNode
  banner?: ReactNode
  actions?: Action.Config[]
  breadcrumbs?: Collapsable<BreadcrumbSegment[]>
  breadcrumbRoot?: BreadcrumbSegment
  mount?: ReactNode
}

export function MetaModule<T extends string>({
  title,
  badge,
  banner,
  actions,
  breadcrumbs,
  breadcrumbRoot,
  tabState,
  tabs,
  mount,
}: Props<T>) {
  const activeTabs = tabs.filter((x) => !!x.qualifiedContent)
  const currentTab = activeTabs.find((x) => tabState[0] === x.id)
  const sz = useScreenSize()

  return (
    <Module
      title={sz === "sm" && currentTab && !currentTab.inline ? currentTab.name : title}
      badge={sz === "sm" && currentTab && !currentTab.inline ? undefined : badge}
      banner={banner}
      actions={actions}
      breadcrumbs={[
        ...(breadcrumbs ?? []),
        sz === "sm" && currentTab && !currentTab.inline && breadcrumbRoot,
      ]}
      mount={mount}
      mainContent={
        sz === "sm" ? (
          <>
            {currentTab && !currentTab.inline ? (
              <>{currentTab.qualifiedContent}</>
            ) : (
              <div className="vflex gap-5">
                <div className="vflex gap-3">
                  {activeTabs
                    .filter((x) => !x.inline)
                    .map((x) => (
                      <div
                        key={x.id}
                        className="flex gap-4 items-center justify-between py-2 cursor-pointer"
                        onClick={() => tabState[1](x.id)}
                      >
                        <div className="flex gap-2 items-center">
                          {x.icon && (
                            <div className="h-[45px] w-[45px] shrink-0 bg-th-bg-slate flex justify-center items-center rounded-full">
                              {typeof x.icon === "string" || Array.isArray(x.icon) ? (
                                <Icon name={x.icon} height={16} />
                              ) : (
                                x.icon
                              )}
                            </div>
                          )}
                          {x.metric ? (
                            <div className="vflex gap-1">
                              <Typography>{x.name}</Typography>
                              <Typography variant="title">{x.metric}</Typography>
                            </div>
                          ) : (
                            <Typography variant="title">{x.name}</Typography>
                          )}
                        </div>
                        <Icon name="chevron" color="text-th-brown-2" height={20} />
                      </div>
                    ))}
                </div>
                {activeTabs.map(
                  (x) => x.inline && <Fragment key={x.id}>{x.qualifiedContent}</Fragment>,
                )}
              </div>
            )}
          </>
        ) : (
          <TabGroup
            value={tabState[0]}
            update={(newValue) => tabState[1](newValue)}
            options={activeTabs.map((x) =>
              TabGroupUtils.option(x.id, x.name, {
                icon:
                  x.icon &&
                  (typeof x.icon === "string" || Array.isArray(x.icon) ? (
                    <Icon name={x.icon} height={16} />
                  ) : (
                    x.icon
                  )),
              }),
            )}
            type="top-section"
            ui={currentTab?.qualifiedContent}
          />
        )
      }
    />
  )
}
