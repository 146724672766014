/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type ProjectPermissionDeletePermissionNotAllowedReason =
  (typeof ProjectPermissionDeletePermissionNotAllowedReason)[keyof typeof ProjectPermissionDeletePermissionNotAllowedReason]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProjectPermissionDeletePermissionNotAllowedReason = {
  not_creator: "not_creator",
  invoice_exists: "invoice_exists",
  unspecified_restriction_exists: "unspecified_restriction_exists",
  null: null,
} as const
