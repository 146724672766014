/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type BillOcrRawResultStatus =
  (typeof BillOcrRawResultStatus)[keyof typeof BillOcrRawResultStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BillOcrRawResultStatus = {
  new: "new",
  pending: "pending",
  complete: "complete",
} as const
