/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type LedgerAccountType = (typeof LedgerAccountType)[keyof typeof LedgerAccountType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const LedgerAccountType = {
  bank: "bank",
  other_current_asset: "other_current_asset",
  fixed_asset: "fixed_asset",
  other_asset: "other_asset",
  accounts_receivable: "accounts_receivable",
  equity: "equity",
  expense: "expense",
  other_expense: "other_expense",
  cost_of_goods_sold: "cost_of_goods_sold",
  accounts_payable: "accounts_payable",
  credit_card: "credit_card",
  long_term_liability: "long_term_liability",
  other_current_liability: "other_current_liability",
  income: "income",
  other_income: "other_income",
} as const
