/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type ListCompanyCamLinksOrdering =
  (typeof ListCompanyCamLinksOrdering)[keyof typeof ListCompanyCamLinksOrdering]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ListCompanyCamLinksOrdering = {
  type: "type",
  "-type": "-type",
  external_id: "external_id",
  "-external_id": "-external_id",
  external_url: "external_url",
  "-external_url": "-external_url",
  created_at: "created_at",
  "-created_at": "-created_at",
  updated_at: "updated_at",
  "-updated_at": "-updated_at",
} as const
