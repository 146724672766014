/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type GetUpdatedTokenStatusOutputTokenStatus =
  (typeof GetUpdatedTokenStatusOutputTokenStatus)[keyof typeof GetUpdatedTokenStatusOutputTokenStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetUpdatedTokenStatusOutputTokenStatus = {
  disabled: "disabled",
  unconfirmed: "unconfirmed",
  active: "active",
  null: null,
} as const
