import * as Sentry from "@sentry/nextjs"
import { Cb } from "cb"
import { enableOptionalServices, ENVIRONMENT, SENTRY_DSN } from "env"
import { MSTimestamp } from "msutils"
import { consoleLog } from "utils/console"

const pageSetupTime = MSTimestamp.now().format()
const disabled = !enableOptionalServices(ENVIRONMENT)

namespace SentryClient {
  if (!disabled)
    Sentry.init({
      dsn: SENTRY_DSN,
      tracesSampleRate: 1.0,
      environment: ENVIRONMENT,
      release: process.env.NEXT_PUBLIC_APP_VERSION,
    })

  export function report2(error: Error, props: { tags: object; level?: "error" | "warning" }) {
    if (disabled) {
      consoleLog("Sentry (v2) is disabled, so printing error instead")
      consoleLog(error)
      consoleLog(`message: ${error.message}`)
    } else {
      Sentry.withScope((scope) => {
        scope.setLevel(props.level ?? "error")
        Sentry.captureException(error, {
          extra: { fullMessage: error.message },
          tags: { ...props.tags, pageSetupTime },
          fingerprint: [error.message],
        })
      })
    }
  }

  type IdentifyProps = {
    userId: string
    business: Cb.Business | null
    email: string | null
    phoneNumber: string | null
    isImpersonator: boolean
  }

  export function identify({
    userId,
    email,
    phoneNumber,
    business,
    isImpersonator,
  }: IdentifyProps) {
    if (!disabled) {
      Sentry.setUser({
        id: userId,
        email: email ?? undefined,
        username: email ?? phoneNumber ?? undefined,
      })
      Sentry.setTags({
        user_id: userId,
        user_email: email,
        phone_number: phoneNumber,
        is_impersonator: isImpersonator,
        on_hold: business?.on_hold ?? false,
      })
    }
  }

  export function deidentify() {
    if (!disabled) {
      Sentry.setUser(null)
      Sentry.setTags({ user_id: null, user_email: null, phone_number: null, is_impersonator: null })
    }
  }
}

export { SentryClient }
