import type { AppProps } from "next/app"
import "styles/globals.css"
import "navigator"
import "core-js/actual/array/at"
import dynamic from "next/dynamic"
import { useForcedRefresh } from "root/utils"
import { useErrorHandling } from "root/component-utils"

const Root = dynamic(() => import("root"), { ssr: false })

export default function App(props: AppProps) {
  useErrorHandling()
  useForcedRefresh()
  return <Root {...props} />
}
