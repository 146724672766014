/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type OnboardingSurveyPatchNumProjectsCompletedYearly =
  (typeof OnboardingSurveyPatchNumProjectsCompletedYearly)[keyof typeof OnboardingSurveyPatchNumProjectsCompletedYearly]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OnboardingSurveyPatchNumProjectsCompletedYearly = {
  fewer_than_three: "fewer_than_three",
  three_to_ten: "three_to_ten",
  eleven_to_twenty_five: "eleven_to_twenty_five",
  more_than_twenty_five: "more_than_twenty_five",
  null: null,
} as const
