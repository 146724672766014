/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type ListExpenseCardsOrdering =
  (typeof ListExpenseCardsOrdering)[keyof typeof ListExpenseCardsOrdering]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ListExpenseCardsOrdering = {
  cardholder_name: "cardholder_name",
  "-cardholder_name": "-cardholder_name",
  last_4: "last_4",
  "-last_4": "-last_4",
  status: "status",
  "-status": "-status",
  spending_limit_period: "spending_limit_period",
  "-spending_limit_period": "-spending_limit_period",
  spending_limit_amount: "spending_limit_amount",
  "-spending_limit_amount": "-spending_limit_amount",
  created_at: "created_at",
  "-created_at": "-created_at",
  updated_at: "updated_at",
  "-updated_at": "-updated_at",
} as const
