/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type BeamBalanceTransactionTypeCashBackPaymentState =
  (typeof BeamBalanceTransactionTypeCashBackPaymentState)[keyof typeof BeamBalanceTransactionTypeCashBackPaymentState]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BeamBalanceTransactionTypeCashBackPaymentState = {
  new: "new",
  pending: "pending",
  complete: "complete",
  failed: "failed",
} as const
