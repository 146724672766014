type UploadFileProps<TSingle extends boolean> = {
  single?: TSingle
  max?: TSingle extends true ? 1 : number
  accept?: string[]
}

export function uploadFile<TSingle extends boolean = false>(
  props?: UploadFileProps<TSingle>,
): Promise<TSingle extends true ? File | null : File[] | null> {
  return new Promise<any>((res) => {
    const dummyInput = document.createElement("input")
    dummyInput.type = "file"
    dummyInput.style.width = "1px"
    dummyInput.style.height = "1px"
    dummyInput.style.background = "transparent"
    dummyInput.multiple = !props?.single
    dummyInput.accept = props?.accept?.join(",") ?? ""
    dummyInput.onchange = (e: any) => {
      document.body.removeChild(dummyInput)
      const fileList = e.target.files as FileList
      const files: File[] = []
      // eslint-disable-next-line
      while (true) {
        const item = fileList.item(files.length)
        if (item) {
          files.push(item)
        } else {
          break
        }
      }
      res(props?.single ? files[0] : files)
    }
    ;(dummyInput as any).oncancel = () => {
      document.body.removeChild(dummyInput)
      res(null)
    }

    document.body.appendChild(dummyInput)
    dummyInput.click()
  })
}
