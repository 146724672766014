/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type EstimateType = (typeof EstimateType)[keyof typeof EstimateType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EstimateType = {
  project: "project",
  change_order: "change_order",
} as const
