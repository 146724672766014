import IconBase from "compass-local/legacy/IconBase"
import { IconProps } from "../types"

export default function Card2({
  width,
  height = 19,
  onClick,
  href,
  tabIndex,
  className,
}: IconProps) {
  return (
    <IconBase href={href} className={className} onClick={onClick} tabIndex={tabIndex}>
      <svg
        width={width?.toFixed(1)}
        height={height.toFixed(1)}
        viewBox="0 0 28 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M28.0001 4.13649V2.56849C28.0001 1.35889 27.0145 0.373291 25.8049 0.373291H2.21766C1.00806 0.373291 0.0224609 1.35889 0.0224609 2.56849V4.13649H28.0001Z"
          fill="currentColor"
        />
        <path
          d="M0 7.04846V16.3445C0 17.5541 0.9856 18.5397 2.1952 18.5397H25.76C26.9696 18.5397 27.9552 17.5541 27.9552 16.3445V7.04846H0ZM25.9168 15.7173C25.9168 16.1205 25.5808 16.4565 25.1776 16.4565H19.152C18.7488 16.4565 18.4128 16.1205 18.4128 15.7173V13.9701C18.4128 13.5669 18.7488 13.2309 19.152 13.2309H25.1552C25.5584 13.2309 25.8944 13.5669 25.8944 13.9701V15.7173H25.9168Z"
          fill="currentColor"
        />
      </svg>
    </IconBase>
  )
}
