import { ExpandOneLayer } from "../object"

export interface Field<TSpec, TSubval, V> {
  _field_type: string
  spec: TSpec
  validate: (conc: TSubval) => V
  /** self-defined errorKey (only applicable when nested in a Group) */
  errorKey?: string
}

export function fieldOfType<T extends string, X>(type: T, val: X) {
  return { ...val, _field_type: type }
}

export interface CellField<T, V> extends Field<T, T, V> {
  _field_type: "cell"
  initValue: T
}

type CellProps<T, V> = {
  initValue: T
  validate: (val: T) => V
  errorKey?: string
  init?: (value: T) => T
}

export function Cell<T, V>(props: CellProps<T, V>): CellField<T, V> {
  return fieldOfType("cell", {
    initValue: props.initValue,
    init: props.init ?? ((x: T) => x),
    spec: props.initValue,
    validate: props.validate,
    errorKey: props.errorKey,
  })
}

export type FieldGroup = Record<string, Field<any, any, any>>
export type GroupInits<T extends FieldGroup> = {
  [K in keyof T]: T[K] extends Field<infer S, any, any> ? S : never
}
export type GroupSubvals<T extends FieldGroup> = ExpandOneLayer<{
  [K in keyof T]: T[K] extends Field<any, any, infer S> ? S : never
}>
export interface BaseGroupField<T extends FieldGroup, V> extends Field<T, GroupSubvals<T>, V> {
  initValue: Partial<GroupInits<T>>
  _field_type: "group"
}

type GroupProps<T extends FieldGroup, V> = {
  initValue?: Partial<GroupInits<T>>
  spec: T
  validate: (subvals: GroupSubvals<T> | null) => V
  errorKey?: string
}

export function BaseGroup<T extends FieldGroup, V>(props: GroupProps<T, V>): BaseGroupField<T, V> {
  return fieldOfType("group", {
    spec: props.spec,
    initValue: props.initValue ?? {},
    validate: props.validate,
    errorKey: props.errorKey,
  })
}

export interface BaseListField<T extends Field<any, any, any>, V>
  extends Field<T, ReturnType<T["validate"]>[], V> {
  initValue: T extends { initValue: infer S } ? S[] : never
  _field_type: "list"
}

export type Recursive<X extends BaseGroupField<any, any>> = X extends BaseGroupField<
  infer T,
  infer V
>
  ? BaseGroupField<
      T & { children: BaseListField<Recursive<X>, V[]> },
      V & { children: (V & { children: V[] })[] }
    >
  : never

type ListProps<T extends Field<any, any, any>, V> = {
  spec: T
  initValue: T extends { initValue: infer S } ? S[] : never
  validate: (subvals: ReturnType<T["validate"]>[]) => V
  errorKey?: string
}

export function BaseList<T extends Field<any, any, any>, V>(
  props: ListProps<T, V>,
): BaseListField<T, V> {
  return fieldOfType("list", {
    spec: props.spec,
    initValue: props.initValue,
    validate: props.validate,
    errorKey: props.errorKey,
  })
}
