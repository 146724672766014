import { performQualify } from "./internal-utils"
import { Config } from "./types"

// eslint-disable-next-line mosaic-js/no-unnamed-boolean-arg
export function hidden(a: Config, cond: boolean) {
  return { ...a, qualify: () => (cond ? null : a.qualify()) }
}

export function visibleCount(actions: Config[] | undefined) {
  return (actions ?? []).filter((x) => performQualify(x.qualify).type !== "hidden").length
}
