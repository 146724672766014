/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type BillPatchApprovalState =
  (typeof BillPatchApprovalState)[keyof typeof BillPatchApprovalState]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BillPatchApprovalState = {
  pending: "pending",
  rejected: "rejected",
  approved: "approved",
  void: "void",
} as const
