import { Fragment } from "react"
import { cn } from "msutils/classnames"
import { unreachable } from "msutils/misc"
import Typography from "compass/data/Typography"
import { CompassTypes } from "compass-local"
import LinkDONOTUSE from "compass-local/Link2"

export type BreadcrumbSegment =
  | {
      type: "link"
      name: string
      path: CompassTypes["href"]
      truncate?: boolean
    }
  | {
      type: "label"
      name: string
      truncate?: boolean
    }

type Props = {
  segments: BreadcrumbSegment[]
}

export default function Breadcrumb({ segments }: Props) {
  return (
    <div className="flex gap-1 items-center">
      {segments.map((s, i) => (
        <Fragment key={i}>
          {s.type === "link" ? (
            <LinkDONOTUSE href={s.path}>
              <Typography className="text-th-brown-2 hover:opacity-90">{s.name}</Typography>
            </LinkDONOTUSE>
          ) : s.type === "label" ? (
            <Typography
              className={cn("text-th-text-secondary cursor-default", s.truncate && "truncate")}
            >
              {s.name}
            </Typography>
          ) : (
            unreachable(s)
          )}
          {/* eslint-disable-next-line mosaic-js/no-raw-text-jsx */}
          {s.type === "link" && <Typography className="text-th-brown-2">/</Typography>}
        </Fragment>
      ))}
    </div>
  )
}
