/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type ListEstimateUploadedCoverPagesOrdering =
  (typeof ListEstimateUploadedCoverPagesOrdering)[keyof typeof ListEstimateUploadedCoverPagesOrdering]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ListEstimateUploadedCoverPagesOrdering = {
  original_file_name: "original_file_name",
  "-original_file_name": "-original_file_name",
  file_type: "file_type",
  "-file_type": "-file_type",
  created_at: "created_at",
  "-created_at": "-created_at",
  updated_at: "updated_at",
  "-updated_at": "-updated_at",
} as const
