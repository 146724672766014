import { Cell, CellField } from "./base"
import { FieldError } from "./utils"

type Toggle<V> = CellField<boolean, V>

type ToggleProps<TReq extends boolean> = {
  initValue?: boolean
  required?: TReq
}

export function Toggle<TReq extends boolean = false>(
  props?: ToggleProps<TReq>,
): Toggle<TReq extends true ? true : boolean> {
  const validate: any = (x: boolean) => {
    if (props?.required && !x) {
      throw new FieldError("Required")
    } else {
      return x
    }
  }
  return Cell({
    initValue: props?.initValue ?? false,
    validate,
  })
}
