import IconBase from "compass-local/legacy/IconBase"
import { IconProps } from "../types"

export default function Shield({
  width,
  height = 8,
  onClick,
  href,
  tabIndex,
  className,
}: IconProps) {
  return (
    <IconBase href={href} className={className} onClick={onClick} tabIndex={tabIndex}>
      <svg
        width={width?.toFixed(1)}
        height={height.toFixed(1)}
        viewBox="0 0 9 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.50293 1.998C0.578167 4.67063 2.19552 6.95742 4.49672 8.00012H4.50366C6.80421 6.95772 8.4213 4.67192 8.49738 2.00027C6.9102 1.87101 5.49776 1.12404 4.50085 0.000118256H4.496C3.49953 1.12248 2.08847 1.86847 0.50293 1.998Z"
          fill="currentColor"
        />
      </svg>
    </IconBase>
  )
}
