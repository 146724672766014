import IconBase from "compass-local/legacy/IconBase"
import { IconProps } from "../types"

export default function Briefcase({
  width,
  height = 26,
  onClick,
  href,
  tabIndex,
  className,
}: IconProps) {
  return (
    <IconBase href={href} className={className} onClick={onClick} tabIndex={tabIndex}>
      <svg
        width={width?.toFixed(1)}
        height={height.toFixed(1)}
        viewBox="0 0 29 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.42 15.8631C2.27574 15.8631 2.13573 15.8504 2 15.825V24.3268C2 25.2219 2.72968 25.9474 3.62483 25.9474H25.5709C26.4661 25.9474 27.1957 25.2219 27.1957 24.3268V15.8292C27.06 15.8504 26.92 15.8631 26.78 15.8631H24.7224V16.4189C24.7224 18.3747 23.1273 19.9656 21.1715 19.9656C19.2157 19.9656 17.6248 18.3747 17.6248 16.4189V15.8631H11.5751V16.4189C11.5751 18.3747 9.98422 19.9656 8.02847 19.9656C6.07272 19.9656 4.48181 18.3747 4.48181 16.4189V15.8631H2.42Z"
          fill="currentColor"
        />
        <path
          d="M26.7799 4.25573H19.5315V2.41544C19.5315 1.61647 18.8814 0.966431 18.0825 0.966431H10.8525C10.2002 0.966431 9.66949 1.49715 9.66949 2.14946V4.25573H2.41987C1.41864 4.25573 0.599854 5.07452 0.599854 6.07572V6.83936V7.2636V13.1945C0.599854 14.1957 1.41864 15.0145 2.41987 15.0145H5.33017V16.4188C5.33017 17.9036 6.53925 19.1169 8.02834 19.1169C9.51744 19.1169 10.7265 17.9036 10.7265 16.4188V15.0145H18.4732V16.4188C18.4732 17.9036 19.6865 19.1169 21.1714 19.1169C22.6605 19.1169 23.8738 17.9036 23.8738 16.4188V15.0145H26.7799C27.7853 15.0145 28.5999 14.1957 28.5999 13.1945V7.2636V6.83936V6.07572C28.5999 5.07452 27.7853 4.25573 26.7799 4.25573ZM8.02834 17.0509C7.5447 17.0509 7.15016 16.6606 7.15016 16.1769C7.15016 15.6933 7.5447 15.2988 8.02834 15.2988C8.50774 15.2988 8.90228 15.6933 8.90228 16.1769C8.90228 16.6606 8.50774 17.0509 8.02834 17.0509ZM21.1714 17.1824C20.6962 17.1824 20.3059 16.7963 20.3059 16.3169C20.3059 15.8375 20.6962 15.4473 21.1714 15.4473C21.6508 15.4473 22.0411 15.8375 22.0411 16.3169C22.0411 16.7963 21.6508 17.1824 21.1714 17.1824ZM10.518 2.14946C10.518 1.96489 10.6679 1.81492 10.8525 1.81492H18.0825C18.4137 1.81492 18.683 2.08421 18.683 2.41544V4.25573H10.518V2.14946Z"
          fill="currentColor"
        />
      </svg>
    </IconBase>
  )
}
