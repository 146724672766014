/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type ComplianceDocumentTypeCoiCoiDetailsCoveragesItemType =
  (typeof ComplianceDocumentTypeCoiCoiDetailsCoveragesItemType)[keyof typeof ComplianceDocumentTypeCoiCoiDetailsCoveragesItemType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ComplianceDocumentTypeCoiCoiDetailsCoveragesItemType = {
  general: "general",
  automotive: "automotive",
  umbrella: "umbrella",
  excess: "excess",
  workers_comp: "workers_comp",
} as const
