/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type TaskType = (typeof TaskType)[keyof typeof TaskType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TaskType = {
  approve_invoices: "approve_invoices",
  upload_coi_document: "upload_coi_document",
  admin_approve_invoice: "admin_approve_invoice",
  nonadmin_resolve_invoice: "nonadmin_resolve_invoice",
  approve_pending_estimate: "approve_pending_estimate",
  convert_approved_estimate_as_payee: "convert_approved_estimate_as_payee",
  convert_approved_estimate_as_payer: "convert_approved_estimate_as_payer",
  sign_conditional_lien_waiver: "sign_conditional_lien_waiver",
  sign_unconditional_lien_waiver: "sign_unconditional_lien_waiver",
  schedule_bill_payment: "schedule_bill_payment",
} as const
