import { ReactNode } from "react"
import Typography from "compass/data/Typography"
import Spinner from "compass-local/Spinner"
import Breadcrumb, { BreadcrumbSegment } from "compass-local/Breadcrumb"
import useScreenSize from "compass/theme/useScreenSize"
import SectionBoundary from "components/boundaries/SectionBoundary"
import WidgetBoundary from "components/boundaries/WidgetBoundary"
import { Action } from "utils/actions"
import { MSArray } from "msutils"
import { cn } from "msutils/classnames"

type Props = {
  title: ReactNode
  banner?: ReactNode
  isLoading?: boolean
  badge?: ReactNode
  breadcrumbs?: BreadcrumbSegment[]
  actions?: Action.Config[]
  footer?: ReactNode
  mainContent: ReactNode
  leftContent1?: ReactNode
  leftContent2?: ReactNode
  forms?: ReactNode
  allowHorizontalScroll?: boolean
}

export function GuestModuleLayout({
  banner,
  title,
  isLoading,
  badge,
  breadcrumbs,
  actions,
  footer,
  mainContent,
  leftContent1,
  leftContent2,
  forms,
}: Props) {
  const sz = useScreenSize()

  return (
    <div className="vflex gap-5 h-full w-full">
      {banner}
      {forms}
      {sz === "sm" ? (
        <div className="vflex gap-5 px-5 isolate">
          {breadcrumbs && <Breadcrumb segments={breadcrumbs} />}
          <div className="flex gap-3 items-center">
            <div className="vflex gap-2">
              <Typography variant="header">{title}</Typography>
              {badge}
            </div>
            {isLoading && <Spinner w="w-5" h="h-5" color="text-th-coolgrey-1" />}
          </div>
        </div>
      ) : (
        <div className="flex gap-5 justify-between isolate items-start">
          <div className="vflex gap-3 px-5">
            {breadcrumbs && <Breadcrumb segments={breadcrumbs} />}
            <div className="flex gap-3 items-center">
              {sz === "md" ? (
                <div className="vflex gap-2">
                  <Typography variant="header">{title}</Typography>
                  {badge}
                </div>
              ) : (
                <>
                  <Typography variant="header">{title}</Typography>
                  {badge}
                </>
              )}
              {isLoading && <Spinner w="w-5" h="h-5" color="text-th-coolgrey-1" />}
            </div>
          </div>
        </div>
      )}
      <div className={sz === "sm" || sz === "md" ? "vflex gap-5 w-full" : "flex gap-5"}>
        {!!(leftContent1 || leftContent2) && (
          <div className="vflex gap-5 lg:w-fit lg:max-w-[420px] lg:min-w-[420px]">
            <SectionBoundary>{leftContent1}</SectionBoundary>
            {sz !== "sm" && (
              <>
                {footer}
                {actions?.map((x, i) => <Action.Mount {...x} key={`${x.name}-${i}`} />)}
              </>
            )}
            {sz !== "sm" && sz !== "md" && <WidgetBoundary>{leftContent2}</WidgetBoundary>}
          </div>
        )}
        <div className="w-full">
          <SectionBoundary>{mainContent}</SectionBoundary>
        </div>
      </div>
      {(sz === "sm" || sz === "md") && <WidgetBoundary>{leftContent2}</WidgetBoundary>}
      {sz === "sm" && (MSArray.isNonEmpty(actions ?? []) || footer) && (
        <>
          <div className="grow" />
          <div className="sticky-actions shadow-reverse">
            <div className="flex flex-col-reverse gap-4">
              {footer}
              {actions
                ?.slice()
                .reverse()
                .map((x, i) => <Action.Mount {...x} key={`${x.name}-${i}`} />)}
            </div>
          </div>
        </>
      )}
    </div>
  )
}

type GuestListModuleProps = {
  title: ReactNode
  breadcrumbs?: BreadcrumbSegment[]
  mainContent: ReactNode
}

export function GuestListModule({ title, breadcrumbs, mainContent }: GuestListModuleProps) {
  const sz = useScreenSize()
  return (
    <GuestModuleLayout
      title={title}
      breadcrumbs={breadcrumbs}
      mainContent={<div className={cn("bg-white", sz === "sm" && "px-5")}>{mainContent}</div>}
    />
  )
}
