import IconBase from "compass-local/legacy/IconBase"
import { StrokedIconProps } from "../types"

export default function Chat({
  width,
  height = 20,
  onClick,
  href,
  tabIndex,
  className,
  thickness = 2,
}: StrokedIconProps) {
  return (
    <IconBase href={href} className={className} onClick={onClick} tabIndex={tabIndex}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="1 1 20 18"
        width={width?.toFixed(1)}
        height={height.toFixed(1)}
      >
        <path
          d="M11 18C15.97 18 20 14.418 20 10C20 5.582 15.97 2 11 2C6.03 2 2 5.582 2 10C2 11.574 2.512 13.042 3.395 14.28L2 18L6.745 17.051C8.07479 17.6808 9.52864 18.005 11 18Z"
          stroke="currentColor"
          strokeWidth={thickness.toFixed(1)}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10 6.42105L10.5843 6.32879C12.4384 6.03603 13.3547 8.50113 11.7595 9.49047V9.49047C11.2493 9.80689 10.9409 10.3664 10.9458 10.9667L10.9474 11.1579M10.9474 14H10.9379"
          stroke="currentColor"
          strokeWidth={thickness.toFixed(1)}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </IconBase>
  )
}
