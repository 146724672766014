/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type PayerContactContactType =
  (typeof PayerContactContactType)[keyof typeof PayerContactContactType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PayerContactContactType = {
  homeowner: "homeowner",
  contractor: "contractor",
  developer: "developer",
} as const
