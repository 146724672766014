/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type BeamBalanceTransactionTypeExternalState =
  (typeof BeamBalanceTransactionTypeExternalState)[keyof typeof BeamBalanceTransactionTypeExternalState]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BeamBalanceTransactionTypeExternalState = {
  new: "new",
  pending: "pending",
  complete: "complete",
  failed: "failed",
} as const
