/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type TransactionAccountTypeCardType =
  (typeof TransactionAccountTypeCardType)[keyof typeof TransactionAccountTypeCardType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TransactionAccountTypeCardType = {
  card: "card",
} as const
