/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type ListStripeConnectedAccountLinksOrdering =
  (typeof ListStripeConnectedAccountLinksOrdering)[keyof typeof ListStripeConnectedAccountLinksOrdering]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ListStripeConnectedAccountLinksOrdering = {
  created_at: "created_at",
  "-created_at": "-created_at",
  updated_at: "updated_at",
  "-updated_at": "-updated_at",
  details_submitted: "details_submitted",
  "-details_submitted": "-details_submitted",
  treasury_capability_status: "treasury_capability_status",
  "-treasury_capability_status": "-treasury_capability_status",
  transfers_capability_status: "transfers_capability_status",
  "-transfers_capability_status": "-transfers_capability_status",
  card_payments_capability_status: "card_payments_capability_status",
  "-card_payments_capability_status": "-card_payments_capability_status",
  us_bank_account_ach_payments_capability_status: "us_bank_account_ach_payments_capability_status",
  "-us_bank_account_ach_payments_capability_status":
    "-us_bank_account_ach_payments_capability_status",
  payouts_enabled: "payouts_enabled",
  "-payouts_enabled": "-payouts_enabled",
  has_requirements_pending_verification: "has_requirements_pending_verification",
  "-has_requirements_pending_verification": "-has_requirements_pending_verification",
  has_requirements_currently_due: "has_requirements_currently_due",
  "-has_requirements_currently_due": "-has_requirements_currently_due",
  has_requirements_eventually_due: "has_requirements_eventually_due",
  "-has_requirements_eventually_due": "-has_requirements_eventually_due",
  has_requirements_past_due: "has_requirements_past_due",
  "-has_requirements_past_due": "-has_requirements_past_due",
  business_state: "business_state",
  "-business_state": "-business_state",
  stripe_id: "stripe_id",
  "-stripe_id": "-stripe_id",
} as const
