import { useMemo, useState } from "react"
import { unreachable } from "../misc"
import { useStabilized } from "./hooks"

type StorageManager<T> = {
  get: () => T | null
  set: (newValue: T) => void
  remove: () => void
}

export function getStorageManager<T>(
  key: string,
  strategy: "local" | "session",
  props: {
    /** Transform from storage system into app-world */
    transformIn: (val: string) => T
    /** Transform out of app-world into storage system */
    transformOut: (val: T) => string
  },
): StorageManager<T> {
  const getDb = () => (strategy === "local" ? window.localStorage : window.sessionStorage)
  return {
    get: () => {
      const value = getDb().getItem(key)
      if (value) {
        return props.transformIn(value)
      } else {
        return null
      }
    },
    set: (newValue) => getDb().setItem(key, props.transformOut(newValue)),
    remove: () => getDb().removeItem(key),
  }
}

export function useStorageState<T>(
  key: string,
  strategy: "local" | "session",
  props: {
    /** Transform from storage system into app-world */
    transformIn: (val: string) => T
    /** Transform out of app-world into storage system */
    transformOut: (val: T) => string
  },
): [T | null, (newValue: T | null) => void] {
  const stableTransformOut = useStabilized(props.transformOut)

  const db = useMemo(
    () =>
      strategy === "local" || !strategy
        ? window.localStorage
        : strategy === "session"
        ? window.sessionStorage
        : unreachable(strategy),
    [strategy],
  )

  const [value, setValue] = useState(() => {
    const str = db.getItem(key)
    if (str) {
      try {
        return props.transformIn(str)
      } catch (e: any) {
        return null
      }
    } else {
      return null
    }
  })

  return useMemo(
    () => [
      value,
      (newValue) => {
        setValue(newValue)
        if (newValue) {
          db.setItem(key, stableTransformOut.current(newValue))
        } else {
          db.removeItem(key)
        }
      },
    ],
    [key, value, db, stableTransformOut],
  )
}
