/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type SetupIntentCreateVerificationMethod =
  (typeof SetupIntentCreateVerificationMethod)[keyof typeof SetupIntentCreateVerificationMethod]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SetupIntentCreateVerificationMethod = {
  automatic: "automatic",
  skip: "skip",
  instant: "instant",
  null: null,
} as const
