/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type BillCreateInvoiceType =
  (typeof BillCreateInvoiceType)[keyof typeof BillCreateInvoiceType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BillCreateInvoiceType = {
  progress: "progress",
  free_form: "free_form",
  milestone: "milestone",
} as const
