/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type AccountPatchTokenStatus =
  (typeof AccountPatchTokenStatus)[keyof typeof AccountPatchTokenStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AccountPatchTokenStatus = {
  disabled: "disabled",
  unconfirmed: "unconfirmed",
  active: "active",
} as const
