/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type ListLedgerAccountsOrdering =
  (typeof ListLedgerAccountsOrdering)[keyof typeof ListLedgerAccountsOrdering]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ListLedgerAccountsOrdering = {
  name: "name",
  "-name": "-name",
  description: "description",
  "-description": "-description",
  classification: "classification",
  "-classification": "-classification",
  type: "type",
  "-type": "-type",
  sub_type: "sub_type",
  "-sub_type": "-sub_type",
  created_at: "created_at",
  "-created_at": "-created_at",
  updated_at: "updated_at",
  "-updated_at": "-updated_at",
} as const
