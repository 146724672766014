// Note: had to change status === 'pending'

/**
@returns {any}
*/
export function injector(qs) {
  const safeResults = {}
  Object.entries(qs).forEach(([k, v]) => {
    if (v.status === "loading") {
      safeResults[k] = { status: "loading" }
    } else if (v.status === "error") {
      safeResults[k] = { status: "error", error: v.error }
    } else if (v.status === "success") {
      safeResults[k] = { status: "success", data: v.data }
    }
  })

  if (Object.values(safeResults).some((v) => v.status === "error")) {
    return { status: "error", qs }
  } else if (Object.values(safeResults).some((v) => v.status === "loading")) {
    return { status: "loading", qs }
  } else {
    const data = {}
    Object.entries(safeResults).forEach(([k, v]) => {
      data[k] = v.data
    })
    return { status: "success", data }
  }
}
