/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type InvoiceListRowLienWaiverStatus =
  (typeof InvoiceListRowLienWaiverStatus)[keyof typeof InvoiceListRowLienWaiverStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InvoiceListRowLienWaiverStatus = {
  not_required: "not_required",
  required: "required",
  collected: "collected",
} as const
