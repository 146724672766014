import IconBase from "compass-local/legacy/IconBase"
import { StrokedIconProps } from "../types"

// TODO: secondary color
export default function DocumentWithCheckmark({
  width,
  height = 20,
  onClick,
  href,
  tabIndex,
  className,
  thickness = 2,
}: StrokedIconProps) {
  return (
    <IconBase href={href} className={className} onClick={onClick} tabIndex={tabIndex}>
      <svg
        width={width?.toFixed(1)}
        height={height.toFixed(1)}
        viewBox="0 0 15 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.6667 17H2.77778C2.30628 17 1.8541 16.8127 1.5207 16.4793C1.1873 16.1459 1 15.6937 1 15.2222V2.77778C1 2.30628 1.1873 1.8541 1.5207 1.5207C1.8541 1.1873 2.30628 1 2.77778 1H7.74311C7.97884 1.00005 8.2049 1.09373 8.37156 1.26044L13.184 6.07289C13.3507 6.23955 13.4444 6.4656 13.4444 6.70133V15.2222C13.4444 15.6937 13.2571 16.1459 12.9237 16.4793C12.5903 16.8127 12.1382 17 11.6667 17Z"
          stroke="currentColor"
          strokeWidth={thickness.toFixed(1)}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5 9.48L6.48 10.96L9.44 8"
          stroke="#008932"
          strokeWidth={thickness.toFixed(1)}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </IconBase>
  )
}
