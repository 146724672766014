/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type EstimateAsPayeeListRowStatus =
  (typeof EstimateAsPayeeListRowStatus)[keyof typeof EstimateAsPayeeListRowStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EstimateAsPayeeListRowStatus = {
  draft: "draft",
  sent: "sent",
  approved: "approved",
  rejected: "rejected",
  void: "void",
} as const
