import IconBase from "compass-local/legacy/IconBase"
import { StrokedIconProps } from "../types"

export default function MagnifyingGlass({
  width,
  height = 14,
  onClick,
  href,
  tabIndex,
  className,
  thickness = 2,
}: StrokedIconProps) {
  return (
    <IconBase href={href} className={className} onClick={onClick} tabIndex={tabIndex}>
      <svg
        width={width?.toFixed(1)}
        height={height.toFixed(1)}
        viewBox="0 0 18 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17 17.5L11.6667 12.1667M13.4444 7.72222C13.4444 8.53934 13.2835 9.34845 12.9708 10.1034C12.6581 10.8583 12.1998 11.5442 11.622 12.122C11.0442 12.6998 10.3583 13.1581 9.60336 13.4708C8.84845 13.7835 8.03934 13.9444 7.22222 13.9444C6.40511 13.9444 5.596 13.7835 4.84108 13.4708C4.08617 13.1581 3.40023 12.6998 2.82245 12.122C2.24466 11.5442 1.78633 10.8583 1.47364 10.1034C1.16094 9.34845 1 8.53934 1 7.72222C1 6.07199 1.65555 4.48934 2.82245 3.32245C3.98934 2.15555 5.57199 1.5 7.22222 1.5C8.87246 1.5 10.4551 2.15555 11.622 3.32245C12.7889 4.48934 13.4444 6.07199 13.4444 7.72222Z"
          stroke="currentColor"
          strokeWidth={thickness.toFixed(1)}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </IconBase>
  )
}
