/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type BillInvoiceType = (typeof BillInvoiceType)[keyof typeof BillInvoiceType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BillInvoiceType = {
  progress: "progress",
  free_form: "free_form",
  milestone: "milestone",
} as const
