/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type MicrodepositRequirementType =
  (typeof MicrodepositRequirementType)[keyof typeof MicrodepositRequirementType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MicrodepositRequirementType = {
  code: "code",
  amounts: "amounts",
} as const
