/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type ComplianceDocumentCreateCoiDetailsCoveragesItemType =
  (typeof ComplianceDocumentCreateCoiDetailsCoveragesItemType)[keyof typeof ComplianceDocumentCreateCoiDetailsCoveragesItemType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ComplianceDocumentCreateCoiDetailsCoveragesItemType = {
  general: "general",
  automotive: "automotive",
  umbrella: "umbrella",
  excess: "excess",
  workers_comp: "workers_comp",
} as const
