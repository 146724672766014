/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type LedgerAccountClassification =
  (typeof LedgerAccountClassification)[keyof typeof LedgerAccountClassification]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const LedgerAccountClassification = {
  asset: "asset",
  equity: "equity",
  expense: "expense",
  liability: "liability",
  revenue: "revenue",
} as const
