import { unreachable } from "msutils/misc"
import { theme2 } from "theme2"
import { ThemeName } from "./types"

export function getButtonThemeFromThemeName(themeName: ThemeName | null) {
  switch (themeName) {
    case null:
    case "primary":
      return theme2.ButtonThemeDefault
    case "secondary":
      return theme2.ButtonThemeLight
    case "text":
      return theme2.ButtonThemeTextDark
    case "text-small":
      return theme2.ButtonThemeTextDarkSmall
    case "text-bordered":
      return theme2.ButtonThemeTextDarkBordered
    case "orange-text":
      return theme2.ButtonThemeTextOrange
    case "danger":
      return theme2.ButtonThemeTextRed
    case "gold":
      return theme2.ButtonThemeGold
    default:
      return unreachable(themeName)
  }
}
