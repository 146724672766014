import { cn } from "msutils/classnames"
import { BaseLayout } from "../../baseLayout"

export default function Divider({
  layout = "h",
  padded,
}: {
  layout?: "h" | "v"
  padded?: boolean
}) {
  return (
    <BaseLayout.NativeDiv
      className={cn(
        "shrink-0 bg-th-warmgrey-1",
        padded && "mx-5",
        layout === "h" && "h-[1px]",
        layout === "v" && "w-[1px] h-full",
      )}
    />
  )
}
