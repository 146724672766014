import { ReactNode } from "react"
import { X } from "compass-local/legacy/icons"
import Typography from "compass/data/Typography"
import { Action } from "utils/actions"
import { MSArray } from "msutils"

type BodyProps = {
  title: ReactNode
  setInactive: () => void
  subtitle?: ReactNode
  footer?: ReactNode
  actions?: Action.Config[]
  children?: ReactNode
  noHeader?: boolean
}
export default function Body({
  setInactive,
  title,
  subtitle,
  footer,
  actions,
  noHeader,
  children,
}: BodyProps) {
  return (
    <div className="vflex p-2 gap-5 h-full grow">
      {!noHeader && (
        <div className="sticky bg-th-bg-white w-full z-[2] flex justify-between items-center gap-4">
          <Typography variant="drawerheader" className="overflow-hidden text-ellipsis">
            {title}
          </Typography>
          <X height={16} className="cursor-pointer text-th-brown-2" onClick={setInactive} />
        </div>
      )}
      {subtitle && <Typography className="text-th-text-secondary">{subtitle}</Typography>}
      <div className="vflex gap-5 grow">{children}</div>
      {(footer || MSArray.isNonEmpty(actions ?? [])) && (
        <div className="sticky bg-th-bg-white w-full">
          <div className="border-t border-t-th-warmgrey-1 vflex pt-4 gap-4">
            {footer}
            <div className="flex gap-2 justify-end">
              <Action.UIConfigProvider value={{ theme: "primary" }}>
                <div className="w-min flex gap-4 items-center">
                  {actions?.map((x, i) => <Action.Mount key={`${x.name}-${i}`} {...x} />)}
                </div>
              </Action.UIConfigProvider>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
