/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type ListVendorBillingConfigsOrdering =
  (typeof ListVendorBillingConfigsOrdering)[keyof typeof ListVendorBillingConfigsOrdering]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ListVendorBillingConfigsOrdering = {
  is_exempt_from_lien_waiver_requirement: "is_exempt_from_lien_waiver_requirement",
  "-is_exempt_from_lien_waiver_requirement": "-is_exempt_from_lien_waiver_requirement",
  created_at: "created_at",
  "-created_at": "-created_at",
  updated_at: "updated_at",
  "-updated_at": "-updated_at",
} as const
