/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type DealStage = (typeof DealStage)[keyof typeof DealStage]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DealStage = {
  new: "new",
  estimate_drafted: "estimate_drafted",
  estimate_sent: "estimate_sent",
  approved: "approved",
  project: "project",
  lost: "lost",
  archived: "archived",
} as const
