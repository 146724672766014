import { createContext, ReactNode, useContext, useMemo } from "react"
import { Cb, Q } from "cb"
import { MSArray, MSError2 } from "msutils"
import withInjectedQueriesDONOTUSE, { AwaitedQueryInjectedProps } from "utils/withInjectedQueries"
import { useSmartEmailVerificationQuery } from "root/utils"
import { auth } from "root/auth"
import Typography from "compass/data/Typography"

type AppContext = (
  | {
      type: "business"
      employee: Cb.Employee
      payeeEnabled: true
      payerEnabled: boolean
      emailVerifications: Cb.EmailVerification[]
      business: Cb.Business
      internalAccount: Cb.TransactionAccountTypeInternalBankAccount | null
      cardProgram: Cb.CardProgram | null
      saasTier: Cb.SaasTierTier
    }
  | {
      type: "client"
      payeeEnabled: boolean
      payerEnabled: true
      client: Cb.Client
    }
) & {
  user: Cb.User
  qboEnabled: boolean
  hasAccountingCodes: boolean
  hasCostTypes: boolean
  qboPushEnabled: boolean
}

const Context = createContext<AppContext | undefined>(undefined)

export function useAppContext() {
  const context = useContext(Context)
  if (context === undefined) throw new Error("AppContext must be used within an AppProvider")
  return context
}

export function useBusinessContext() {
  const context = useAppContext()
  if (context.type !== "business") throw new Error("Expected business context")
  return context
}

export function useClientContext() {
  const context = useAppContext()
  if (context.type !== "client") throw new Error("Expected client context")
  return context
}

function useQueries() {
  return Q.group({
    user: Cb.useListUsers({ select: Q.first }),
    employees: Cb.useListEmployees(),
    emailVerifications: useSmartEmailVerificationQuery(),
    // Minimally disable the app for users that are archived
    business: Cb.useListBusiness({ params: { archived: false }, select: Q.opt }),
    client: Cb.useListClients({ select: Q.opt }),
    payerEnabled: Cb.useWhoAmI({ select: (data) => data.payer_enabled }),
    payeeEnabled: Cb.useWhoAmI({ select: (data) => data.payee_enabled }),
    qboEnabled: Cb.useListAccounts({
      select: (data) => data.results.some((x) => x.enabled && x.token_status === "active"),
    }),
    hasAccountingCodes: Cb.useListCostCodes({
      select: (data) => MSArray.isNonEmpty(data.results),
    }),
    hasCostTypes: Cb.useListCostTypes({
      params: { archived: false },
      select: (data) => MSArray.isNonEmpty(data.results),
    }),
    qboPushEnabled: Cb.useListAccounts({
      select: (data) =>
        data.results.some((x) => x.outbound_sync_enabled && x.token_status === "active"),
    }),
    internalAccount: Cb.useListTransactionAccounts({
      select: ({ results }) =>
        results.flatMap((x) => (x.type === "internal_bank_account" ? [x] : [])).at(0) ?? null,
    }),
    cardProgram: Cb.useListCardPrograms({ select: Q.opt }),
    saasTier: Cb.useListSaasTiers({ select: Q.opt }),
  })
}

type Props = {
  children: ReactNode
  _queryset: AwaitedQueryInjectedProps<typeof useQueries>
}

export default withInjectedQueriesDONOTUSE(useQueries, ({ children, _queryset }: Props) => {
  const {
    qboEnabled,
    hasAccountingCodes,
    hasCostTypes,
    qboPushEnabled,
    payeeEnabled,
    payerEnabled,
    emailVerifications,
    internalAccount,
    cardProgram,
    client,
    business,
    user,
    employees,
    saasTier,
  } = _queryset

  const contextValue = useMemo((): AppContext => {
    const shared = {
      qboEnabled,
      hasAccountingCodes,
      hasCostTypes,
      qboPushEnabled,
      user,
      cardProgram,
    }
    if (payeeEnabled && business) {
      return {
        ...shared,
        payeeEnabled,
        emailVerifications,
        employee: MSArray.assertFind(employees, (x) => x.user_id === user.id),
        business,
        payerEnabled,
        internalAccount,
        type: "business",
        saasTier: saasTier?.tier ?? "core",
      }
    } else if (payerEnabled && client) {
      return {
        ...shared,
        payeeEnabled,
        client,
        payerEnabled,
        type: "client",
      }
    } else {
      throw new MSError2("Unexpected case in user type initialization")
    }
  }, [
    emailVerifications,
    qboEnabled,
    hasAccountingCodes,
    hasCostTypes,
    qboPushEnabled,
    payeeEnabled,
    payerEnabled,
    client,
    business,
    user,
    internalAccount,
    cardProgram,
    employees,
    saasTier,
  ])
  const { settings } = auth.usePostAuthContext()

  return (
    <Context.Provider value={contextValue}>
      {settings.impersonator && (
        <div className="bg-red-200 p-4">
          {/* eslint-disable-next-line */}
          <Typography variant="bodybold">internal user</Typography>
        </div>
      )}
      {children}
    </Context.Provider>
  )
})
