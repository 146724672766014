import BaseMetric from "compass-local/BaseMetric/Component"
import { MetricsUtils } from "compass-local/Metrics"

type Props = MetricsUtils.Spec & {
  k: string
  v: string
}

export default function Metric(props: Props) {
  return <BaseMetric {...props} />
}
