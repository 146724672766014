/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type ListAdminTransactionsOrdering =
  (typeof ListAdminTransactionsOrdering)[keyof typeof ListAdminTransactionsOrdering]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ListAdminTransactionsOrdering = {
  state: "state",
  "-state": "-state",
  initiated_date: "initiated_date",
  "-initiated_date": "-initiated_date",
  completed_date: "completed_date",
  "-completed_date": "-completed_date",
  amount: "amount",
  "-amount": "-amount",
  created_at: "created_at",
  "-created_at": "-created_at",
  updated_at: "updated_at",
  "-updated_at": "-updated_at",
} as const
