/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type InternalFileUploadPatchType =
  (typeof InternalFileUploadPatchType)[keyof typeof InternalFileUploadPatchType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InternalFileUploadPatchType = {
  generic: "generic",
  cost_code: "cost_code",
  cost_type: "cost_type",
  cost_library: "cost_library",
  estimate: "estimate",
} as const
