/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type BillListRowApprovalState =
  (typeof BillListRowApprovalState)[keyof typeof BillListRowApprovalState]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BillListRowApprovalState = {
  pending: "pending",
  rejected: "rejected",
  approved: "approved",
  void: "void",
} as const
