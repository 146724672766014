import { Cell, CellField } from "./base"
import { Branded, EmptyString, FieldError } from "./utils"

type CurrencyString = Branded<string, "currency">
type NonzeroCurrencyString = Branded<string, "nonzero-currency">
type CurrencyField<V> = CellField<string, V>

type CurrencyProps<TReq, TNonzero> = {
  required?: TReq
  nonzero?: TNonzero
  initValue?: string
  treatEmptyAs?: string
  errorKey?: string
}

export function Currency<TReq extends boolean = false, TNonzero extends boolean = false>(
  props?: CurrencyProps<TReq, TNonzero>,
): CurrencyField<
  TReq extends true
    ? TNonzero extends true
      ? NonzeroCurrencyString
      : CurrencyString
    : TNonzero extends true
    ? NonzeroCurrencyString | EmptyString
    : CurrencyString | EmptyString
> {
  const validate: any = (x: string) => {
    if (props?.required) {
      if (!x || x === "-") {
        throw new FieldError("Required")
      } else if (Number.isNaN(x)) {
        throw new FieldError("Please enter a valid dollar amount")
      } else if (props.nonzero && Number(x) === 0) {
        throw new FieldError("Please enter a nonzero dollar amount")
      } else {
        return x
      }
    } else if (!x || x === "-") {
      return props?.treatEmptyAs ?? ""
    } else if (Number.isNaN(x)) {
      throw new FieldError("Please enter a valid dollar amount")
    } else if (props?.nonzero && Number(x) === 0) {
      throw new FieldError("Please enter a nonzero dollar amount")
    } else {
      return x
    }
  }
  return Cell({
    initValue: props?.initValue ?? "",
    validate,
    errorKey: props?.errorKey,
  })
}
