/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type ListTutorialsOrdering =
  (typeof ListTutorialsOrdering)[keyof typeof ListTutorialsOrdering]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ListTutorialsOrdering = {
  invoice_sent: "invoice_sent",
  "-invoice_sent": "-invoice_sent",
  status: "status",
  "-status": "-status",
  created_at: "created_at",
  "-created_at": "-created_at",
  updated_at: "updated_at",
  "-updated_at": "-updated_at",
} as const
