import { ReactNode, useState } from "react"
import Typography from "compass/data/Typography"
import Breadcrumb, { BreadcrumbSegment } from "compass-local/Breadcrumb"
import { Collapsable, MSArray } from "msutils/array"
import useScreenSize from "compass/theme/useScreenSize"
import SectionBoundary from "components/boundaries/SectionBoundary"
import { Action } from "utils/actions"

type Props = {
  title: string
  subtitle?: string
  badge?: ReactNode
  breadcrumbs?: Collapsable<BreadcrumbSegment[]>
  actions?: Action.Config[]
  footer?: ReactNode
  disablePadding?: boolean
  contentBanner?: ReactNode
  mainContent: ReactNode
  mount?: ReactNode
}

export function SettingsModule({
  title,
  subtitle,
  badge,
  breadcrumbs: breadcrumbsUncollapsed,
  actions,
  footer,
  contentBanner,
  mainContent,
  mount,
}: Props) {
  const sz = useScreenSize()
  const breadcrumbs = MSArray.collapse(breadcrumbsUncollapsed ?? [])

  const [actionsContainer, setActionsContainer] = useState<HTMLDivElement | null>(null)
  const actionsContainerHeight = actionsContainer?.clientHeight ?? 0

  return (
    <div className="vflex h-full w-full">
      <div className="vflex gap-5 h-full w-full" style={{ paddingBottom: actionsContainerHeight }}>
        {mount}
        {sz === "sm" ? (
          <div className="vflex gap-5 px-5 isolate">
            {MSArray.isNonEmpty(breadcrumbs) && <Breadcrumb segments={breadcrumbs} />}
            <div className="vflex gap-2">
              <Typography variant="drawerheader">{title}</Typography>
              <Typography className="text-th-text-secondary">{subtitle}</Typography>
              {badge}
            </div>
          </div>
        ) : (
          <div className="flex gap-5 justify-between isolate items-start">
            <div className="vflex gap-3 px-5">
              <div className="flex gap-3 items-center">
                {sz === "md" ? (
                  <div className="vflex gap-2">
                    <Typography variant="drawerheader">{title}</Typography>
                    <Typography className="text-th-text-secondary">{subtitle}</Typography>
                    {badge}
                  </div>
                ) : (
                  <div className="vflex gap-2">
                    <div className="flex gap-3 items-center">
                      <Typography variant="drawerheader">{title}</Typography>
                      {badge}
                    </div>
                    <Typography className="text-th-text-secondary">{subtitle}</Typography>
                  </div>
                )}
              </div>
            </div>
            <div className="flex gap-3 items-center">
              {actions?.map((x, i) => <Action.Mount key={`${x.name}-${i}`} {...x} />)}
            </div>
          </div>
        )}
        <div className={sz === "sm" || sz === "md" ? "vflex gap-5 w-full" : "flex gap-5"}>
          <div className="w-full vflex gap-3">
            {contentBanner}
            <SectionBoundary>
              <div className="vflex gap-5 bg-th-bg-white">{mainContent}</div>
            </SectionBoundary>
          </div>
        </div>
      </div>
      {sz === "sm" && (MSArray.isNonEmpty(actions ?? []) || footer) && (
        <>
          <div className="footer-actions shadow-reverse" ref={setActionsContainer}>
            {footer}
            {MSArray.isNonEmpty(actions ?? []) && (
              <div className="vflex gap-3">
                {actions
                  ?.slice()
                  .reverse()
                  .map((x, i) => <Action.Mount key={`${x.name}-${i}`} {...x} />)}
              </div>
            )}
          </div>
        </>
      )}
    </div>
  )
}
