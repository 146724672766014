/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type ComplianceDocumentCreateLicenseDetailsLicenseType =
  (typeof ComplianceDocumentCreateLicenseDetailsLicenseType)[keyof typeof ComplianceDocumentCreateLicenseDetailsLicenseType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ComplianceDocumentCreateLicenseDetailsLicenseType = {
  a: "a",
  b: "b",
  b_2: "b_2",
  c: "c",
  c_2: "c_2",
  c_4: "c_4",
  c_5: "c_5",
  c_6: "c_6",
  c_7: "c_7",
  c_8: "c_8",
  c_9: "c_9",
  c_10: "c_10",
  c_11: "c_11",
  c_12: "c_12",
  c_13: "c_13",
  c_15: "c_15",
  c_16: "c_16",
  c_17: "c_17",
  c_20: "c_20",
  c_21: "c_21",
  c_22: "c_22",
  c_23: "c_23",
  c_27: "c_27",
  c_28: "c_28",
  c_29: "c_29",
  c_31: "c_31",
  c_32: "c_32",
  c_33: "c_33",
  c_34: "c_34",
  c_35: "c_35",
  c_36: "c_36",
  c_38: "c_38",
  c_39: "c_39",
  c_42: "c_42",
  c_43: "c_43",
  c_45: "c_45",
  c_46: "c_46",
  c_47: "c_47",
  c_50: "c_50",
  c_51: "c_51",
  c_53: "c_53",
  c_54: "c_54",
  c_55: "c_55",
  c_57: "c_57",
  c_60: "c_60",
  c_61: "c_61",
  unknown: "unknown",
} as const
