export const Colors = {
  green: "text-th-green-success",
  red: "text-th-red-warning",
  gray: "text-th-text",
}

export const BorderColors = {
  green: "border-th-green-success",
  red: "border-th-red-warning",
  gray: "border-th-text",
}

export const BackgroundColors = {
  warm: "bg-th-warmgrey-1",
  black: "bg-black",
  gray: "bg-th-text",
}

export const TextColors = {
  normal: "text-th-text",
  disabledText: "text-th-text-disabled",
  warm: "text-th-warmgrey-1",
  cool: "text-th-coolgrey-1",
  green: "text-th-green-success",
}
