/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type TransactionAccountTypeExternalBankAccountExternalBankAccountDetailsMicrodepositRequirementType =
  (typeof TransactionAccountTypeExternalBankAccountExternalBankAccountDetailsMicrodepositRequirementType)[keyof typeof TransactionAccountTypeExternalBankAccountExternalBankAccountDetailsMicrodepositRequirementType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TransactionAccountTypeExternalBankAccountExternalBankAccountDetailsMicrodepositRequirementType =
  {
    code: "code",
    amounts: "amounts",
  } as const
