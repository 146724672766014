export function C(extras) {
  function buildLayer(InnerComponent, remainingExtras) {
    if (remainingExtras.length === 0) {
      return InnerComponent
    } else {
      const useExtra = remainingExtras[0]
      const Component = buildLayer(InnerComponent, remainingExtras.slice(1))

      return function Inner(props) {
        let extraData = useExtra(props)
        if (!("status" in extraData)) {
          extraData = { status: "ready", data: extraData }
        }

        if (extraData.status === "waiting") {
          return <extraData.Loading />
        } else if (extraData.status === "error") {
          return <extraData.Err error={extraData.error} />
        } else if (extraData.status === "ready") {
          return <Component {...props} {...extraData.data} />
        } else {
          return <Component {...props} {...extraData.data} />
        }
      }
    }
  }

  return {
    build: (InnerComponent) => buildLayer(InnerComponent, extras),
    inject: (extra) => C([...extras, extra]),
  }
}
