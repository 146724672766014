/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type ChangeOrderApprovalState =
  (typeof ChangeOrderApprovalState)[keyof typeof ChangeOrderApprovalState]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ChangeOrderApprovalState = {
  pending: "pending",
  rejected: "rejected",
  approved: "approved",
} as const
