/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type ContractItemsItemApprovalState =
  (typeof ContractItemsItemApprovalState)[keyof typeof ContractItemsItemApprovalState]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ContractItemsItemApprovalState = {
  pending: "pending",
  rejected: "rejected",
  approved: "approved",
} as const
