import { ReactNode } from "react"
import { cn } from "msutils/classnames"
import Typography from "../Typography"
import Spinner from "../Spinner"
import Tooltip from "../../layout/Tooltip"
import { ButtonUtils } from "./utils"

type Props = {
  onClick?: (e: React.MouseEvent<HTMLElement>) => void
  disabled?: boolean
  disabledMessage?: string | JSX.Element | null
  isLoading?: boolean
  icon?: JSX.Element
  endIcon?: JSX.Element
  theme: ButtonUtils.Theme
  submit?: boolean
  richText?: ReactNode
  children?: string
}

export default function Button(props: Props) {
  const { onClick, isLoading, icon, endIcon, theme, submit, disabledMessage, richText, children } =
    props
  const disabled = props.disabled || isLoading

  const iconColor = cn(
    !disabled ? theme.iconColor : theme.disabledIconColor,
    !disabled && theme.hoverIconColor,
  )

  return (
    <Tooltip inactive={!disabled || !disabledMessage} message={disabledMessage}>
      <button
        type={submit ? "submit" : "button"}
        onClick={disabled ? undefined : onClick}
        style={{
          outlineWidth: theme.borderWidth,
          outlineOffset: -theme.borderWidth,
        }}
        className={cn(
          "w-full p-0 border-0 outline transition-colors duration-75 rounded-4 outline-rounded-5",
          // background
          !disabled ? theme.bgColor : theme.disabledBgColor,
          !disabled && theme.hoverBgColor,
          // text
          !disabled ? theme.textColor : theme.disabledTextColor,
          !disabled && theme.hoverTextColor,
          // border
          !disabled ? theme.borderColor : theme.disabledBorderColor,
          !disabled && theme.hoverBorderColor,
          // cursor
          disabled && "cursor-not-allowed",
          // opacity
          disabled && theme.disabledOpacity,
        )}
      >
        <div
          className={cn(
            "relative whitespace-nowrap rounded-4",
            // padding
            theme.size === "large" ? "py-2.5" : "py-0",
            theme.variant === "primary" ? "px-4" : "px-0",
            // shadow
            !disabled && theme.hoverShadow,
          )}
        >
          <Typography
            variant={theme.size === "small" ? "bodybold" : "subtitle"}
            className={cn("flex items-center justify-center gap-2", isLoading && "invisible")}
          >
            {icon && <div className={iconColor}>{icon}</div>}
            {richText ?? children}
            {endIcon && <div className={iconColor}>{endIcon}</div>}
          </Typography>
          {isLoading && (
            <div className="absolute inset-2 flex items-center justify-center">
              <Spinner size="medium" />
            </div>
          )}
        </div>
      </button>
    </Tooltip>
  )
}
