/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type UpdateContractTypeInputContractType =
  (typeof UpdateContractTypeInputContractType)[keyof typeof UpdateContractTypeInputContractType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UpdateContractTypeInputContractType = {
  progress: "progress",
  milestone: "milestone",
  free_form: "free_form",
} as const
