/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type BillListRowDisplayStatus =
  (typeof BillListRowDisplayStatus)[keyof typeof BillListRowDisplayStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BillListRowDisplayStatus = {
  void: "void",
  paid: "paid",
  processing: "processing",
  scheduled: "scheduled",
  partially_paid: "partially_paid",
  rejected: "rejected",
  unsent: "unsent",
  overdue: "overdue",
  pending: "pending",
  ready_for_payment: "ready_for_payment",
} as const
