/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type ListExpandedExpenseLineItemsOrdering =
  (typeof ListExpandedExpenseLineItemsOrdering)[keyof typeof ListExpandedExpenseLineItemsOrdering]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ListExpandedExpenseLineItemsOrdering = {
  description: "description",
  "-description": "-description",
  schedule_of_values_item_number: "schedule_of_values_item_number",
  "-schedule_of_values_item_number": "-schedule_of_values_item_number",
  amount: "amount",
  "-amount": "-amount",
  created_at: "created_at",
  "-created_at": "-created_at",
  updated_at: "updated_at",
  "-updated_at": "-updated_at",
  cost_code_name: "cost_code_name",
  "-cost_code_name": "-cost_code_name",
  project_name: "project_name",
  "-project_name": "-project_name",
} as const
