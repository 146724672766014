/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type VendorListRowComplianceDocumentsItemType =
  (typeof VendorListRowComplianceDocumentsItemType)[keyof typeof VendorListRowComplianceDocumentsItemType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const VendorListRowComplianceDocumentsItemType = {
  coi: "coi",
  license: "license",
  w9: "w9",
} as const
