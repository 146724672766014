/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type InvoiceApprovalRuleCreateType =
  (typeof InvoiceApprovalRuleCreateType)[keyof typeof InvoiceApprovalRuleCreateType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InvoiceApprovalRuleCreateType = {
  admin_approval_required: "admin_approval_required",
  project_manager_approval_required: "project_manager_approval_required",
} as const
