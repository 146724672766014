/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type ListProjectBudgetsOrdering =
  (typeof ListProjectBudgetsOrdering)[keyof typeof ListProjectBudgetsOrdering]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ListProjectBudgetsOrdering = {
  client_facing: "client_facing",
  "-client_facing": "-client_facing",
  overhead_cost: "overhead_cost",
  "-overhead_cost": "-overhead_cost",
  created_at: "created_at",
  "-created_at": "-created_at",
  updated_at: "updated_at",
  "-updated_at": "-updated_at",
} as const
