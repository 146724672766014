/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type CardProgramStatus = (typeof CardProgramStatus)[keyof typeof CardProgramStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CardProgramStatus = {
  eligible: "eligible",
  ineligible: "ineligible",
  engaged: "engaged",
  onboarding: "onboarding",
  approved: "approved",
  processing: "processing",
  pending: "pending",
  declined: "declined",
  closed: "closed",
  unavailable_in_state: "unavailable_in_state",
  waiting: "waiting",
} as const
