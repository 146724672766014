import IconBase from "compass-local/legacy/IconBase"
import { StrokedIconProps } from "../types"

export default function RefreshWithExclamation({
  width,
  height = 20,
  onClick,
  href,
  tabIndex,
  className,
  thickness = 2,
}: StrokedIconProps) {
  return (
    <IconBase href={href} className={className} onClick={onClick} tabIndex={tabIndex}>
      <svg
        width={width?.toFixed(1)}
        height={height.toFixed(1)}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.124 5.7502H15L12.5301 3.04993C11.735 2.18112 10.7445 1.55634 9.65825 1.23839C8.57202 0.920444 7.42831 0.920537 6.34212 1.23866C5.25593 1.55679 4.26553 2.18173 3.47048 3.05067C2.67543 3.91961 2.10376 5.00192 1.81293 6.18879M1.00078 14.4847V10.2498M1.00078 10.2498H4.87677M1.00078 10.2498L3.46986 12.9501C4.26502 13.8189 5.25551 14.4437 6.34175 14.7616C7.42798 15.0796 8.57169 15.0795 9.65788 14.7613C10.7441 14.4432 11.7345 13.8183 12.5295 12.9493C13.3246 12.0804 13.8962 10.9981 14.1871 9.81121M15 1.51529V5.7485"
          stroke="currentColor"
          strokeWidth={thickness.toFixed(1)}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8 5.375V8M8 10.625H8.00875"
          stroke="#BD0101"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </IconBase>
  )
}
