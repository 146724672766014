/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type BeamBalanceTransactionTypeCreditCardBillPaymentType =
  (typeof BeamBalanceTransactionTypeCreditCardBillPaymentType)[keyof typeof BeamBalanceTransactionTypeCreditCardBillPaymentType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BeamBalanceTransactionTypeCreditCardBillPaymentType = {
  credit_card_bill_payment: "credit_card_bill_payment",
} as const
