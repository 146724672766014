/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type ComplianceDocumentTypeW9Type =
  (typeof ComplianceDocumentTypeW9Type)[keyof typeof ComplianceDocumentTypeW9Type]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ComplianceDocumentTypeW9Type = {
  w9: "w9",
} as const
