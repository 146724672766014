/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type ListRetainageReleasesOrdering =
  (typeof ListRetainageReleasesOrdering)[keyof typeof ListRetainageReleasesOrdering]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ListRetainageReleasesOrdering = {
  amount: "amount",
  "-amount": "-amount",
  date: "date",
  "-date": "-date",
  created_at: "created_at",
  "-created_at": "-created_at",
  updated_at: "updated_at",
  "-updated_at": "-updated_at",
} as const
