/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type ListRenofiAdClicksOrdering =
  (typeof ListRenofiAdClicksOrdering)[keyof typeof ListRenofiAdClicksOrdering]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ListRenofiAdClicksOrdering = {
  clicked_at: "clicked_at",
  "-clicked_at": "-clicked_at",
  created_at: "created_at",
  "-created_at": "-created_at",
  updated_at: "updated_at",
  "-updated_at": "-updated_at",
} as const
