import { Cell, CellField } from "./base"
import { Branded, EmptyString, FieldError } from "./utils"

type RoutingNumberString = Branded<string, "routing-number">
type RoutingNumberField<V> = CellField<string, V>

type RoutingNumberProps<TReq> = {
  required?: TReq
  initValue?: string
  errorKey?: string
}

function validateRoutingNumber(s: string) {
  if (Number.isNaN(s)) {
    throw new Error("Routing number must be numeric")
  }
  if (s.length !== 9) {
    throw new Error("Routing number must be 9 characters")
  }
  return s
}

export function RoutingNumber<TReq extends boolean = false>(
  props?: RoutingNumberProps<TReq>,
): RoutingNumberField<TReq extends true ? RoutingNumberString : RoutingNumberString | EmptyString> {
  const validate: any = (x: string) => {
    if (props?.required) {
      if (!x) {
        throw new FieldError("Required")
      } else {
        return validateRoutingNumber(x)
      }
    } else if (!x) {
      return ""
    } else {
      return validateRoutingNumber(x)
    }
  }
  return Cell({
    initValue: props?.initValue ?? "",
    validate,
    errorKey: props?.errorKey,
  })
}
