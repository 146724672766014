/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type ListEstimatesAsPayeePageSummarysOrdering =
  (typeof ListEstimatesAsPayeePageSummarysOrdering)[keyof typeof ListEstimatesAsPayeePageSummarysOrdering]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ListEstimatesAsPayeePageSummarysOrdering = {
  all_amount: "all_amount",
  "-all_amount": "-all_amount",
  all_count: "all_count",
  "-all_count": "-all_count",
  pending_amount: "pending_amount",
  "-pending_amount": "-pending_amount",
  pending_count: "pending_count",
  "-pending_count": "-pending_count",
  approved_amount: "approved_amount",
  "-approved_amount": "-approved_amount",
  approved_count: "approved_count",
  "-approved_count": "-approved_count",
  rejected_amount: "rejected_amount",
  "-rejected_amount": "-rejected_amount",
  rejected_count: "rejected_count",
  "-rejected_count": "-rejected_count",
  created_at: "created_at",
  "-created_at": "-created_at",
  updated_at: "updated_at",
  "-updated_at": "-updated_at",
} as const
