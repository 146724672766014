/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type QboLinkType = (typeof QboLinkType)[keyof typeof QboLinkType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const QboLinkType = {
  bill: "bill",
  bill_payment: "bill_payment",
  invoice: "invoice",
  invoice_payment: "invoice_payment",
  vendor: "vendor",
  customer: "customer",
  project: "project",
  expense: "expense",
} as const
