import IconBase from "compass-local/legacy/IconBase"
import { StrokedIconProps } from "../types"

export default function DollarExiting({
  width,
  height = 14,
  onClick,
  href,
  tabIndex,
  className,
  thickness = 1.5,
}: StrokedIconProps) {
  return (
    <IconBase href={href} className={className} onClick={onClick} tabIndex={tabIndex}>
      <svg
        width={width?.toFixed(1)}
        height={height.toFixed(1)}
        viewBox="0 0 15 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.87876 4.5V2.5C9.87876 1.94772 9.43104 1.5 8.87876 1.5H1.98987C1.43758 1.5 0.989868 1.94772 0.989868 2.5V12.5C0.989868 13.0523 1.43758 13.5 1.98987 13.5H8.87876C9.43104 13.5 9.87876 13.0523 9.87876 12.5V11"
          stroke="currentColor"
          strokeWidth={thickness.toFixed(1)}
          strokeLinecap="round"
        />
        <path
          d="M5.44446 4.3C4.33979 4.3 3.44446 5.016 3.44446 5.9C3.44446 6.784 4.33979 7.5 5.44446 7.5C6.54912 7.5 7.44446 8.216 7.44446 9.1C7.44446 9.984 6.54912 10.7 5.44446 10.7M5.44446 4.3V10.7M5.44446 4.3C6.18446 4.3 6.83112 4.6216 7.17712 5.1M5.44446 4.3V3.5M5.44446 10.7V11.5M5.44446 10.7C4.70446 10.7 4.05779 10.3784 3.71179 9.9"
          stroke="currentColor"
          strokeWidth={thickness.toFixed(1)}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.7172 9.86364L13.899 7.68182M13.899 7.68182L11.7172 5.5M13.899 7.68182H9.44446"
          stroke="currentColor"
          strokeWidth={thickness.toFixed(1)}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </IconBase>
  )
}
