/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type BeamBalanceTransactionTypeTransferDirection =
  (typeof BeamBalanceTransactionTypeTransferDirection)[keyof typeof BeamBalanceTransactionTypeTransferDirection]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BeamBalanceTransactionTypeTransferDirection = {
  debit: "debit",
  credit: "credit",
} as const
