import { ReactNode } from "react"
import { cn } from "msutils/classnames"
import Typography from "compass/data/Typography"
import { MetricsUtils } from "compass-local/Metrics"
import { TextColors } from "compass-local/utils/colors"
import { unreachable } from "msutils/misc"
import useScreenSize from "compass/theme/useScreenSize"
import { BaseMetricUtils as Utils } from "../utils"

type Props = MetricsUtils.Spec & {
  k: string
  v: ReactNode
}

export default function BaseMetric(props: Props) {
  const sz = useScreenSize()
  const ctx = MetricsUtils.useContext()
  const layout = props.layout ?? ctx.layout ?? "v"
  const variant = props.variant ?? ctx.variant ?? "small"
  const color = TextColors[props.color ?? ctx.color ?? "normal"]
  // eslint-disable-next-line mosaic-js/no-coalesce-true
  const bold = props.bold ?? ctx.bold ?? true
  const align = props.align ?? ctx.align ?? "left"
  const { k, v } = props

  return (
    <div
      className={cn(
        layout === "v"
          ? "vflex"
          : layout === "h"
          ? "flex justify-between gap-4"
          : unreachable(layout),
        variant === "xsmall"
          ? "gap-1"
          : variant === "small" || variant === "medium"
          ? "gap-1"
          : variant === "large"
          ? "gap-3"
          : variant === "preview/medium"
          ? "gap-3"
          : variant === "preview/large"
          ? "gap-3"
          : unreachable(variant),
        align === "right" ? "items-end" : align === "left" ? "items-start" : unreachable(align),
      )}
    >
      <div className="flex gap-1 items-center">
        {props.labelIcon}
        <div className="flex gap-2 items-center">
          <Typography variant={Utils.getKeyTypographyVariant(variant)}>{k}</Typography>
          {props.labelEndIcon}
        </div>
      </div>
      <div
        className={cn(
          "flex gap-1 items-center",
          (align === "right" || layout === "h") && "text-right",
          color,
        )}
      >
        {props.icon}
        <Typography
          variant={Utils.getValueTypographyVariant(variant, { sz, bold })}
          className={props.clamp ? "clamp-1" : ""}
        >
          {v}
        </Typography>
      </div>
    </div>
  )
}
