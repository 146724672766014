/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type VendorListRowComplianceStatusSummaryBusinessLevelStatus =
  (typeof VendorListRowComplianceStatusSummaryBusinessLevelStatus)[keyof typeof VendorListRowComplianceStatusSummaryBusinessLevelStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const VendorListRowComplianceStatusSummaryBusinessLevelStatus = {
  compliant: "compliant",
  not_compliant: "not_compliant",
} as const
