/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type BeamBalanceTransactionTypeChargeChargeDetailsType =
  (typeof BeamBalanceTransactionTypeChargeChargeDetailsType)[keyof typeof BeamBalanceTransactionTypeChargeChargeDetailsType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BeamBalanceTransactionTypeChargeChargeDetailsType = {
  invoice: "invoice",
  saas: "saas",
} as const
