import IconBase from "compass-local/legacy/IconBase"
import { StrokedIconProps } from "../types"

export default function LocationPin({
  width,
  height = 20,
  onClick,
  href,
  tabIndex,
  className,
  thickness = 1.4,
}: StrokedIconProps) {
  return (
    <IconBase href={href} className={className} onClick={onClick} tabIndex={tabIndex}>
      <svg
        width={width?.toFixed(1)}
        height={height.toFixed(1)}
        viewBox="0 0 14 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.7713 10.6046C10.2495 11.1263 9.25195 12.1239 8.41384 12.962C7.63279 13.743 6.36729 13.7431 5.58624 12.962C4.76385 12.1396 3.78311 11.1589 3.22878 10.6046C1.14599 8.52177 1.14599 5.14489 3.22878 3.0621C5.31158 0.979301 8.68846 0.979301 10.7713 3.0621C12.8541 5.14489 12.8541 8.52177 10.7713 10.6046Z"
          stroke="currentColor"
          strokeWidth={thickness.toFixed(1)}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.00002 6.83333C9.00002 7.9379 8.10459 8.83333 7.00002 8.83333C5.89545 8.83333 5.00002 7.9379 5.00002 6.83333C5.00002 5.72876 5.89545 4.83333 7.00002 4.83333C8.10459 4.83333 9.00002 5.72876 9.00002 6.83333Z"
          stroke="currentColor"
          strokeWidth={thickness.toFixed(1)}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </IconBase>
  )
}
