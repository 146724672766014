import { Cell, CellField } from "./base"
import { Branded, EmptyString, FieldError } from "./utils"

type UrlString = Branded<string, "ein">
type UrlField<V> = CellField<string, V>

type UrlProps<TReq> = {
  required?: TReq
  initValue?: string
  errorKey?: string
}

function validateUrl(s: string) {
  const fullUrl = !s.includes("://") ? `https://${s}` : s
  try {
    const parsedUrl = new URL(fullUrl)
    if (parsedUrl.protocol !== "http:" && parsedUrl.protocol !== "https:") {
      throw new Error("Invalid URL")
    }
    if (s.includes(" ")) {
      throw new Error("URL cannot contain spaces")
    }
    if (s.includes("@")) {
      throw new Error("URL cannot contain @")
    }
    if (!s.includes(".")) {
      throw new Error("Invalid URL")
    }
    return s
  } catch (e) {
    throw new Error("Invalid URL")
  }
}

export function Url<TReq extends boolean = false>(
  props?: UrlProps<TReq>,
): UrlField<TReq extends true ? UrlString : UrlString | EmptyString> {
  const validate: any = (x: string) => {
    if (props?.required) {
      if (!x) {
        throw new FieldError("Required")
      } else {
        return validateUrl(x)
      }
    } else if (!x) {
      return ""
    } else {
      return validateUrl(x)
    }
  }
  return Cell({
    initValue: props?.initValue ?? "",
    validate,
    errorKey: props?.errorKey,
  })
}
