/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type AdminQBRequestPatchType =
  (typeof AdminQBRequestPatchType)[keyof typeof AdminQBRequestPatchType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AdminQBRequestPatchType = {
  push: "push",
  pull: "pull",
} as const
