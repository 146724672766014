/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type ListQBProjectsOrdering =
  (typeof ListQBProjectsOrdering)[keyof typeof ListQBProjectsOrdering]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ListQBProjectsOrdering = {
  name: "name",
  "-name": "-name",
  parent_name: "parent_name",
  "-parent_name": "-parent_name",
  cb_name: "cb_name",
  "-cb_name": "-cb_name",
  qb_created_at: "qb_created_at",
  "-qb_created_at": "-qb_created_at",
  import_request_state: "import_request_state",
  "-import_request_state": "-import_request_state",
  source_id: "source_id",
  "-source_id": "-source_id",
  created_at: "created_at",
  "-created_at": "-created_at",
  updated_at: "updated_at",
  "-updated_at": "-updated_at",
} as const
