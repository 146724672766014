/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type InvoiceActionRequestCreateType =
  (typeof InvoiceActionRequestCreateType)[keyof typeof InvoiceActionRequestCreateType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InvoiceActionRequestCreateType = {
  update: "update",
  approve: "approve",
} as const
