/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type ProjectExpenseApprovalState =
  (typeof ProjectExpenseApprovalState)[keyof typeof ProjectExpenseApprovalState]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProjectExpenseApprovalState = {
  pending: "pending",
  rejected: "rejected",
  approved: "approved",
  void: "void",
} as const
