/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type SetBusinessStatusInputArchivedReason =
  (typeof SetBusinessStatusInputArchivedReason)[keyof typeof SetBusinessStatusInputArchivedReason]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SetBusinessStatusInputArchivedReason = {
  is_suspicious: "is_suspicious",
  not_supported: "not_supported",
  null: null,
} as const
