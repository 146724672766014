/* eslint-disable mosaic-js/no-scream-case */

import { unreachable } from "msutils/misc"
import { execute } from "utils/misc"

type VercelEnv = "test" | "development" | "preview" | "demo" | "production"
type Environment = "test" | "local" | "dev" | "demo" | "prod"

function getEnvironment(): Environment {
  const vercelEnv = process.env.NEXT_PUBLIC_VERCEL_ENV as VercelEnv | undefined
  if (!vercelEnv) return "local"

  switch (vercelEnv) {
    case "test":
      return "test"
    case "development":
    case "preview":
      return "dev"
    case "demo":
      return "demo"
    case "production":
      return "prod"
    default:
      throw new Error(`Unexpected environment setting: ${vercelEnv}`)
  }
}

export function enableOptionalServices(env: Environment) {
  return !["test", "local"].includes(env)
}

export function prodLike(env: Environment) {
  return ["demo", "prod"].includes(env)
}

export function devLike(env: Environment) {
  return ["test", "local", "dev"].includes(env)
}

export const ENVIRONMENT = getEnvironment()
export const LOGROCKET_KEY = process.env.NEXT_PUBLIC_LOGROCKET_KEY as string
export const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN as string
export const STRIPE_API_KEY = process.env.NEXT_PUBLIC_STRIPE_API_KEY as string
export const LAUNCHDARKLY_API_KEY = process.env.NEXT_PUBLIC_LAUNCHDARKLY_API_KEY as string
export const CB_BASE_URL =
  (process.env.NEXT_PUBLIC_BASE_URL_OVERRIDE as string) ||
  execute(() => {
    switch (ENVIRONMENT) {
      case "test":
      case "local":
      case "dev":
        return "https://api.dev.trybeam.com"
      case "demo":
        return "https://api.demo.trybeam.com"
      case "prod":
        return "https://api.trybeam.com"
      default:
        return unreachable(ENVIRONMENT)
    }
  })
export const SMARTY_STREETS_KEY = process.env.NEXT_PUBLIC_SMARTY_STREETS_KEY as string
