/*

error handling dimensions
* component error boundaries
* global unhandled errors
* inline js
* inline jsx
* reporting (i.e., what to do when you've caught an error)
  * the only place sentry client should be used is for setting this callback
* query errors
* mutation errors
* fallback (for inline reports)

MSError2.message - safe to display, since this will frequently end up accidentally displayed anyways
MSError2.description - report message
MSError2.name - safe to display, included in report

*/

import { FC, ReactNode, useCallback } from "react"
import { ErrorBoundary, FallbackProps } from "react-error-boundary"
import { useOnMount } from "../common"

export class MSError2 extends Error {
  static defaultMessage = "An unexpected error has occurred"

  static report(error: Error | string) {
    // eslint-disable-next-line
    console.log(error)
  }

  static Fallback = ({ error }: { error?: MSError2 }) => {
    return <>{error?.message ?? MSError2.defaultMessage}</>
  }

  static DefaultFallback = ({
    error,
    CustomUi,
  }: {
    error?: MSError2
    CustomUi?: FC<{ error?: MSError2 }>
  }) => {
    useOnMount(() => {
      MSError2.report(error ?? "An unknown error has occurred")
    })
    if (CustomUi) {
      return <CustomUi error={error} />
    } else {
      return <MSError2.Fallback error={error} />
    }
  }

  static convertError(error: Error) {
    if (error instanceof MSError2) {
      return error
    } else {
      const newError = new MSError2(MSError2.defaultMessage, {
        name: "WrappedError",
        reportMessage: error.message,
      })
      newError.stack = error.stack
      newError.cause = error.cause
      return newError
    }
  }

  /** There is no HOC for Boundary. If you're trying to wrap a component, rethink things - wrapping components is a good way to make boundaries O(N) instead of O(1). */
  static Boundary = (props: { CustomUi?: FC<{ error?: MSError2 }>; children: ReactNode }) => {
    const Fallback = useCallback(
      ({ error }: FallbackProps) => {
        if (error instanceof Error) {
          return (
            <MSError2.DefaultFallback
              error={MSError2.convertError(error)}
              CustomUi={props.CustomUi}
            />
          )
        } else {
          return <MSError2.DefaultFallback CustomUi={props.CustomUi} />
        }
      },
      [props.CustomUi],
    )

    return <ErrorBoundary FallbackComponent={Fallback}>{props.children}</ErrorBoundary>
  }

  static useGlobalErrorHandling = () => {
    useOnMount(() => {
      const listener = (e: ErrorEvent) => {
        const err = e.error
        if (err instanceof MSError2) {
          MSError2.report(err)
        } else if (err instanceof Error) {
          MSError2.report(err.message)
        } else {
          MSError2.report(`Non-standard error: ${JSON.stringify(err)}`)
        }
        return true
      }
      window.addEventListener("error", listener)
      return () => {
        window.removeEventListener("error", listener)
      }
    })
  }

  /** For errors caught by boundary */
  componentStack: string | null = null

  reportMessage: string

  /** DONOTUSE - if an error makes it to global scope, it *should* be reported */
  _handled: boolean = false

  constructor(userFacingMessage: string, options?: { reportMessage?: string; name?: string }) {
    const reportMessage = options?.reportMessage
      ? `${options.reportMessage} (${userFacingMessage})`
      : userFacingMessage
    super(userFacingMessage)
    if (options?.name) {
      this.name = options.name
    }
    this.reportMessage = reportMessage
  }
}
