/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type InvoicePaymentState = (typeof InvoicePaymentState)[keyof typeof InvoicePaymentState]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InvoicePaymentState = {
  unpaid: "unpaid",
  partially_paid: "partially_paid",
  paid: "paid",
} as const
