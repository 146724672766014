import IconBase from "compass-local/legacy/IconBase"
import { IconProps } from "../types"

export default function ErrorIcon({
  width,
  height = 14,
  onClick,
  href,
  tabIndex,
  className,
}: IconProps) {
  return (
    <IconBase href={href} className={className} onClick={onClick} tabIndex={tabIndex}>
      <svg
        width={width?.toFixed(1)}
        height={height.toFixed(1)}
        viewBox="0 0 17 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.4445 8C16.4445 10.1217 15.6016 12.1566 14.1013 13.6569C12.601 15.1571 10.5662 16 8.44446 16C6.32273 16 4.28789 15.1571 2.7876 13.6569C1.28731 12.1566 0.444458 10.1217 0.444458 8C0.444458 5.87827 1.28731 3.84344 2.7876 2.34315C4.28789 0.842855 6.32273 0 8.44446 0C10.5662 0 12.601 0.842855 14.1013 2.34315C15.6016 3.84344 16.4445 5.87827 16.4445 8ZM9.44446 12C9.44446 12.2652 9.3391 12.5196 9.15156 12.7071C8.96403 12.8946 8.70967 13 8.44446 13C8.17924 13 7.92489 12.8946 7.73735 12.7071C7.54982 12.5196 7.44446 12.2652 7.44446 12C7.44446 11.7348 7.54982 11.4804 7.73735 11.2929C7.92489 11.1054 8.17924 11 8.44446 11C8.70967 11 8.96403 11.1054 9.15156 11.2929C9.3391 11.4804 9.44446 11.7348 9.44446 12ZM8.44446 3C8.17924 3 7.92489 3.10536 7.73735 3.29289C7.54982 3.48043 7.44446 3.73478 7.44446 4V8C7.44446 8.26522 7.54982 8.51957 7.73735 8.70711C7.92489 8.89464 8.17924 9 8.44446 9C8.70967 9 8.96403 8.89464 9.15156 8.70711C9.3391 8.51957 9.44446 8.26522 9.44446 8V4C9.44446 3.73478 9.3391 3.48043 9.15156 3.29289C8.96403 3.10536 8.70967 3 8.44446 3Z"
          fill="currentColor"
        />
      </svg>
    </IconBase>
  )
}
