import { useState } from "react"
import { cn } from "msutils/classnames"
import { Dropdown, Drawer as BaseDrawer } from "antd"
import { Collapsable, MSArray } from "msutils/array"
import { t } from "content"
import Button from ".."
import useScreenSize from "../../../theme/useScreenSize"
import Divider from "../../Divider"
import Typography from "../../Typography"
import { ButtonUtils } from "../utils"
import Icon from "../../Icon"
import { Option, processOptions } from "./internal-utils"
import { DropdownButtonUtils } from "./utils"

export type Props = {
  options: Collapsable<DropdownButtonUtils.OptionOrDivider[]>
  theme: ButtonUtils.Theme
  closeButtonTheme: ButtonUtils.Theme
  keepMenuMounted?: boolean
  disabled?: boolean
  isLoading?: boolean
  icon?: JSX.Element
  hideChevron?: boolean
  popupOnly?: boolean
  children: string
}

export default function DropdownButton({
  options: unprocessedOptions,
  disabled,
  icon,
  theme,
  closeButtonTheme,
  keepMenuMounted,
  hideChevron,
  popupOnly,
  children,
}: Props) {
  const [isOpen, setIsOpen] = useState(false)
  const sz = useScreenSize()
  const options = processOptions(unprocessedOptions)

  if (options.length === 0) return null
  if (sz === "sm" && !popupOnly) {
    return (
      <>
        <BaseDrawer
          open={isOpen}
          onClose={(e) => {
            setIsOpen(false)
            e.stopPropagation()
          }}
          width="100%"
          height="auto"
          closeIcon={null}
          placement="bottom"
          zIndex={10002}
          push={false}
          getContainer={() => {
            // TODO: fix this
            const rootEl = document.getElementById("mos-drawer-root")
            return rootEl ?? document.body
          }}
          rootStyle={{ isolation: "isolate", position: "absolute", top: 0, bottom: 0 }}
          headerStyle={{ display: "none" }}
          drawerStyle={{ background: "transparent" }}
          bodyStyle={{ padding: 0, background: "white" }}
          contentWrapperStyle={{
            overflow: "hidden",
            borderTopLeftRadius: 6,
            borderTopRightRadius: 6,
          }}
          destroyOnClose={!keepMenuMounted}
        >
          <div className="vflex items-center gap-4 p-5">
            <Typography variant="label" className="text-th-text-secondary">
              {children}
            </Typography>
            {MSArray.collapse(options).map((item, i) =>
              item.type === "divider" ? (
                <div key={i} className="w-full">
                  <Divider />
                </div>
              ) : item.type === "override" ? (
                <div
                  key={item.key ?? i}
                  onClick={!item.disabled ? () => setIsOpen(false) : undefined}
                  className={cn(item.disabled ? "cursor-not-allowed" : "cursor-pointer")}
                >
                  {item.ui}
                </div>
              ) : (
                <div
                  key={i}
                  onClick={
                    !item.disabled
                      ? () => {
                          item.onClick?.()
                          setIsOpen(false)
                        }
                      : undefined
                  }
                  className={cn(item.disabled ? "cursor-not-allowed" : "cursor-pointer")}
                >
                  <Option {...item} key={i} />
                </div>
              ),
            )}
            <div className="w-full">
              <Button
                icon={<Icon name="x" height={14} />}
                theme={closeButtonTheme}
                onClick={() => setIsOpen(false)}
              >
                {t("Close")}
              </Button>
            </div>
          </div>
        </BaseDrawer>
        <Button
          icon={icon}
          disabled={disabled}
          theme={theme}
          onClick={() => {
            if (!disabled) {
              setIsOpen(true)
            }
          }}
        >
          {children}
        </Button>
      </>
    )
  } else {
    return (
      <Dropdown
        open={isOpen}
        onOpenChange={setIsOpen}
        destroyPopupOnHide={!keepMenuMounted}
        menu={{
          className:
            "w-full min-w-[220px] float-right relative -top-1 !rounded-2 !border !border-th-warmgrey-2",
          items: MSArray.collapse(options).map((item, i) =>
            item.type === "divider"
              ? { key: i, type: "divider" as const, className: "!mx-3 !bg-th-warmgrey-2" }
              : item.type === "override"
              ? {
                  key: item.key ?? i,
                  label: item.ui,
                  disabled: item.disabled,
                  onClick: () => setIsOpen(false),
                  className: "!p-0 !rounded-2",
                }
              : {
                  key: i,
                  disabled: item.disabled,
                  label: <Option {...item} />,
                  onClick: () => {
                    item.onClick?.()
                    setIsOpen(false)
                  },
                  className: "!p-0 !rounded-2",
                },
          ),
        }}
        trigger={!disabled ? ["click"] : []}
      >
        {/* antd only works when child is a div */}
        <div className="">
          <Button
            icon={icon}
            disabled={disabled}
            theme={theme}
            endIcon={
              hideChevron ? undefined : (
                <Icon name={["chevron", isOpen ? "-90" : "90"]} height={16} />
              )
            }
          >
            {children}
          </Button>
        </div>
      </Dropdown>
    )
  }
}
