/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type InvoiceApprovalActionType =
  (typeof InvoiceApprovalActionType)[keyof typeof InvoiceApprovalActionType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InvoiceApprovalActionType = {
  approve: "approve",
  reject: "reject",
  resubmit: "resubmit",
  unapprove: "unapprove",
} as const
