import { BaseGroup, BaseGroupField, FieldGroup, GroupInits, GroupSubvals } from "./base"
import * as Utils from "./js-utils"
import { InputCore, InputValue } from "./utils"

type Group<T extends FieldGroup, V> = BaseGroupField<T, V>

type OnChange<S extends string | number | symbol> = S extends string
  ? `onChange${Capitalize<S>}`
  : S

type GroupHooks<T extends FieldGroup> = (cores: { [K in keyof T]: InputCore<T[K]> }) => Partial<{
  [K in keyof T as OnChange<K>]?: (newValue: InputValue<T[K]>) => void
}>

type GroupProps<T extends FieldGroup, TOpt extends boolean, V> = {
  initValue?: Partial<GroupInits<T>>
  validate?: (subvals: TOpt extends true ? GroupSubvals<T> | null : GroupSubvals<T>) => V
  optional?: TOpt
  errorKey?: string
  hooks?: GroupHooks<T>
  spec: { [K in keyof T]: T[K] }
}

export function Group<
  T extends FieldGroup,
  TOpt extends boolean = false,
  V = TOpt extends true ? GroupSubvals<T> | null : GroupSubvals<T>,
>(props: GroupProps<T, TOpt, V>): Group<T, V> {
  const validate: any = (x: any) => {
    if (!props.optional && x === null) throw new Error("Invalid input")
    return props.validate?.(x) ?? x
  }

  return {
    ...BaseGroup({
      spec: props.spec,
      // TODO: I think this is wrong?
      initValue: Object.fromEntries(
        Object.entries(props.spec).map(([k, v]) => [k, Utils.getDefaults(v, props.initValue?.[k])]),
      ) as any,
      validate,
      errorKey: props.errorKey,
    }),
    hooks: props.hooks,
  } as any
}
