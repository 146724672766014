/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type CompanyCamLinkType = (typeof CompanyCamLinkType)[keyof typeof CompanyCamLinkType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CompanyCamLinkType = {
  project: "project",
  photo: "photo",
  document: "document",
} as const
