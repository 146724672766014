/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type ExpenseCardStatus = (typeof ExpenseCardStatus)[keyof typeof ExpenseCardStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ExpenseCardStatus = {
  active: "active",
  inactive: "inactive",
  canceled: "canceled",
  frozen: "frozen",
} as const
