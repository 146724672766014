import { Cell, CellField } from "./base"

type Choice<T extends {}, V> = CellField<T, V>

type ChoiceProps = {
  errorKey?: string
}

export function DefaultChoice<T extends {}>(defaultValue: T, props?: ChoiceProps): Choice<T, T> {
  return Cell({
    initValue: defaultValue,
    validate: (x) => x,
    errorKey: props?.errorKey,
  })
}
