import IconBase from "compass-local/legacy/IconBase"
import { IconProps } from "../types"

export default function Arrow({
  width,
  height = 16,
  onClick,
  href,
  tabIndex,
  className,
}: IconProps) {
  return (
    <IconBase href={href} className={className} onClick={onClick} tabIndex={tabIndex}>
      <svg
        width={width?.toFixed(1)}
        height={height.toFixed(1)}
        viewBox="0 0 14 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.292786 7.707C0.105315 7.51947 0 7.26516 0 7C0 6.73484 0.105315 6.48053 0.292786 6.293L6.29279 0.293C6.48031 0.105529 6.73462 0.000213623 6.99979 0.000213623C7.26495 0.000213623 7.51926 0.105529 7.70679 0.293L13.7068 6.293C13.8889 6.4816 13.9897 6.7342 13.9875 6.9964C13.9852 7.2586 13.88 7.50941 13.6946 7.69482C13.5092 7.88023 13.2584 7.9854 12.9962 7.98767C12.734 7.98995 12.4814 7.88916 12.2928 7.707L7.99979 3.414V15C7.99979 15.2652 7.89443 15.5196 7.70689 15.7071C7.51936 15.8946 7.265 16 6.99979 16C6.73457 16 6.48022 15.8946 6.29268 15.7071C6.10514 15.5196 5.99979 15.2652 5.99979 15V3.414L1.70679 7.707C1.51926 7.89447 1.26495 7.99979 0.999786 7.99979C0.734622 7.99979 0.480314 7.89447 0.292786 7.707V7.707Z"
          fill="currentColor"
        />
      </svg>
    </IconBase>
  )
}
