/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type ListInvoiceIntentsOrdering =
  (typeof ListInvoiceIntentsOrdering)[keyof typeof ListInvoiceIntentsOrdering]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ListInvoiceIntentsOrdering = {
  type: "type",
  "-type": "-type",
  prefill_status: "prefill_status",
  "-prefill_status": "-prefill_status",
  created_at: "created_at",
  "-created_at": "-created_at",
  updated_at: "updated_at",
  "-updated_at": "-updated_at",
} as const
