/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type RemovalChangeOrderPatchApprovalState =
  (typeof RemovalChangeOrderPatchApprovalState)[keyof typeof RemovalChangeOrderPatchApprovalState]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RemovalChangeOrderPatchApprovalState = {
  pending: "pending",
  rejected: "rejected",
  approved: "approved",
} as const
