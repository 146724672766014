/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type InvoiceInvoiceType = (typeof InvoiceInvoiceType)[keyof typeof InvoiceInvoiceType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InvoiceInvoiceType = {
  progress: "progress",
  free_form: "free_form",
  milestone: "milestone",
} as const
