import IconBase from "compass-local/legacy/IconBase"
import { IconProps } from "../types"

export default function ArrowUpCircle({
  width,
  height = 16,
  onClick,
  href,
  tabIndex,
  className,
}: IconProps) {
  return (
    <IconBase href={href} className={className} onClick={onClick} tabIndex={tabIndex}>
      <svg
        width={width?.toFixed(1)}
        height={height.toFixed(1)}
        viewBox="0 0 16 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 16.5C12.4183 16.5 16 12.9183 16 8.5C16 4.08172 12.4183 0.5 8 0.5C3.58172 0.5 0 4.08172 0 8.5C0 12.9183 3.58172 16.5 8 16.5ZM11.7071 7.79289L8.70711 4.79289C8.31658 4.40237 7.68342 4.40237 7.29289 4.79289L4.29289 7.79289C3.90237 8.18342 3.90237 8.81658 4.29289 9.20711C4.68342 9.59763 5.31658 9.59763 5.70711 9.20711L7 7.91421L7 11.5C7 12.0523 7.44771 12.5 8 12.5C8.55228 12.5 9 12.0523 9 11.5V7.91421L10.2929 9.20711C10.6834 9.59763 11.3166 9.59763 11.7071 9.20711C12.0976 8.81658 12.0976 8.18342 11.7071 7.79289Z"
          fill="currentColor"
        />
      </svg>
    </IconBase>
  )
}
