import { ReactNode, useMemo, useRef, useState } from "react"
import { MSTimestamp, useOnChange, useOnMount } from "msutils"
import { unreachable } from "msutils/misc"
import { createPortal } from "react-dom"
import { PortalRootId } from "./_internal/constants"
import { useOnAnyMovement, useOnResize, useOnScroll, useOnWindowLoseFocus } from "./dom-utils"
import { BaseLayout } from "./baseLayout"

export namespace PortalLayout {
  type Coordinate = { x: number; y: number }

  function usePortalRoot() {
    return useMemo(() => document.getElementById(PortalRootId) as HTMLDivElement, [])
  }

  function useOnClickAnywhere(onClickAnywhere: (target: HTMLElement) => void) {
    const handlerRef = useRef(onClickAnywhere)
    handlerRef.current = onClickAnywhere
    useOnMount(() => {
      const handle = (e: MouseEvent) =>
        e.target instanceof HTMLElement && handlerRef.current(e.target)
      document.addEventListener("click", handle)
      return () => {
        document.removeEventListener("click", handle)
      }
    })
  }

  function Portal({ children }: { children: ReactNode }) {
    const portalRoot = usePortalRoot()
    const portal = useMemo(() => createPortal(<>{children}</>, portalRoot), [children, portalRoot])

    return <>{portal}</>
  }

  function getAllowableBounds() {
    return document.body.getBoundingClientRect()
  }

  type Direction2 = "top-left" | "top-right" | "bottom-left" | "bottom-right" | "none"

  function ContentLayout({
    anchor,
    contentRef: contentRef_,
    isActive,
    setInactive,
    direction: directionOverride,
    getAnchorOffset: getAnchorOffsetOverride,
    animationBehavior,
    animationDuration,
    margin,
    offset,
  }: {
    anchor: HTMLElement
    contentRef: HTMLDivElement
    isActive: boolean
    setInactive: () => void
    direction: Direction2 | undefined
    getAnchorOffset: (() => Coordinate) | undefined
    animationBehavior: "drop" | "scale" | "none"
    animationDuration: number
    margin: number
    offset: number
  }) {
    const contentRef = contentRef_
    const getDirection = (anchorRect: DOMRect): Direction2 => {
      if (directionOverride) {
        return directionOverride
      } else {
        const allowableBounds = getAllowableBounds()
        const contentSize = contentRef.getBoundingClientRect()
        const rightHasSpace = allowableBounds.right - anchorRect.right - margin > contentSize.width
        const leftHasSpace = anchorRect.left - margin > contentSize.width
        const bottomHasSpace =
          allowableBounds.bottom - anchorRect.bottom - margin - offset > contentSize.height
        const topHasSpace = anchorRect.top - margin - offset > contentSize.height
        // console.log(
        // `space - right: ${rightHasSpace}, left: ${leftHasSpace}, bottom: ${bottomHasSpace}, top: ${topHasSpace}`,
        // )
        // Note: anchor to the left if the right has space
        if (rightHasSpace) {
          if (bottomHasSpace) {
            return "bottom-left"
          } else if (topHasSpace) {
            return "top-left"
          } else {
            return "bottom-left"
          }
        } else if (leftHasSpace) {
          if (bottomHasSpace) {
            return "bottom-right"
          } else if (topHasSpace) {
            return "top-right"
          } else {
            return "bottom-right"
          }
        } else {
          if (bottomHasSpace) {
            return "bottom-left"
          } else if (topHasSpace) {
            return "top-left"
          } else {
            return "bottom-left"
          }
        }
      }
    }

    const setPosition = (reason: string) => {
      if (!reason) throw new Error("Must have a reason")
      if (!isActive) return

      const anchorRect = anchor.getBoundingClientRect()
      const contentSize = contentRef.getBoundingClientRect()
      const direction = getDirection(anchorRect)
      // console.log(`setting position for ${reason}`)
      // console.log(anchor)
      const getDirectionOffset = (): Coordinate => {
        if (getAnchorOffsetOverride) {
          return getAnchorOffsetOverride()
        }
        switch (direction) {
          case "top-left":
            return { x: 0, y: -contentSize.height - offset }
          case "top-right":
            return { x: anchorRect.width - contentSize.width, y: -contentSize.height - offset }
          case "bottom-left":
            return { x: 0, y: anchorRect.height + offset }
          case "bottom-right":
            return { x: anchorRect.width - contentSize.width, y: anchorRect.height + offset }
          case "none":
            return { x: 0, y: 0 }
          default:
            return unreachable(direction)
        }
      }
      const directionOffset = getDirectionOffset()
      contentRef.style.top = `${anchorRect.top + directionOffset.y}px`
      contentRef.style.left = `${anchorRect.left + directionOffset.x}px`
      contentRef.style.transformOrigin =
        direction === "top-left"
          ? "0 100%"
          : direction === "top-right"
          ? "80% 100%"
          : direction === "bottom-left"
          ? "0 0"
          : direction === "bottom-right"
          ? "80% 0"
          : direction === "none"
          ? "0 0"
          : unreachable(direction)
    }

    useOnResize([contentRef], () => setPosition("content size change"))
    useOnWindowLoseFocus(() => setInactive())
    useOnAnyMovement(anchor, () => setPosition("anchor position change"))
    useOnScroll(anchor, setInactive)

    useOnMount(() => {
      if (animationBehavior !== "none") {
        contentRef.style.transition = "ease-in-out opacity, ease-in-out transform"
        contentRef.style.transitionDuration = `${animationDuration}ms`
      }
    })
    useOnChange([isActive], () => {
      // we only set position here because we avoid setting position when the state is inactive
      setPosition("active state change")
      const anchorRect = anchor.getBoundingClientRect()
      const direction = getDirection(anchorRect)
      contentRef.style.opacity = isActive ? "1" : "0"

      if (isActive) {
        contentRef.style.transform = ""
        contentRef.style.pointerEvents = ""
      } else {
        contentRef.style.pointerEvents = "none"
        if (animationBehavior === "drop") {
          contentRef.style.transform = `translateY(${direction.startsWith("top") ? "" : "-"}10px)`
        } else if (animationBehavior === "scale") {
          contentRef.style.transform = "scale(0.1)"
        } else if (animationBehavior === "none") {
          //
        } else {
          unreachable(animationBehavior)
        }
      }
    })

    return null
  }

  function ClickListener({ onClick }: { onClick: () => void }) {
    const mountedAt = useRef(MSTimestamp.now()).current
    useOnClickAnywhere(() => {
      if (mountedAt.add(0.1, "second").isPast()) {
        onClick()
      }
    })
    return null
  }

  export function Dropdown({
    anchor,
    content,
    isActive,
    setInactive,
    direction,
    getAnchorOffset,
    animationBehavior = "drop",
    animationDuration = 800,
    offset = 4,
    margin = 8,
  }: {
    anchor: HTMLElement
    content: ReactNode
    isActive: boolean
    setInactive: () => void
    direction?: Direction2
    getAnchorOffset?: () => Coordinate
    closeBehavior?: "on-blur" | "manual"
    animationBehavior?: "drop" | "scale" | "none"
    animationDuration?: number
    offset?: number
    margin?: number
  }) {
    const [contentRef, setContentRef] = useState<HTMLDivElement | null>(null)

    return (
      <Portal>
        {contentRef && (
          <ContentLayout
            contentRef={contentRef}
            anchor={anchor}
            isActive={isActive}
            setInactive={setInactive}
            direction={direction}
            getAnchorOffset={getAnchorOffset}
            animationBehavior={animationBehavior}
            animationDuration={animationDuration}
            offset={offset}
            margin={margin}
          />
        )}
        {isActive && <ClickListener onClick={setInactive} />}
        <BaseLayout.NativeDiv ref={setContentRef} position="absolute" opacity="0">
          <BaseLayout.ClickPropagationBoundary>{content}</BaseLayout.ClickPropagationBoundary>
        </BaseLayout.NativeDiv>
      </Portal>
    )
  }
}
