/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type ListCardTransactionListRowsOrdering =
  (typeof ListCardTransactionListRowsOrdering)[keyof typeof ListCardTransactionListRowsOrdering]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ListCardTransactionListRowsOrdering = {
  date: "date",
  "-date": "-date",
  description: "description",
  "-description": "-description",
  amount: "amount",
  "-amount": "-amount",
} as const
