/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type CategorizeTransactionInputCategory =
  (typeof CategorizeTransactionInputCategory)[keyof typeof CategorizeTransactionInputCategory]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CategorizeTransactionInputCategory = {
  credit_card_bill_payment: "credit_card_bill_payment",
  transfer: "transfer",
  invoice_payment: "invoice_payment",
  expense_payment: "expense_payment",
  uncategorized: "uncategorized",
} as const
