/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type InvoicePaymentTypeExternalMethod =
  (typeof InvoicePaymentTypeExternalMethod)[keyof typeof InvoicePaymentTypeExternalMethod]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InvoicePaymentTypeExternalMethod = {
  external: "external",
} as const
