import IconBase from "compass-local/legacy/IconBase"
import { StrokedIconProps } from "../types"

export default function DollarBills({
  width,
  height = 19,
  onClick,
  href,
  tabIndex,
  className,
  thickness = 2,
}: StrokedIconProps) {
  return (
    <IconBase href={href} className={className} onClick={onClick} tabIndex={tabIndex}>
      <svg
        width={width?.toFixed(1)}
        height={height.toFixed(1)}
        viewBox="0 0 23 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17.2085 6.07143V3.78571C17.2085 2.52335 16.1851 1.5 14.9228 1.5H3.49421C2.23185 1.5 1.2085 2.52335 1.2085 3.78571V10.6429C1.2085 11.9052 2.23185 12.9286 3.49421 12.9286H5.77992M8.06564 17.5H19.4942C20.7566 17.5 21.7799 16.4767 21.7799 15.2143V8.35714C21.7799 7.09478 20.7566 6.07143 19.4942 6.07143H8.06564C6.80327 6.07143 5.77992 7.09478 5.77992 8.35714V15.2143C5.77992 16.4767 6.80327 17.5 8.06564 17.5ZM16.0656 11.7857C16.0656 13.0481 15.0423 14.0714 13.7799 14.0714C12.5176 14.0714 11.4942 13.0481 11.4942 11.7857C11.4942 10.5233 12.5176 9.5 13.7799 9.5C15.0423 9.5 16.0656 10.5233 16.0656 11.7857Z"
          stroke="currentColor"
          strokeWidth={thickness.toFixed(1)}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </IconBase>
  )
}
