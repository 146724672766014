import IconBase from "compass-local/legacy/IconBase"
import { IconProps } from "../types"

export default function ShieldWithX({
  width,
  height = 8,
  onClick,
  href,
  tabIndex,
  className,
}: IconProps) {
  return (
    <IconBase href={href} className={className} onClick={onClick} tabIndex={tabIndex}>
      <svg
        width={width?.toFixed(1)}
        height={height.toFixed(1)}
        viewBox="0 0 9 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.50293 1.99801C0.578167 4.67063 2.19552 6.95743 4.49672 8.00012H4.50366C6.80421 6.95772 8.4213 4.67193 8.49738 2.00027C6.9102 1.87102 5.49776 1.12404 4.50085 0.00012207H4.496C3.49953 1.12248 2.08847 1.86847 0.50293 1.99801ZM3.43657 2.23523L2.72947 2.94233L3.79013 4.00299L2.73234 5.06078L3.43944 5.76789L4.49723 4.7101L5.55789 5.77076L6.265 5.06365L5.20434 4.00299L6.26787 2.93946L5.56076 2.23236L4.49723 3.29589L3.43657 2.23523Z"
          fill="currentColor"
        />
      </svg>
    </IconBase>
  )
}
