import IconBase from "compass-local/legacy/IconBase"
import { IconProps } from "../types"

export default function Home({
  width,
  height = 31,
  onClick,
  href,
  tabIndex,
  className,
}: IconProps) {
  return (
    <IconBase href={href} className={className} onClick={onClick} tabIndex={tabIndex}>
      <svg
        width={width?.toFixed(1)}
        height={height.toFixed(1)}
        viewBox="0 0 29 31"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M26.9762 30.074C27.2244 30.074 27.4193 29.8746 27.4193 29.631V27.7257C27.4193 27.6282 27.3927 27.5397 27.3351 27.4643L25.9394 25.5591C25.8552 25.4439 25.7223 25.3774 25.5805 25.3774H3.61726C3.50206 25.3774 3.39572 25.4217 3.31154 25.5015L1.41518 27.3181C1.32656 27.4023 1.27783 27.5175 1.27783 27.6371V29.631C1.27783 29.7462 1.32213 29.8614 1.40631 29.9456C1.4905 30.0253 1.60127 30.074 1.72091 30.074C1.72091 30.074 1.72091 30.074 1.72532 30.074C11.3268 30.0076 25.8198 29.95 26.8477 30.0519C26.8876 30.0652 26.9319 30.074 26.9762 30.074Z"
          fill="currentColor"
        />
        <path
          d="M28.1149 12.475L24.2114 9.13865V1.28292C24.2114 1.03923 24.0164 0.839844 23.7683 0.839844H19.7806C19.5325 0.839844 19.3375 1.03923 19.3375 1.28292V4.97372L15.4252 1.6285C14.8846 1.16328 14.0827 1.16772 13.5421 1.63738L1.07838 12.4706C0.763789 12.7453 0.599854 13.1308 0.599854 13.5162C0.599854 13.8397 0.715058 14.1631 0.941031 14.429C1.44171 15.005 2.31899 15.067 2.89498 14.5619L14.4947 4.47748L26.3116 14.5796C26.573 14.8012 26.8964 14.9119 27.2154 14.9119C27.6053 14.9119 27.9908 14.748 28.2655 14.429C28.7662 13.8441 28.6953 12.9713 28.1149 12.475Z"
          fill="currentColor"
        />
        <path d="M22.6652 18.3679H20.3169V20.8004H22.6652V18.3679Z" fill="currentColor" />
        <path d="M8.88122 18.3679H6.53735V20.8004H8.88122V18.3679Z" fill="currentColor" />
        <path
          d="M25.5812 24.4913C25.7362 24.4913 25.8824 24.5178 26.0242 24.571V15.4614C25.9267 15.4038 25.8293 15.3329 25.7407 15.2575L14.4998 5.64722L3.47609 15.231C3.38306 15.3151 3.28114 15.386 3.1748 15.4525V24.5666C3.31659 24.5178 3.46281 24.4913 3.61788 24.4913H11.3584V17.5039C11.3584 17.2602 11.5534 17.0609 11.8015 17.0609H17.402C17.6457 17.0609 17.845 17.2602 17.845 17.5039V24.4913H25.5812ZM9.76778 21.2435C9.76778 21.4916 9.56839 21.6866 9.3247 21.6866H6.09469C5.84657 21.6866 5.65161 21.4916 5.65161 21.2435V17.9249C5.65161 17.6767 5.84657 17.4818 6.09469 17.4818H9.3247C9.56839 17.4818 9.76778 17.6767 9.76778 17.9249V21.2435ZM19.4313 17.9249C19.4313 17.6767 19.6307 17.4818 19.8743 17.4818H23.1088C23.3525 17.4818 23.5519 17.6767 23.5519 17.9249V21.2435C23.5519 21.4916 23.3525 21.6866 23.1088 21.6866H19.8743C19.6307 21.6866 19.4313 21.4916 19.4313 21.2435V17.9249Z"
          fill="currentColor"
        />
        <path
          d="M15.4082 21.1114V22.0209C15.4082 22.2656 15.6066 22.464 15.8513 22.464C16.096 22.464 16.2944 22.2656 16.2944 22.0209V21.1114C16.2944 20.8667 16.096 20.6683 15.8513 20.6683C15.6066 20.6683 15.4082 20.8667 15.4082 21.1114Z"
          fill="currentColor"
        />
      </svg>
    </IconBase>
  )
}
