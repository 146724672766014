/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type EstimateCreateStatus = (typeof EstimateCreateStatus)[keyof typeof EstimateCreateStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EstimateCreateStatus = {
  draft: "draft",
  sent: "sent",
  approved: "approved",
  rejected: "rejected",
  void: "void",
} as const
