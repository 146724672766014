import { ReactNode } from "react"
import { Tooltip as BaseTooltip } from "antd"
import Icon from "../../data/Icon"

type Props = {
  message: ReactNode
  inactive?: boolean
  children: ReactNode
}

function Tooltip({ message, inactive, children }: Props) {
  if (inactive) {
    return <>{children}</>
  } else {
    return (
      <>
        <BaseTooltip
          title={<div className="p-2 whitespace-pre-wrap">{message}</div>}
          style={{ fontWeight: 400, maxWidth: 360 }}
          mouseEnterDelay={0.04}
          mouseLeaveDelay={0.04}
          zIndex={10100}
        >
          {children}
        </BaseTooltip>
      </>
    )
  }
}

function Info(props: { message: ReactNode; inactive?: boolean }) {
  return (
    <Tooltip {...props}>
      <div className="hitbox">
        <Icon name="info" height={16} color="text-th-text-disabled" />
      </div>
    </Tooltip>
  )
}

export default Object.assign(Tooltip, { Info })
