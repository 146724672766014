/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type TutorialPatchStatus = (typeof TutorialPatchStatus)[keyof typeof TutorialPatchStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TutorialPatchStatus = {
  in_progress: "in_progress",
  dismissed: "dismissed",
  completed: "completed",
} as const
