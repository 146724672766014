/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type BusinessArchivedReason =
  (typeof BusinessArchivedReason)[keyof typeof BusinessArchivedReason]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BusinessArchivedReason = {
  is_suspicious: "is_suspicious",
  not_supported: "not_supported",
  null: null,
} as const
