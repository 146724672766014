import { ReactNode, useMemo } from "react"
import { MetricsUtils as Utils } from "../utils"

type Props = Utils.Spec & {
  children: ReactNode
}

export default function Metrics(props: Props) {
  const upperContext = Utils.useContext()
  const { children, ...ctx } = props

  const val = useMemo(() => ({ ...upperContext, ...ctx }), [upperContext, ctx])

  return <Utils.Ctx.Provider value={val}>{children}</Utils.Ctx.Provider>
}
