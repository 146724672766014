import IconBase from "compass-local/legacy/IconBase"
import { StrokedIconProps } from "../types"

export default function Profile({
  width,
  height = 20,
  onClick,
  href,
  tabIndex,
  className,
  thickness = 2,
}: StrokedIconProps) {
  return (
    <IconBase href={href} className={className} onClick={onClick} tabIndex={tabIndex}>
      <svg
        width={width?.toFixed(1)}
        height={height.toFixed(1)}
        viewBox="0 0 18 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.88533 14.6591C4.75059 13.6048 6.8574 13.0523 9 13.0556C11.2222 13.0556 13.3084 13.6378 15.1147 14.6591M11.6667 7.72222C11.6667 8.42947 11.3857 9.10774 10.8856 9.60784C10.3855 10.1079 9.70724 10.3889 9 10.3889C8.29276 10.3889 7.61448 10.1079 7.11438 9.60784C6.61428 9.10774 6.33333 8.42947 6.33333 7.72222C6.33333 7.01498 6.61428 6.3367 7.11438 5.8366C7.61448 5.33651 8.29276 5.05556 9 5.05556C9.70724 5.05556 10.3855 5.33651 10.8856 5.8366C11.3857 6.3367 11.6667 7.01498 11.6667 7.72222ZM17 9.5C17 10.5506 16.7931 11.5909 16.391 12.5615C15.989 13.5321 15.3997 14.414 14.6569 15.1569C13.914 15.8997 13.0321 16.489 12.0615 16.891C11.0909 17.2931 10.0506 17.5 9 17.5C7.94943 17.5 6.90914 17.2931 5.93853 16.891C4.96793 16.489 4.08601 15.8997 3.34315 15.1569C2.60028 14.414 2.011 13.5321 1.60896 12.5615C1.20693 11.5909 1 10.5506 1 9.5C1 7.37827 1.84285 5.34344 3.34315 3.84315C4.84344 2.34285 6.87827 1.5 9 1.5C11.1217 1.5 13.1566 2.34285 14.6569 3.84315C16.1571 5.34344 17 7.37827 17 9.5Z"
          stroke="currentColor"
          strokeWidth={thickness.toFixed(1)}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </IconBase>
  )
}
