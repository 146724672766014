import { FC } from "react"
import { t } from "content"
import * as Js from "./utils.jsx"

export type Id<T> = T extends infer U ? { [K in keyof U]: U[K] } : never

export type ExtraResult<T extends object> =
  | { status: "waiting"; Loading: FC }
  | { status: "ready"; data: T }
  | { status: "error"; error: Error; Err: FC<{ error: Error }> }

type Extra<TPropsBefore extends object, TPropsAfter extends object> = (
  props: TPropsBefore,
) => ExtraResult<TPropsAfter> | TPropsAfter

type AnyExtra = Extra<any, any>

type ResolveExtras<TExtras extends AnyExtra[]> = TExtras extends [
  Extra<any, infer TFinal>,
  ...infer TRest extends AnyExtra[],
]
  ? TFinal & ResolveExtras<TRest>
  : object

interface AbstractComponent<TOuterProps extends object, TExtras extends AnyExtra[]> {
  build: (Component: FC<Id<ResolveExtras<TExtras> & TOuterProps>>) => FC<TOuterProps>
  inject: <T extends object>(
    extra: Extra<ResolveExtras<TExtras> & TOuterProps, T>,
  ) => AbstractComponent<TOuterProps, [...TExtras, Extra<ResolveExtras<TExtras> & TOuterProps, T>]>
}

export function C<TProps extends object>(): AbstractComponent<TProps, []> {
  return Js.C([])
}

export namespace C {
  export function ready<T extends object>(data: T): ExtraResult<T> {
    return { status: "ready", data }
  }

  function DefaultErr(p: { error: Error }) {
    return <>{`Error ${p.error.message}`}</>
  }
  export function error(error_: Error): ExtraResult<any> {
    return { status: "error", error: error_, Err: DefaultErr }
  }

  function DefaultLoading() {
    return <>{t("Loading...")}</>
  }
  export function waiting(): ExtraResult<any> {
    return { status: "waiting", Loading: DefaultLoading }
  }
}
