/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type ListTimeEntrysOrdering =
  (typeof ListTimeEntrysOrdering)[keyof typeof ListTimeEntrysOrdering]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ListTimeEntrysOrdering = {
  date: "date",
  "-date": "-date",
  employee_name: "employee_name",
  "-employee_name": "-employee_name",
  billable: "billable",
  "-billable": "-billable",
  taxable: "taxable",
  "-taxable": "-taxable",
  rate: "rate",
  "-rate": "-rate",
  cost_rate: "cost_rate",
  "-cost_rate": "-cost_rate",
  hours: "hours",
  "-hours": "-hours",
  minutes: "minutes",
  "-minutes": "-minutes",
  seconds: "seconds",
  "-seconds": "-seconds",
  created_at: "created_at",
  "-created_at": "-created_at",
  updated_at: "updated_at",
  "-updated_at": "-updated_at",
} as const
