/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type ListMfaSessionsOrdering =
  (typeof ListMfaSessionsOrdering)[keyof typeof ListMfaSessionsOrdering]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ListMfaSessionsOrdering = {
  expires_at: "expires_at",
  "-expires_at": "-expires_at",
  created_at: "created_at",
  "-created_at": "-created_at",
  updated_at: "updated_at",
  "-updated_at": "-updated_at",
} as const
