/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type TransactionAccountTypeCardCardDetailsBrand =
  (typeof TransactionAccountTypeCardCardDetailsBrand)[keyof typeof TransactionAccountTypeCardCardDetailsBrand]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TransactionAccountTypeCardCardDetailsBrand = {
  amex: "amex",
  diners: "diners",
  discover: "discover",
  eftpos_au: "eftpos_au",
  jcb: "jcb",
  mastercard: "mastercard",
  unionpay: "unionpay",
  visa: "visa",
  unknown: "unknown",
} as const
