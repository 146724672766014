/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type SetupIntentPaymentMethodType =
  (typeof SetupIntentPaymentMethodType)[keyof typeof SetupIntentPaymentMethodType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SetupIntentPaymentMethodType = {
  card: "card",
  bank_account: "bank_account",
} as const
