import React, { Children, CSSProperties, forwardRef, ReactNode, useState } from "react"
import { unreachable } from "msutils/misc"
// TODO: bad import
import Tooltip from "./layout/Tooltip"

export namespace BaseLayout {
  export const NativeDiv = forwardRef<
    HTMLDivElement,
    CSSProperties & { children?: ReactNode; className?: string; id?: string }
  >((props, ref) => {
    return (
      <div ref={ref} style={props} className={props.className} id={props.id}>
        {props.children}
      </div>
    )
  })

  export const Grain = 4

  type Children = { children?: ReactNode }
  type ViewProps = {
    inset?: number | [number, number]
    border?: string
    outline?: string
    outlineOffset?: number
    borderRadius?: number
    background?: string
    textColor?: string
    fillHeight?: boolean
    fillWidth?: boolean
    fixedWidth?: number
    collapseWidth?: boolean
    /** Note: This should eventually default to true */
    hideOverflow?: boolean
    overridesDONOTUSE?: CSSProperties
  }

  function getViewStyles({
    inset,
    border,
    outline,
    outlineOffset,
    borderRadius,
    background,
    textColor,
    fillHeight,
    fillWidth,
    fixedWidth,
    collapseWidth,
    hideOverflow,
    overridesDONOTUSE,
  }: ViewProps): CSSProperties {
    return {
      padding: typeof inset === "number" ? inset * Grain : undefined,
      ...(Array.isArray(inset) && {
        paddingTop: inset[0] * Grain,
        paddingBottom: inset[0] * Grain,
        paddingLeft: `${inset[1] * Grain}px`,
        paddingRight: inset[1] * Grain,
      }),
      outline,
      outlineOffset,
      border,
      overflow: hideOverflow ? "hidden" : undefined,
      borderRadius: borderRadius ? borderRadius * Grain : undefined,
      background,
      color: textColor,
      height: fillHeight ? "100%" : undefined,
      width: fixedWidth ?? (fillWidth ? "100%" : collapseWidth ? "fit-content" : undefined),
      minWidth: fixedWidth,
      ...overridesDONOTUSE,
    }
  }

  type EventHandlers = {
    onClick?: (e: React.MouseEvent<HTMLDivElement>) => void
    onFocus?: () => void
    onBlur?: () => void
    onMouseEnter?: () => void
    onMouseLeave?: () => void
  }

  const ViewWithEventHandlers = forwardRef<HTMLDivElement, ViewProps & EventHandlers & Children>(
    (props, ref) => {
      return (
        <div
          ref={ref}
          style={getViewStyles(props)}
          onClick={props.onClick}
          onFocus={props.onFocus}
          onBlur={props.onBlur}
          onMouseEnter={props.onMouseEnter}
          onMouseLeave={props.onMouseLeave}
        >
          {props.children}
        </div>
      )
    },
  )

  export const View = forwardRef<HTMLDivElement, ViewProps & Children>((props, ref) => (
    <ViewWithEventHandlers {...props} ref={ref} />
  ))

  type StackProps = {
    gap?: number
    justify?: "between" | "start" | "end" | "center"
    align?: "center" | "start" | "end" | "stretch" | "baseline"
    orientation: "vertical" | "horizontal"
    view?: ViewProps
  }

  export const Stack = forwardRef<HTMLDivElement, StackProps & Children>((props, ref) => {
    const {
      justify = "start",
      orientation,
      align = orientation === "vertical" ? undefined : "center",
      gap = orientation === "horizontal" && justify === "between" ? 4 : undefined,
      view,
      children,
    } = props

    if (!ref && !view && Children.toArray(children).length <= 1) {
      return <>{children}</>
    } else {
      return (
        <div
          ref={ref}
          style={{
            ...getViewStyles({
              fillWidth: orientation === "vertical" && !view?.collapseWidth,
              ...view,
            }),
            display: "flex",
            flexDirection: orientation === "vertical" ? "column" : "row",
            gap: gap ? gap * Grain : undefined,
            justifyContent:
              justify === "between"
                ? "space-between"
                : justify === "start"
                ? "start"
                : justify === "end"
                ? "end"
                : justify === "center"
                ? "center"
                : unreachable(justify),
            alignItems: !align
              ? undefined
              : align === "center"
              ? "center"
              : align === "start"
              ? "start"
              : align === "end"
              ? "end"
              : align === "stretch"
              ? "stretch"
              : align === "baseline"
              ? "baseline"
              : unreachable(align),
          }}
        >
          {children}
        </div>
      )
    }
  })

  export const HStack = forwardRef<HTMLDivElement, Omit<StackProps & Children, "orientation">>(
    (p, ref) => <Stack {...p} ref={ref} orientation="horizontal" />,
  )

  export const VStack = forwardRef<HTMLDivElement, Omit<StackProps & Children, "orientation">>(
    (p, ref) => <Stack {...p} ref={ref} orientation="vertical" />,
  )

  type ScrollerProps = {
    scrollX?: boolean
    scrollY?: boolean
    ignoreNaturalSize?: boolean
  }

  export const Scroller = forwardRef<HTMLDivElement, ScrollerProps & Children>((props, ref) => {
    const content = (
      <div
        ref={ref}
        style={{
          position: props.ignoreNaturalSize ? "absolute" : undefined,
          inset: props.ignoreNaturalSize ? 0 : undefined,
          overflowX: props.scrollX ? "auto" : undefined,
          overflowY: props.scrollY ? "auto" : undefined,
        }}
      >
        {props.children}
      </div>
    )

    if (props.ignoreNaturalSize) {
      return <div style={{ position: "relative", height: "100%" }}>{content}</div>
    } else {
      return content
    }
  })

  export const ClickPropagationBoundary = forwardRef<HTMLDivElement, Children>((props, ref) => {
    return (
      <div
        ref={ref}
        onClick={(e) => {
          e.stopPropagation()
          e.preventDefault()
        }}
      >
        {props.children}
      </div>
    )
  })

  type IState = "idle" | "disabled" | "hovered"
  type InteractionTargetProps = {
    stopPropagation?: boolean
    disabled?: false | string
    view?: (state: IState) => ViewProps
    onClick?: () => void
    onFocus?: () => void
    onBlur?: () => void
  }

  export const InteractionTarget = forwardRef<HTMLDivElement, InteractionTargetProps & Children>(
    (props, ref) => {
      const [isHovered, setIsHovered] = useState(false)
      const state: IState = props.disabled ? "disabled" : isHovered ? "hovered" : "idle"
      const viewProps = props.view?.(state)
      return (
        <Tooltip message={props.disabled || ""} inactive={!props.disabled}>
          <ViewWithEventHandlers
            {...props}
            {...viewProps}
            ref={ref}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={
              props.onClick
                ? (e) => {
                    if (props.stopPropagation) {
                      e.stopPropagation()
                      e.preventDefault()
                    }
                    props.onClick?.()
                  }
                : undefined
            }
          />
        </Tooltip>
      )
    },
  )
}
