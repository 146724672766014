/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type InvoicePaymentTypeCardCardDetailsState =
  (typeof InvoicePaymentTypeCardCardDetailsState)[keyof typeof InvoicePaymentTypeCardCardDetailsState]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InvoicePaymentTypeCardCardDetailsState = {
  new: "new",
  pending: "pending",
  complete: "complete",
  failed: "failed",
} as const
