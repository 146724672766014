/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type ContractCreateContractType =
  (typeof ContractCreateContractType)[keyof typeof ContractCreateContractType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ContractCreateContractType = {
  progress: "progress",
  milestone: "milestone",
  free_form: "free_form",
} as const
