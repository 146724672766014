/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type ListInvoicesPageSummarysOrdering =
  (typeof ListInvoicesPageSummarysOrdering)[keyof typeof ListInvoicesPageSummarysOrdering]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ListInvoicesPageSummarysOrdering = {
  all_count: "all_count",
  "-all_count": "-all_count",
  all_amount: "all_amount",
  "-all_amount": "-all_amount",
  unpaid_count: "unpaid_count",
  "-unpaid_count": "-unpaid_count",
  unpaid_amount: "unpaid_amount",
  "-unpaid_amount": "-unpaid_amount",
  processing_count: "processing_count",
  "-processing_count": "-processing_count",
  processing_amount: "processing_amount",
  "-processing_amount": "-processing_amount",
  paid_count: "paid_count",
  "-paid_count": "-paid_count",
  paid_amount: "paid_amount",
  "-paid_amount": "-paid_amount",
  project_exists: "project_exists",
  "-project_exists": "-project_exists",
  lien_waiver_requirement_exists: "lien_waiver_requirement_exists",
  "-lien_waiver_requirement_exists": "-lien_waiver_requirement_exists",
  created_at: "created_at",
  "-created_at": "-created_at",
  updated_at: "updated_at",
  "-updated_at": "-updated_at",
} as const
