/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type ListStripeIdentityVerificationSessionsOrdering =
  (typeof ListStripeIdentityVerificationSessionsOrdering)[keyof typeof ListStripeIdentityVerificationSessionsOrdering]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ListStripeIdentityVerificationSessionsOrdering = {
  created_at: "created_at",
  "-created_at": "-created_at",
  updated_at: "updated_at",
  "-updated_at": "-updated_at",
  status: "status",
  "-status": "-status",
  stripe_id: "stripe_id",
  "-stripe_id": "-stripe_id",
} as const
