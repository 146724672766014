import IconBase from "compass-local/legacy/IconBase"
import { StrokedIconProps } from "../types"

export default function DocumentWithPencil({
  width,
  height = 20,
  onClick,
  href,
  tabIndex,
  className,
  thickness = 2,
}: StrokedIconProps) {
  return (
    <IconBase href={href} className={className} onClick={onClick} tabIndex={tabIndex}>
      <svg
        width={width?.toFixed(1)}
        height={height.toFixed(1)}
        viewBox="0 0 46 44"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M36.8931 11.3071L41.0516 15.4895M36.8931 11.3071L22.5077 25.7751M36.8931 11.3071L38.6572 9.53295M41.0516 15.4895L26.6239 30.0001M41.0516 15.4895L42.8157 13.7153M38.6572 9.53295C39.2086 8.97833 39.9565 8.66675 40.7364 8.66675C41.5163 8.66675 42.2642 8.97833 42.8157 9.53295C43.3671 10.0876 43.6769 10.8398 43.6769 11.6241C43.6769 12.4085 43.3671 13.1607 42.8157 13.7153M38.6572 9.53295L22.5077 25.7751M42.8157 13.7153L26.6239 30.0001M26.6239 30.0001H22.5077V25.7751"
          stroke="currentColor"
          strokeWidth={thickness.toFixed(1)}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.61538 14.6667H23.8308M8.61538 20.6667H17.8769M23.0091 2H5.96923C4.91653 2 3.90694 2.42143 3.16256 3.17157C2.41819 3.92172 2 4.93913 2 6V38C2 39.0609 2.41819 40.0783 3.16256 40.8284C3.90694 41.5786 4.91653 42 5.96923 42H26.4769C27.5296 42 28.5392 41.5786 29.2836 40.8284C30.028 40.0783 30.4462 39.0609 30.4462 38V26.6667M23.0091 2C23.5355 2.00011 24.0402 2.2109 24.4123 2.586L29.8647 8.08067C30.2369 8.45565 30.446 8.96428 30.4462 9.49467M23.0091 2V9.49467H30.4462M30.4462 9.49467V17.3333M8.61538 26.6667H17.8769M13.9077 34.6667H22.5077"
          stroke="currentColor"
          strokeWidth={thickness.toFixed(1)}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </IconBase>
  )
}
