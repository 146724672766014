import { useRef } from "react"
import { expand, ExpandOneLayer } from "msutils/object"
import { MSArray } from "msutils"

export function getNextInvoiceNumber(existingNumberStrings: (string | null)[]) {
  const numbers = existingNumberStrings.map((n) => Number(n)).filter((x) => !Number.isNaN(x))
  return String(Math.max(...numbers, 0) + 1)
}

// Note: these are generic utils which should be moved to MSUtils

export function switchInline<R extends string, T>(arg: R, switches: Record<R, T>) {
  return switches[arg]
}

export function execute<T>(fn: () => T): T {
  return fn()
}

export function required<T>(value: T | null | undefined): T {
  if (value === null || value === undefined) throw new Error("Required")
  return value
}

export function useControlledOrUncontrolled<T>(
  controlledValue: T | undefined,
  uncontrolledValue: T,
) {
  const initialControlled = useRef(controlledValue).current
  if (initialControlled) {
    if (controlledValue) {
      return controlledValue
    } else {
      // eslint-disable-next-line
      console.error('Component changed from controlled to uncontrolled')
      return uncontrolledValue
    }
  } else if (controlledValue) {
    // eslint-disable-next-line
    console.error('Component changed from uncontrolled to controlled')
    return controlledValue
  } else {
    return uncontrolledValue
  }
}

export function omitCoalesce<T>(a: T, b: T) {
  return a === undefined ? b : a
}

export function emptied(object: Record<string, never>) {
  if (MSArray.isNonEmpty(Object.keys(object))) {
    throw new Error("Expected an emptied object")
  }
}

export function branch<K extends string, R>(k: K, val: R): ExpandOneLayer<{ type: K } & R> {
  return expand({ type: k, ...val })
}

export function typed<T>(val: T) {
  return val
}

// eslint-disable-next-line
export function printed<T>(val: T) {
  // eslint-disable-next-line
  console.log(val)
  return val
}

export function ref<T>(val: T) {
  return { map: <S>(fn: (x: T) => S) => fn(val), val }
}
