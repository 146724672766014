/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type BeamBalanceTransactionTypeExternalType =
  (typeof BeamBalanceTransactionTypeExternalType)[keyof typeof BeamBalanceTransactionTypeExternalType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BeamBalanceTransactionTypeExternalType = {
  external: "external",
} as const
