import IconBase from "compass-local/legacy/IconBase"
import { StrokedIconProps } from "../types"

export default function DollarEntering({
  width,
  height = 14,
  onClick,
  href,
  tabIndex,
  className,
  thickness = 1.5,
}: StrokedIconProps) {
  return (
    <IconBase href={href} className={className} onClick={onClick} tabIndex={tabIndex}>
      <svg
        width={width?.toFixed(1)}
        height={height.toFixed(1)}
        viewBox="0 0 16 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.27273 11.1565V12.5C6.27273 13.0523 6.72045 13.5 7.27273 13.5L14.1616 13.5C14.7139 13.5 15.1616 13.0523 15.1616 12.5L15.1616 2.5C15.1616 1.94772 14.7139 1.5 14.1616 1.5L7.27273 1.5C6.72045 1.5 6.27273 1.94772 6.27273 2.5V4"
          stroke="currentColor"
          strokeWidth={thickness.toFixed(1)}
          strokeLinecap="round"
        />
        <path
          d="M10.7273 4.3C9.62263 4.3 8.72729 5.016 8.72729 5.9C8.72729 6.784 9.62263 7.5 10.7273 7.5C11.832 7.5 12.7273 8.216 12.7273 9.1C12.7273 9.984 11.832 10.7 10.7273 10.7M10.7273 4.3V10.7M10.7273 4.3C11.4673 4.3 12.114 4.6216 12.46 5.1M10.7273 4.3V3.5M10.7273 10.7V11.5M10.7273 10.7C9.98729 10.7 9.34063 10.3784 8.99463 9.9"
          stroke="currentColor"
          strokeWidth={thickness.toFixed(1)}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.00001 9.86364L6.18183 7.68182M6.18183 7.68182L4.00001 5.5M6.18183 7.68182H1.72729"
          stroke="currentColor"
          strokeWidth={thickness.toFixed(1)}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </IconBase>
  )
}
