import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import en from "./en-US.json"

const configuredI18N = i18n.use(LanguageDetector).use(initReactI18next)
const enTranslations = { translations: en }

configuredI18N.init({
  resources: { en: enTranslations },
  detection: { order: ["navigator"] },
  fallbackLng: "en",
  fallbackNS: "translations",
  interpolation: { escapeValue: false },
})

export { t, Trans, StatusReport } from "./utils"
