/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type ComplianceDocumentCreateType =
  (typeof ComplianceDocumentCreateType)[keyof typeof ComplianceDocumentCreateType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ComplianceDocumentCreateType = {
  coi: "coi",
  license: "license",
  w9: "w9",
} as const
