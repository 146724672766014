/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type InvoiceIntentType = (typeof InvoiceIntentType)[keyof typeof InvoiceIntentType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InvoiceIntentType = {
  invoice: "invoice",
  bill: "bill",
} as const
