import { BaseList, BaseListField, Field } from "./base"
import * as Utils from "./js-utils"

type List<T extends Field<any, any, any>, V> = BaseListField<T, V>

type ListProps<T extends Field<any, any, any>, TOpt extends boolean, V> = {
  initValue?: T extends { initValue: infer S } ? S[] : never
  validate?: (
    subvals: TOpt extends true ? ReturnType<T["validate"]>[] | null : ReturnType<T["validate"]>[],
  ) => V
  optional?: TOpt
  errorKey?: string
  spec: T
}

export function List<
  T extends Field<any, any, any>,
  TOpt extends boolean = false,
  V = TOpt extends true ? ReturnType<T["validate"]>[] | null : ReturnType<T["validate"]>[],
>(props: ListProps<T, TOpt, V>): List<T, V> {
  const validate: any = (x: any) => {
    if (!props.optional && x === null) throw new Error("Required")
    return props.validate?.(x) ?? x
  }

  return BaseList({
    spec: props.spec,
    initValue: props.initValue ?? ([Utils.getDefaults(props.spec, null)] as any),
    validate,
    errorKey: props.errorKey,
  })
}
