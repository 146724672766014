import IconBase from "compass-local/legacy/IconBase"
import { StrokedIconProps } from "../types"

export default function Info({
  width,
  height = 20,
  onClick,
  href,
  tabIndex,
  className,
  thickness = 2,
}: StrokedIconProps) {
  return (
    <IconBase href={href} className={className} onClick={onClick} tabIndex={tabIndex}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
        width={width?.toFixed(1)}
        height={height.toFixed(1)}
      >
        <path
          fillRule="evenodd"
          d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a.75.75 0 000 1.5h.253a.25.25 0 01.244.304l-.459 2.066A1.75 1.75 0 0010.747 15H11a.75.75 0 000-1.5h-.253a.25.25 0 01-.244-.304l.459-2.066A1.75 1.75 0 009.253 9H9z"
          clipRule="evenodd"
          strokeWidth={thickness.toFixed(1)}
        />
      </svg>
    </IconBase>
  )
}
