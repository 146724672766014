/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type ChangeOrderCreateApprovalState =
  (typeof ChangeOrderCreateApprovalState)[keyof typeof ChangeOrderCreateApprovalState]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ChangeOrderCreateApprovalState = {
  pending: "pending",
  rejected: "rejected",
  approved: "approved",
} as const
