/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type PayeeContactPatchContactType =
  (typeof PayeeContactPatchContactType)[keyof typeof PayeeContactPatchContactType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PayeeContactPatchContactType = {
  homeowner: "homeowner",
  contractor: "contractor",
  developer: "developer",
} as const
