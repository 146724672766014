/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 */

export type ComplianceStatusSummaryBusinessLevelStatus =
  (typeof ComplianceStatusSummaryBusinessLevelStatus)[keyof typeof ComplianceStatusSummaryBusinessLevelStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ComplianceStatusSummaryBusinessLevelStatus = {
  compliant: "compliant",
  not_compliant: "not_compliant",
} as const
