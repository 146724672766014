import { useEffect, useRef, useState } from "react"
import { MSArray, MSTimestamp, useRunEvery } from "msutils"
import { Cb } from "cb"

export function useSmartEmailVerificationQuery() {
  const [hasVerified, setHasVerified] = useState(false)
  const emailVerificationsQ = Cb.useListEmailVerifications({
    refetchInterval: hasVerified ? undefined : 2000,
  })

  useEffect(() => {
    if (!hasVerified && emailVerificationsQ.data && MSArray.isNonEmpty(emailVerificationsQ.data)) {
      setHasVerified(true)
    }
  }, [hasVerified, emailVerificationsQ.data])

  return emailVerificationsQ
}

const AssumeStaleAfterHours = 48
export function useForcedRefresh() {
  const mountedAtTime = useRef(MSTimestamp.now()).current
  useRunEvery(30, () => {
    if (mountedAtTime.add(AssumeStaleAfterHours, "hour").isPast()) {
      // eslint-disable-next-line
      console.warn('Forcing an app refresh to avoid stale client')
      window.location.reload()
    }
  })
}
